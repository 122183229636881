.dark-menu-item-btn {
  color: #fff !important;
}

.light-menu-item-btn {
  color: #000 !important;
}

.upper-upload img {
  width: 70%;
  object-fit: fill;
  border-radius: 15px;
}

.subscription-form .MuiPaper-rounded {
  min-width: 100%;
  padding: 50px 35px !important;
}

.client-users-form .MuiPaper-rounded {
  min-width: 100%;
}

.css-19kzrtu {
  padding: 0 !important;
}


.d-flex-next-btn {
  gap: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  float: inline-end;
}

.d-flex-checkbox {
  display: flex;
  align-items: center;
  gap: 2px;
  position: relative;
  margin-left: 0px!important;
}

.d-flex-checkbox span{
  color: #fff!important;
}


.align-middle-parts input {
  height: 28px;
  text-align: center;
}

.sub-heading-vendor {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
}

.align-middle-parts {
  display: flex;
  align-items: center;
  padding-top: 0px;
}

.quantity-btn {
  padding: 0px !important;
  margin: 0px 0 0 0 !important;
  height: 18px;
  position: relative !important;
  top: 6px;
}

.border-left-right {
  border-left: 1px solid #c4c4c4;
  height: 45px;
  width: 32px;
}

.absolute-checkbox {
  position: absolute !important;
  left: -50px;
}

.border-bottom-btns {
  border-bottom: 1px solid #c4c4c4 !important;
  border-radius: 0px !important;
}

.align-middle-parts fieldset {
  border-radius: 10px !important;
}

.d-flex-company-info {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 10px;
}

.d-flex-company-logo {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

.d-flex-company-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.align-peragraph-p h3 {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 15px;
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: #fff;
}

.align-peragraph-p p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
}

.react-tel-input input {
  width: 393.58px !important;
  height: 56px !important;
  border-radius: 15px !important;
  font-size: 15px;
}

.react-tel-input input[type="tel"] {
  font-size: 15px;
}

.react-tel-input .flag-dropdown {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}


.light-mode-class .add-vendor-tabs .Mui-selected {
  color: #4a10a4 !important
}

.add-vendor-tabs .Mui-selected {
  /* background-color: transparent !important;
  font-weight: 600; */
  font-size: 16px;
}

.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium.MuiPhoneNumber-positionStart.css-ittuaa-MuiInputAdornment-root {
  gap: 8px;
}

.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium.MuiPhoneNumber-positionStart.css-1iiaq29-MuiInputAdornment-root {
  gap: 8px;
}

.MuiPhoneNumber-flagButton {
  min-width: 50px !important;
}



.add-user {
  width: 150px;
  font-weight: 400 !important;
  height: 40px;
  text-transform: capitalize !important;
  font-size: 15px !important;
  font-family: Mona-Sans, sans-serif !important;
  background: #4A10A4 !important;
  color: #fff !important;
  border-radius: 10px !important;
  display: flex !important;
  margin: 0px 0px 0px 0px !important;
}


.switch-flex {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: left;
}

/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  border: 2px solid #414141;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.3em;
  width: 1.3em;
  left: 0.3em;
  bottom: 4px;
  background-color: white;
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.320, 1);
}

.switch input:checked+.slider {
  /* box-shadow: 0 0 20px #6917e4de;
    border: 2px solid #6917e4; */
}

.switch input:checked+.slider:before {
  transform: translateX(1.5em);
}

.align-justifys{
  display: flex;
}

.align-justifys .MuiBox-root {
  justify-content: flex-start !important;
  gap: 35px !important;
  color: #fff;
}



/*new*/
.toggle-button-cover {
  display: table-cell;
  position: relative;
  height: 70px;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #121212;
  transition: 0.3s ease all;
  z-index: 1;
  border: 1px solid #494949;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

#button-3 .knobs:before {
  content: url('../../assets/mail-line.svg');
  position: absolute;
  background-size: contain;
  top: 5px;
  left: 4px;
  width: 27px;
  height: 27px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 1;
  padding: 4px 3px;
  background-color: #6917e4;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#button-3 .checkbox:active+.knobs:before {
  width: 46px;
  border-radius: 100px;
}

#button-3 .checkbox:checked:active+.knobs:before {
  margin-left: -26px;
}

#button-3 .checkbox:checked+.knobs:before {
  content: url('../../assets/message.svg');
  left: 42px;
  background-color: #6917e4;
}

#button-3 .checkbox:checked~.layer {
  background-color: #121212;
  border: 1px solid #494949;
}

.notification-client-count {
  text-decoration: underline;
  color: rgb(75, 75, 198);
  cursor: pointer;
}

.notification-client-list .profile-pic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-client-list .profile-pic img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.notification-client-list {
  margin-top: 40px;
  max-height: 400px;
  overflow-y: auto;
  width: 97%;
}

.notification-client-list::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Match this with your container background color */
}

.notification-client-list::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.notification-client-list::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.notification-client-list::-webkit-scrollbar-thumb {
  background: #4c4c74;
  /* Customize the thumb color */
  border-radius: 8px;
}


.notification-client-list .client {
  margin: 15px 10px;
}

.notification-client-list .client-name {
  text-align: start;
  margin-left: 2px;
  font-size: 28px;
  letter-spacing: 1.5px;
  font-weight: 700;
  text-transform: capitalize;
}

.notification-client-list .client-email {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notification-client-list .client-email img {
  height: 30px;
  width: 30px;
}

.client-users-form .datagrid-table {
  border: none !important;
}

.add-client-logo img {
  height: 200px;
}

.client-users-form .MuiContainer-root{
  padding: 0!important;
}