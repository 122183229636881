.subscription-form .subscription-origin-client-detail {
    border: none !important;
  }

  .name-chart-item{
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.41px;
    text-align: left;
    margin-bottom: 8px;
    color: #fff;
  }

  .padding-progressdata {
    padding: 11px 0 0;
}

.padding-progressdata h3{
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  margin: 20px 0 26px;
}

