.security-breadcum {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.security-breadcum p {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  display: flex;
  gap: 13px;
}

.security-breadcum h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-top: 35px;
  margin-bottom: 10px;
}

.genesis-ai-btn {
  background: #0a023d !important;
  font-family: Mona-Sans, sans-serif !important;
  font-size: 14.07px !important;
  font-weight: 400 !important;
  line-height: 27px !important;
  text-align: center;
  color: #fff !important;
  z-index: 1;
  border: none;
  padding: 7px 29px !important;
  margin: 0px 0px 0px auto !important;
}

.genesis-ai-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  padding: 3.5px;
  background: linear-gradient(90deg, #d500ff 9.15%, #450099 58.43%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  z-index: -1;
}

.infor-vendor-detail-box {
  background: #150a4c;
  padding: 25px;
  border-radius: 10px;
}

.infor-vendor-detail-box .logo-sign {
  width: 90px;
  height: 90px;
  background: #3f1beb66;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0px 6.05px 6.05px 0px #00000040 inset;
  display: flex;
  align-items: center;
  justify-content: center;
}

.domain-detail-platte {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  color: #fff;
}

.infor-vendor-detail-box .logo-sign img {
  width: 50px;
  height: 50px;
}

.grade-d {
  vertical-align: middle;
}

.domain-detail-platte .line-right {
  border-right: 1px solid #ffffff33;
  padding-right: 25px;
  padding-left: 25px;
  height: 13px;
  display: flex;
  align-items: center;
  gap: 7px;
}

.line-right.right-0 {
  border-right: 0px;
}

.general-icon {
  margin: 0px;
  font-family: Mona-Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 17.41px;
  text-align: left;
  color: #fff;
}

.general-icon img {
  vertical-align: middle;
  margin-right: 10px;
}

.about-part-section-info {
  background: #150a4c;
  border-radius: 10px;
  padding: 25px;
  margin-top: 18px;
}

.about-part-section-info h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #fff;
  margin: 0px;
}

.about-part-section-info p {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0px;
  color: #fff;
}

.all-detail-information .deadline {
  background: #d9d9d912;
  border-radius: 4px;
  display: inline-block;
  padding: 5px;
}

.all-detail-information .statusClass {
  width: auto;
}

.top-line-flex-first {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  border-top: 1px solid #ffffff1a;
  padding-right: 45px;
}

.all-detail-information p {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #fff;
}

.all-detail-information .d-flex-inline {
  display: flex;
  gap: 11px;
  align-items: center;
}

.all-detail-information {
  margin-top: 20px;
}

.border-right-side-info {
  position: relative;
}

.border-right-side-info:before {
  content: "";
  position: absolute;
  border-right: 1px solid #2d235e;
  right: -93px;
  width: 2px;
  height: 114px;
}

.all-detail-information img {
  vertical-align: middle;
}

.status-aling {
  display: flex;
  align-items: center;
  margin-top: 0;
}

.Mui-selected.second-mui {
  background: #120a3a !important;
  border: none !important;
}

.d-flex-inline p:first-child {
  /* width: 70px; */
}

.first_tab .MuiTabs-indicator {
  background: linear-gradient(
    270deg,
    #df03cf 0%,
    #d90ce8 52%,
    #d415ff 100%
  ) !important;
  height: 2.5px !important;
}

.tab-main-heading .MuiTabs-indicator {
  background-color: transparent !important;
}

.overall-risk-overview-page {
  background: #150a4c;
  border-radius: 10px;
  padding: 25px;
  margin-top: 20px;
}

.overall-risk-overview-page-admin {
  height: 100%;
  margin-top: 14px;
}

.graph-design-donut {
  background: #1c125480;
}

.ranking-total {
  border-radius: 15px;
  background: #1f1260;
  padding: 25px;
  margin-top: 25px;
}

.ranking-total h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 50.81px;
  text-align: center;
  margin: 0px;
  color: #fff;
}

.ranking-total p {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 15.47px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0;
}

.flex-graph-half-donut {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.daily-activity {
  margin-top: 20px;
  background: #150a4c;
  border-radius: 10px;
  width: 100%;
}

.daily-activity h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 17.41px;
  text-align: left;
  margin: 0;
  text-align: center;
  padding: 17px;
  background: #3c32702b;
  color: #fff;
}

.daily-activity .bg-backgrounds {
  padding: 25px;
}

.bg-backgrounds.autoscroll {
  max-height: 281px;
  overflow-y: auto;
}

.bg-backgrounds.autoscroll::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Match this with your container background color */
}

.bg-backgrounds.autoscroll::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.bg-backgrounds.autoscroll::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.bg-backgrounds.autoscroll::-webkit-scrollbar-thumb {
  background: #4c4c74;
  /* Customize the thumb color */
  border-radius: 8px;
}

.daily-activity .heading-long-short {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #4c4c7480;
  padding-bottom: 15px;
}

.daily-activity .heading-long-short h4 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 15.47px;
  text-align: left;
  color: #fff;
  margin: 0 0 5px 0;
}

.daily-activity .heading-long-short p {
  display: flex;
  align-items: center;
  margin: 0;
  color: #fff;
  font-size: 14px;
}

.daily-activity .bg-backgrounds span {
  color: #60d39c;
  padding: 0px 0 0px 5px;
}

.graph-risk {
  text-align: center;
}

.mtop-0.heading-new {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 17.41px;
  text-align: center;
  color: #fff;
}

.inside-scan-div .box-Security-control {
  background: #150a4c;
  border: 1px solid #150a4c;
}

.same-base-bg {
  background: #150a4c;
  border-radius: 10px;
  height: 100%;
  margin-bottom: 20px;
}

.same-base-bg h2 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 17.41px;
  text-align: left;
  border-bottom: 1px solid #ffffff1a;
  padding: 20px 20px;
  color: #fff;
  margin-top: 0px;
}

.complaince-padding p {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 15.78px;
  text-align: center;
}

.iso-image {
  width: 135px;
  height: 135px;
  background: #180c53;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19%;
  object-fit: contain;
}

.flexi-complainces {
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 0px 25px;
}

.tablesame-outsidescore .MuiTableHead-root {
  background: #1e1355cc;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.54px;
  text-align: left;
}

.tablesame-outsidescore .MuiTableCell-root {
  border-bottom: 1px solid #ffffff0a;
  color: #fff;
  font-family: Mona-Sans, sans-serif;
}

.tablesame-outsidescore .gradClass.A,
.tablesame-outsidescore .gradClass.B,
.tablesame-outsidescore .gradClass.C,
.tablesame-outsidescore .gradClass.D,
.tablesame-outsidescore .gradClass.E {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 19.5px !important;
  font-size: 14px;
}

.inherent-risk-full-bacground {
  background: #150a4c;
  padding: 25px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.top-3-box-risk {
  border-radius: 20px;
  text-align: center;
  background: #1e1163;
  padding: 20px 0px 20px;
  width: 280px;
}

.top-3-box-risk h4 {
  font-family: Mona-Sans, sans-serif;
  font-size: 44.03px;
  font-style: italic;
  font-weight: 400;
  line-height: 42.58px;
  text-align: center;
  margin: 22px;
}

.top-3-box-risk h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 17.41px;
  text-align: center;
  border-bottom: 1px solid #ffffff1a;
  margin: 0;
  padding: 0px 0 20px;
}

.impact-analysis-section {
  font-family: Mona-Sans, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 21.27px;
  text-align: left;
}

.impact-analysis-section h2 {
  font-family: Mona-Sans, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 21.27px;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 30px;
}

.impact-analysis-section img {
  width: 100%;
}

.bussiness-risk {
  background: #150a4c;
  border-radius: 10px;
}

.bussiness-risk h2 {
  font-family: Mona-Sans, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 23.21px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid #ffffff1a;
  margin: 0;
  padding: 20px 0 20px 20px;
}

.flex-compliance-mapping {
  /* display: flex; */
  /* gap: 20px; */
  margin-top: 40px;
}

.gdpr-box {
  width: 100%;
  text-align: center;
  background: #1e1163;
  border-radius: 20px;
  padding-bottom: 20px;
}

.gdpr-box h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  border-bottom: 1px solid #ffffff1a;
  margin: 0;
  padding: 20px 0px;
}

.gdpr-box-mappingtab {
  width: 100%;
  text-align: center;
  background: rgba(21, 13, 67, 1);
  border-radius: 20px;
  padding-bottom: 20px;
  border: 1px solid rgba(64, 64, 64, 1);
}

.gdpr-box-mappingtab h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  border-bottom: 1px solid #ffffff1a;
  margin: 0;
  padding: 20px 0px;
  color: #fff;
}

.mapping-bussiness-left {
  padding: 20px;
}

.issues-main-bg {
  background: #150a4c;
  border-radius: 10px;
}

.tab-issues-flex {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 50px;
  gap: 50px;
}

.total-issue-three {
  background: #1e1162;
  border-radius: 25px;
  border: 1.26px solid #404040;
  width: 274px;
  height: 139px;
  color: #fff;
}

.total-issue-three h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 15.47px;
  text-align: center;
  border-bottom: 1px solid #ffffff1a;
  padding-bottom: 15px;
  padding-top: 3px;
  color: #fff;
}

.total-issue-three p {
  font-family: Mona-Sans, sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 34.81px;
  text-align: center;
  margin-top: 28px;
}

.issues-tab-table {
  margin-top: 30px;
}

.create-remediation-button {
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%);
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 15.47px;
  text-align: center;
  padding: 15px 15px;
  border-radius: 10px;
  cursor: pointer;
}

.no-remediation-button {
  background: linear-gradient(90deg, rgb(45 35 94) 0%, rgb(19 9 73) 100%);
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.47px;
  text-align: center;
  padding: 15px 15px;
  border-radius: 10px;
  cursor: pointer;
  width: 163px;
}

.Mui-selected.nextmi-selected {
  background: #150a4c !important;
  border: none !important;
}

.pop-flex-remiadation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1.29px solid #ffffff33;
  padding: 0px 25px;
}

.blank-white {
  color: #fff !important;
}

.popup-remiadation {
  background: #120a3a;
  border-radius: 10px;
  max-width: 757px;
  max-height: 586px;
}

.scroll-design::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Match this with your container background color */
}

.scroll-design::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.scroll-design::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.scroll-design::-webkit-scrollbar-thumb {
  background: #4c4c74;
  /* Customize the thumb color */
  border-radius: 8px;
}

.scroll-design textarea::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Match this with your container background color */
}

.scroll-design textarea::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.scroll-design textarea::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.scroll-design textarea::-webkit-scrollbar-thumb {
  background: #4c4c74;
  /* Customize the thumb color */
  border-radius: 8px;
}

.popup-remiadation h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 23.25px;
  font-weight: 400;
  line-height: 22.49px;
  text-align: left;
  color: #fff;
}

.popup-form-remidaition {
  margin-top: 30px;
  padding: 0px 35px 25px;
}

.cancel-popup-btns {
  border: 1.29px solid #ffffff66 !important;
  font-family: Mona-Sans, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 19.99px;
  text-align: center;
  color: #fff;
  border-radius: 12px;
  background: transparent !important;
  padding: 18px !important;
  width: 200px;
  height: 55px;
}

.button-alin-remaidation {
  display: flex;
  justify-content: right;
  gap: 21px;
}

.cancel-popup-btns.save-direct {
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%) !important;
  border: none !important;
}

.drawer-padding {
  padding: 30px;
}

.cve-detail {
  background: #170b52;
  padding-bottom: 5px;
  margin-bottom: 20px;
  width: 100%;
}

.cve-header-common {
  background: #1d0e65;
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 41px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 3px 20px;
  border-radius: 10px 10px 0 0;
}

.cve-detail p {
  padding-left: 25px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.Drawer-popup-rightside {
  padding: 0 !important;
}

.Drawer-popup-rightside thead {
  background: #1d0e65;
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 41px;
  text-align: left;
  border-radius: 10px 10px 0px 0px;
}

.Drawer-popup-rightside th {
  border-bottom: 0px;
}

.Drawer-popup-rightside td {
  border-bottom: 0px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 41px;
  text-align: left;
}

.buttonCve-none-border {
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.flexi-Cwe {
  display: flex;
  width: 100%;
  gap: 20px;
}

.bg-failed-control {
  background: #150a4c;
  border-radius: 10px;
  padding: 25px;
}

.ico-unique {
  background: #27196933;
  border-radius: 25px;
  padding: 23px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 130px;
}

.ico-unique p {
  margin: 0;
  margin-bottom: 7px;
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.ico-unique span {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.47px;
  text-align: left;
}

.general-id-box {
  display: flex;
  align-items: center;
  gap: 25px;
}

.failed-qeustions {
  background: #23136e;
  width: 100%;
  position: relative;
  border-radius: 10px;
}

.failed-qeustions .arrow-right-image {
  position: absolute;
  right: 20px;
  bottom: 20%;
}

.failed-qeustions p {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}

.type-inline-controls p {
  display: flex;
  align-items: center;
  max-height: 119px;
  height: 100%;
}

.failed-qeustions h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  border-bottom: 1px solid #ffffff1a;
  padding-bottom: 20px;
  padding-top: 11px;
  padding-left: 20px;
  margin-bottom: 0px;
}

.failed-qeustions p {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-top: 0px;
  padding: 20px;
}

.flez-inline {
  display: flex;
  margin-top: 25px;
}

.failed-qeustions.response-box {
  background-color: #190d52;
  border-radius: 0px;
}

.failed-flexi {
  display: flex;
  width: 100%;
  margin-top: 20px;
  gap: 15px;
}

.type-inline-controls {
  display: flex;
  width: 100%;
  gap: 15px;
}

.impact-on-vendor {
  width: 100%;
}

.type-inline-controls .failed-qeustions.response-box {
  border-radius: 10px;
  width: 100%;
}

.impact-on-vendor-fl {
  background: #190d52;
  display: flex;
  width: 100%;
  border-radius: 10px;
}

.impact-on-vendor-fl .failed-qeustions {
  background: transparent;
}

.financial-loss {
  background: linear-gradient(90deg, rgba(81, 25, 140, 0.13) 0%, #a52cf5 100%);
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  border-radius: 5px;
  width: 180px;
  height: 39px;
  padding: 13px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.impact-on-vendor-fl .failed-qeustions.response-box .financial-loss {
  margin-left: 20px;
  margin-top: 20px;
}

.failed-control-datatable {
  width: 100%;
}

.data-table-failed-full {
  background: #27196933;
  padding: 15px;
  margin-top: 20px;
}

.data-table-failed-full h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  padding-left: 8px;
}

.data-table-failed-full th {
  background: #ffffff08;
  border: none;
}

.data-table-failed-full td {
  border-bottom: 1px solid #ffffff1a;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 6px;
  text-align: left;
  height: 59px;
}

.tophangechange {
  margin-top: 30px;
}

.inside-scan-div .box-Security-control {
  padding: 0;
}

.outside-side-scan {
  margin: 0;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.54px;
  text-align: center;
  color: #fff;
}

.inside-scan-div .box-Security-control .fit-bottom {
  display: none;
}

/* .inside-scan-div .bottom-circle{
    width: 5.5rem !important;
    height: 5.5rem !important;
    margin-left: -112px !important;
} */

.inside-scan-div .security-text {
  margin-top: -10px !important;
  font-family: Mona-Sans, sans-serif !important;
  font-size: 20.82px !important;
  font-weight: 500 !important;
  line-height: 24.4px;
  text-align: center;
}

.Relationship.Risk p {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 17.41px;
  text-align: center;
  color: #fff;
}

.inside-scan-div .circularprogress {
  margin-bottom: 16px;
}

/* .inside-scan-div .circularprogress {
    width: 7rem !important;
    height: 5rem !important;
    margin-bottom: 20px;
} */

.mb-12-side {
  margin-bottom: 15px;
}

.inside-scan-div {
  position: relative;
  top: 9px;
}

.Relationship.Risk {
  position: relative;
  top: 10px;
  text-align: center;
}

.mtop-0 {
  margin-top: 0px;
}

.flex-start-activity {
  display: flex;
}

#chart-scores .apexcharts-inner.apexcharts-graphical {
  transform: translate(0px, 30px) !important;
}

.chart-scores-num {
  position: absolute;
  top: -20px;
  font-family: Mona-Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03em;
}

.our-risk-management {
  background: #f8f7f3e0;
  padding: 30px;
  display: flex;
}

.remeidation-detail-box {
  background: #150a4c;
  border-radius: 10px;
  padding: 50px 40px;
}

.id-detail p {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.id-detail span {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
}

.remediation-discription-right {
  background: #1d0f5d;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.remediation-discription-right h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  border-bottom: 1px solid #ffffff1a;
  padding-bottom: 20px;
  margin-top: 8px;
}

.remediation-discription-right p {
  font-family: Mona-Sans, sans-serif;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-bottom: 0px;
}

.remediation-discription-right ul {
  margin: 0px;
  padding: 0px;
}

.remediation-discription-right ul li {
  list-style-type: none;
  font-style: normal;
  margin: 13px 0px;
}

.remediation-discription-right ul li strong,
.new-ul-list ul li strong {
  font-weight: 600;
}

.remediation-discription-right ul li > ul {
  margin: 0px 0px;
}

.remediation-discription-right ul li > ul li{
  margin: 10px 0px;
}

.remediation-discription-right ul li > ul li::marker {
  font-size: 12px; /* Adjust marker size */
}

.links-boxes-in {
  border: 1px solid #34276e;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  height: 200px; /* Adjust as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  display: flex;
  flex-direction: column;
}

.links-boxes-in a {
  color: #fff;
  font-size: 14px;
  font-family: Mona-Sans, sans-serif;
  margin-bottom: 10px;
  display: block;
}

.links-boxes-in a:last-child {
  margin-bottom: 0px;
}

.url-item {
  margin: 2px 0; /* Reduce the gap between URLs */
}

.url-item a {
  margin-left: 10px; /* Adjust left spacing */
  color: white;
  display: flex;
  flex-wrap: wrap;
}

.url-affected{
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-top: 35px;
}

.date-created-line p {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.date-created-line span {
  background: #1d0f5d;
  border-radius: 10px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding: 13px 25px;
  width: 215px;
  display: block;
}

.flexie-age {
  display: flex;
  gap: 40px;
}

.flexie-age.top-space {
  margin-top: 35px;
}

.id-detail {
  margin-bottom: 55px;
}

.comments-part h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  padding-left: 15px;
  border-bottom: 1px solid #af1bff4d;
  padding-bottom: 15px;
}

.textarea-comment {
  background-color: transparent;
  width: 100%;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  border: 0px solid #af1bff4d;
  padding: 15px;
  color: #fff;
}

.comments-part {
  border: 1px solid #af1bff4d;
  border-radius: 10px;
}

.submit-btn-comment {
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%);
  font-family: Mona-Sans, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  text-align: center;
  border-radius: 10px !important;
  height: 49px;
  padding: 15px 24px !important;
  display: block !important;
  margin: 5px 15px 20px auto !important;
}

.mark-btn-comment {
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%);
  font-family: Mona-Sans, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  text-align: center;
  border-radius: 10px !important;
  height: 49px;
  padding: 15px 24px !important;
  display: block !important;
  margin: 45px 15px 20px auto !important;
}

.profile-picture-img {
  min-width: 58px;
  height: 58px;
  border-radius: 100px;
  display: block;
  background: #120844;
  margin-top: 14px;
}

.comment-box-section-bottom {
  background: #1a0d58;
  border-radius: 10px;
  display: flex;
  padding: 20px;
}

.name-of-comment span {
  color: #b6acac;
}

.comments-seprate {
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  margin: 15px;
  padding: 15px;
}

.comments-seprate p {
  margin: 0;
  margin-bottom: 8px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.name-of-comment {
  display: flex;
  align-items: center;
  gap: 15px;
}

.name-of-comment h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  border-right: 1px solid #ffffff33;
  padding-right: 18px;
}

.name-of-commet span {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.textarea-comment::-webkit-input-placeholder {
  /* Chrome */
  color: #fff;
  opacity: 1;
}

.textarea-comment:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  opacity: 1;
}

.textarea-comment::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  opacity: 1;
}

.textarea-comment:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #fff;
  opacity: 1;
}

.risk-critical {
  width: 105px;
  height: 28px;
  line-height: 15px;
}

.bg-newvendor-detail .MuiPaper-elevation1 {
  background: #150a4c !important;
}

.legend-text-issue-legend.top-space-middle-chart {
  margin-top: 68px;
}

.carousel-box-inline h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 15.78px;
  text-align: center;
  color: #fff;
}

.carousel-box-inline button {
  background: #1b0e60;
  border-radius: 7px;
  top: calc(50% - 3px) !important;
}

.carousel-box-inline button:hover {
  background-color: #1b0e60 !important;
}

.carousel-box-inline .css-1abc02a:hover button {
  background-color: #1b0e60 !important;
}

.radialBar-new .mr-side0 .number-up {
  top: -10px;
}

.mr-side0 #radialBar-new-overviewpage {
  top: -18px;
}

.tablesame-outsidescore::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Match this with your container background color */
}

.tablesame-outsidescore::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.tablesame-outsidescore::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.tablesame-outsidescore::-webkit-scrollbar-thumb {
  background: #4c4c74;
  /* Customize the thumb color */
  border-radius: 8px;
}

.viewdetail-answer .select-steps.behind-line:before {
  width: 220px;
  left: 57px;
}

.steps-image img {
  width: 32px;
  height: 32px;
  object-fit: fill;
  margin: 14px 13px;
  text-align: center;
  padding: 1px;
}

.mt-questionpart .inline-parts {
  align-items: center;
  margin-bottom: 16px;
  margin-top: 9px;
}

.icons button {
  padding: 0;
}

.category-score-text-heading {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 17.41px;
  text-align: left;
  color: #fff;
  margin: 30px 0px;
}

.Category-Score-box {
  border: 1.12px solid #404040;
  background-color: #150d43;
}

.Category-Score-box h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.fialed-control-poup h3 {
  background: #1d0e65;
  font-family: Mona-Sans, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 41px;
  text-align: left;
  padding-left: 15px;
  border-radius: 10px 10px 0 0;
}

.fialed-control-poup ul {
  padding: 11px 11px;
  margin: 0px;
  background: #170b52;
}
.padding-markdown{
  background: #170b52;
}

.padding-markdown p{
  padding:0px 15px 15px;
}

.fialed-control-poup ul li {
  text-align: left;
  list-style: none;
  background: #170b52;
  padding-bottom: 10px;
  margin: 0px;
}

.fialed-control-poup ul li ul li {
  margin: 5px 0px 0px 0px;
  padding: 0;
  /* display: flex;
  flex-wrap: wrap;
  align-items: baseline; */
  padding-bottom: 4px;
}
.fialed-control-poup ul li ul{
  padding: 0;
}

.fialed-control-poup h4 {
  background: #1d0e65;
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 41px;
  text-align: left;
  padding-left: 15px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 0px;
  width: 100%;
}

.fialed-control-poup h4:nth-of-type(4) {
  background-color: transparent;
}

.span-font-size {
  font-size: 14px;
  margin: 15px 0 0px 15px;
  display: block;
}

.multiple-heading-map b {
  font-family: Mona-Sans, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  margin-left: 15px;
}

.Send-send-assessment {
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%);
  height: 42px;
  width: 125px;
}

.full-form .relation {
  font-size: 20px;
  font-family: Mona-Sans, sans-serif;
}

.openai-response .full-form input {
  color: #b1b3b7 !important;
}

.assesment-ellips-text {
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 0px;
}

.newhomeclass {
  background: transparent;
  font-family: Mona-Sans, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px !important;
  text-align: left;
  border-radius: 4px;
  color: #fff;
  padding: 3.5px;
  display: inline-block;
  position: relative;
}

.newhomeclass span {
  margin-left: 20px;
}

.criticalthirdparty:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #dc0000;
}

.highthirdparty:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #fc8c00;
}

.mediumthirdparty:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #fcc500;
}

.lowthirdparty:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #008000;
}

.tagClassapproval {
  background: transparent;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.47px !important;
  text-align: center !important;
  border-radius: 4px;
  color: transparent;
  width: 94px !important;
  height: 27px;
  padding: 5px;
  display: inline-block;
}

.tagClassapproval span {
  margin-left: 20px;
}

.tagClassapproval.Medium,
.tagClassapproval.High,
.tagClassapproval.Low,
.tagClassapproval.Critical {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.47px !important;
  text-align: left !important;
  border-radius: 4px;
  color: #fff;
  position: relative;
}

.tagClassapproval.High::before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #ffa500;
}

.tagClassapproval.Low::before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #008000;
}

.tagClassapproval.Medium:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #ffd700;
}

.tagClassapproval.Critical:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #ff0000;
}

.Low,
.low {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.47px !important;
  text-align: center !important;
  border-radius: 4px;
  padding: 5px;
  display: inline-block;
}

.Passed {
  background: #009420;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.47px !important;
  text-align: center !important;
  border-radius: 4px;
  color: rgba(255, 255, 255, 1);
  width: 108px !important;
  height: 27px;
  padding: 5px;
  display: inline-block;
}

.Failed {
  background: #e22222;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.47px !important;
  text-align: center !important;
  border-radius: 4px;
  color: #fff;
  width: 108px !important;
  height: 27px;
  padding: 5px;
  display: inline-block;
}

.Medium {
  /* background: rgba(252, 197, 0, 0.3); */
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.47px !important;
  text-align: center !important;
  border-radius: 4px;
  /* color: rgba(252, 197, 0, 1); */
  /* width: 94px !important;
  height: 27px; */
  padding: 5px;
  display: inline-block;
}

.High {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.47px !important;
  text-align: center !important;
  border-radius: 4px;
  padding: 5px;
  display: inline-block;
}

.Critical {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.47px !important;
  text-align: center !important;
  border-radius: 4px;
  padding: 5px;
  display: inline-block;
}

.criticaldatagrid,
.highdatagrid,
.mediumdatagrid,
.lowdatagrid {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.47px !important;
  text-align: left !important;
  border-radius: 4px;
  color: #fff;
  position: relative;
}

.sqaure-datagrid {
  text-align: left;
  padding-left: 23px;
}

.criticaldatagrid:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #ff0000;
}

.highdatagrid:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #ffa500;
}

.mediumdatagrid::before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #ffd700;
}

.lowdatagrid:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #008000;
}

.tagClass.Critical,
.tagClass.High,
.tagClass.Medium,
.tagClass.Low {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px !important;
  text-align: center !important;
  border-radius: 5.9px;
  position: relative;
  width: 105px;
  height: 28px;
}

.tagClass.Critical {
  background: #ff0000;
  color: #ffffff;
}

.tagClass.High {
  background: #ffa500;
  color: #333333;
}

.tagClass.Medium {
  background: #ffd700;
  color: #000000;
}

.tagClass.Low {
  background: #008000;
  color: #ffffff;
}

.gradClass.A,
.gradClass.B,
.gradClass.C,
.gradClass.D,
.gradClass.E {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px !important;
  text-align: center !important;
  border-radius: 5.9px;
  position: relative;
  width: 32px;
  height: 32px;
  padding: 4px;
  font-style: normal;
  display: block;
}

.gradClass.A {
  background: #008000;
  color: #ffffff;
}

.gradClass.B {
  background: #ffd700;
  color: #000000;
}

.gradClass.C {
  background: #ffa500;
  color: #000000;
}

.gradClass.D {
  background: #ff0000;
  color: #ffffff;
}

.gradClass.E {
  background: #ff0000;
  color: #ffffff;
}

.please-provide-text {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.14px;
  text-align: left;
  color: #fff;
}

.controls-popup {
  max-width: 601px;
  background-color: #120a3a;
}

.controls-popup .popup-form-remidaition {
  background-color: #120a3a;
}

.add-another-button {
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%) !important;
  border: none !important;
  color: #fff !important;
  font-family: Mona-Sans, sans-serif !important;
  font-size: 15px !important;
  border-radius: 10px !important;
  font-weight: 400;
  line-height: 25px !important;
  text-align: center;
  border-radius: 10px;
  padding: 18px !important;
  height: 40px;
  text-transform: capitalize !important;
  width: 135px;
}

.controls-popup .popup-form-remidaition {
  padding: 0px 25px 25px;
}

.margin-bottomselect {
  margin-bottom: 15px;
}

.Category-Score-box .box-Security-control {
  background: #150d43;
  border: none;
}

.Carousel-vertical-align-middle {
  position: relative;
  top: 41.5%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Carousel-vertical-align-middle .css-hn784z {
  left: 0px;
}

.Carousel-vertical-align-middle .css-hn784z button {
  background-color: #1b0e60;
  border-radius: 4px;
}

.Carousel-vertical-align-middle .css-hn784z button:hover {
  background-color: #1b0e60;
}

.Carousel-vertical-align-middle .css-1abc02a {
  right: 0px;
}

.Carousel-vertical-align-middle .css-1abc02a button {
  background-color: #1b0e60;
  border-radius: 4px;
}

.Carousel-vertical-align-middle .css-1abc02a button:hover {
  background-color: #1b0e60;
}

.custom-cloud-vpn-btn {
  background-color: red;
}

.no-comment-found-text {
  font-size: 400;
}

.gdpr-box.Category-Score-box p {
  margin-bottom: 0;
}

.gdpr-box.Category-Score-box .progress-part {
  margin-top: 15px;
}

.impact-on-vendore-heading {
  background: #150a4c;
  border-radius: 10px;
}

.impact-on-vendore-heading h2 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 17.41px;
  text-align: left;
  border-bottom: 1px solid #ffffff1a;
  padding-bottom: 20px;
  padding-top: 25px;
}

.top-3-box-risk .tagClass.Medium {
  background: #ffd700;
  color: #000000;
  width: 104px;
}

.top-3-box-risk .tagClass.High {
  background: #ffa500;
  color: #333333;
  width: 104px;
}

.top-3-box-risk .tagClass.Critical {
  background: #ff0000;
  color: #ffffff;
  width: 104px;
}

.top-3-box-risk .tagClass.Low {
  background: #008000;
  color: #ffffff;
  width: 104px;
}

.MuiDataGrid-withBorderColor {
  border-color: #ffffff00 !important;
}

.add-vendor-tabs {
  text-transform: capitalize;
}

.add-vendor-tabs button {
  text-transform: capitalize;
  font-size: 16px;
}

.custom-tooltip {
  background-color: #190b5f !important;
  /* Change background color */
  color: #ffffff !important;
  /* Optional: Change text color */
  font-size: 14px;
  /* Optional: Adjust font size */
}

.download-report-button {
  min-width: 189px !important;
  height: 43px;
  font-family: Mona-Sans, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 13.54px;
  text-align: center;
  padding: 15px 20px !important;
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%);
  /* margin: 0px 0px 0px 25px !important; */
  border-radius: 50px !important;
}

.download-report-button img {
  margin-right: 7px;
}

.soc-grid-boxes {
  background-color: #271969;
  border-radius: 10px;
  padding: 25px;
  min-height: 183px;
  text-align: center;
  position: relative;
  transition: 0.8s all;
}

.allgenesis-ai-list {
  padding: 50px;
  background-color: #150a49;
  border-radius: 25px;
}

.soc-grid-boxes h3 {
  margin-bottom: 0;
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #fff;
}

.imgallgenesis,
.imgallgenesis img {
  width: 55px;
  height: 55px;
  border-radius: 100px;
  display: block;
  text-align: center;
  margin: 0px auto 30px;
}

.soc-grid-boxes:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  top: 50px;
  border: 1px solid #ffffff0d;
}

.soc-grid-boxes:hover {
  background: linear-gradient(
    180deg,
    rgba(39, 25, 105, 0.4) 0%,
    rgba(134, 16, 161, 0.4) 100%
  );
  cursor: pointer;
}

.top-0issues {
  margin-top: -30px;
}

.edit-show-compliance {
  width: 619px;
  margin-inline: auto;
  background: #120a3a;
  border-radius: 10px;
  margin-top: 50px;
}

.hippa-background {
  background: #180b58;
  margin-top: 30px;
  max-width: 500px;
}

.hippa-background .paddingstack-box {
  padding: 40px 35px;
}

.hippa-background .textdeatil-complaince {
  font-size: 13.34px;
}

.hippa-background .complaince-issues-heading {
  font-size: 13.34px;
  margin-bottom: 0px;
  max-width: 104px;
  line-height: 17px;
}

.inline-align-cards {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2px;
  margin-top: 20px;
}

.compliance-logo {
  width: 62px;
  height: 62px;
}

.flex-ai-animation {
  display: flex;
  align-items: center;
  gap: 23px;
}

.animation-ai-round {
  width: 189px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 0 #000;
  --border-angle: 0turn;
  --main-bg: conic-gradient(
    from var(--border-angle),
    #213,
    #120a3a 5%,
    #120a3a 60%,
    #213 95%
  );
  border: solid 2px transparent;
  border-radius: 2em;
  --gradient-border: conic-gradient(
    from var(--border-angle),
    transparent 25%,
    #5c0ecc,
    #89119e 99%,
    transparent
  );
  background: var(--main-bg) padding-box, var(--gradient-border) border-box,
    var(--main-bg) border-box;
  background-position: center center;
  animation: bg-spin 3s linear infinite;
  font-family: Mona-Sans, sans-serif;
  cursor: pointer;
}

.animation-ai-round img {
  margin-right: 7px;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

.animation-ai-round:hover {
  /* animation-play-state: paused; */
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.show-complaince-popup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ffffff33;
  margin-bottom: 20px;
  padding: 12px 25px 0;
}

.show-complaince-popup h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 17.41px;
  text-align: left;
  margin-top: 8px;
}

.bg-section-img-cover {
  background: #290cb266;
  border-radius: 10px;
  width: 95px;
  height: 95px;
  text-align: center;
  padding: 18px 0;
}

.paddingstack-box {
  padding: 40px 43px;
}

.view-complaince-heading-popup {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 15.47px;
  text-align: left;
}

.discription-complaince-text {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.show-document-complaince-button {
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%);
  border-radius: 10px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.54px;
  text-align: left;
  height: 43px;
  color: #fff;
  padding: 20px !important;
}

.complaince-issues-heading {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 11.6px;
  text-align: left;
}

.textdeatil-complaince {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 11.6px;
  text-align: left;
  margin-bottom: 15px;
}

.assessing-overall-ai {
  background: linear-gradient(180deg, #150a4c 0%, #211077 100%);
  width: 650px;
  height: 100%;
  border-radius: 10px;
  padding: 25px;
  overflow: auto;
}

.assessing-overall-ai::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Match this with your container background color */
}

.assessing-overall-ai::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.assessing-overall-ai::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.assessing-overall-ai::-webkit-scrollbar-thumb {
  background: #4c4c74;
  /* Customize the thumb color */
  border-radius: 8px;
}

.typing-effect::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Match this with your container background color */
}

.typing-effect::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent !important;
}

.typing-effect::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.typing-effect::-webkit-scrollbar-thumb {
  background: transparent;
  /* Customize the thumb color */
  border-radius: 8px;
}

.text-center-gifimage {
  text-align: center;
  margin: 210px auto;
  margin-inline: auto;
  display: block;
}

.typing-effect h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 17.41px;
  text-align: left;
  color: #fff;
  /* margin-top: -20px; */
}

.typing-effect h4 {
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  margin-bottom: 0;
  background: #5b40dd17;
  padding: 10px 20px;
  border-radius: 10px 10px 0px 0px;
  color: #fff;
}

.typing-effect ol {
  padding-left: 23px;
}

.typing-effect h5 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

.typing-effect ul li strong {
  font-family: Mona-Sans, sans-serif;
  margin-bottom: 15px;
  font-weight: 500 !important;
  color: #fff;
}

.typing-effect ul {
  padding: 0;
  list-style: none;
  background-color: #2a198161;
  margin-top: 0;
  padding: 20px 20px;
  border-radius: 0px 0px 10px 10px;
}

.typing-effect ul li {
  list-style: none;
  font-family: Mona-Sans, sans-serif;
  margin-bottom: 15px;
  border-bottom: 1px solid #ffffff1a;
  padding-bottom: 16px;
  font-size: 16px;
  color: #fff;
}

.gif-boxes .MuiPaper-elevation {
  max-width: 100%;
  background-color: transparent !important;
  overflow: inherit !important;
}

.remove-line-type {
  border: none;
}

.entervalid-domain-text {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.24px;
  text-align: left;
  max-width: 50%;
}

.add-vendor-tabs {
  border-bottom: 1px solid #ffffff1a;
}

.marin-right-another-button {
  margin-right: 25px !important;
}

.w120-fix {
  width: 120px;
}

.text-add-vendo-popup-valid {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  margin-top: 26px;
  margin-bottom: 10px;
}

.m-auto-align-center {
  margin: 0 !important;
}

.top-reduces {
  margin-top: 30px;
}

.ai-search-visible-data h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 21px;
}

.ai-search-visible-data h4 {
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  margin-bottom: 0;
  background: #5b40dd17;
  padding: 10px 20px;
  border-radius: 10px 10px 0px 0px;
}

.ai-search-visible-data h5 {
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.ai-search-visible-data ul {
  padding: 0;
  list-style: none;
  background-color: #2a198161;
  margin-top: 0;
  padding: 20px 20px;
  border-radius: 0px 0px 10px 10px;
}

.ai-search-visible-data ul li {
  list-style: none;
  font-family: Mona-Sans, sans-serif;
  margin-bottom: 15px;
  border-bottom: 1px solid #ffffff1a;
  padding-bottom: 16px;
  font-size: 16px;
}

.ai-search-visible-data ul li strong {
  font-family: Mona-Sans, sans-serif;
  margin-bottom: 15px;
  font-weight: 500 !important;
}

.ai-fill {
  cursor: pointer;
}

.widthfitupload {
  width: -webkit-fill-available;
  top: 24px;
}

.deadline-border fieldset {
  border-color: #ff00004d !important;
}

.extendDeadline-border fieldset {
  border-color: #2bd43c66 !important;
}

.deadline-border
  .MuiInputBase-colorPrimary.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #ff00004d !important;
}

.extendDeadline-border
  .MuiInputBase-colorPrimary.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #2bd43c66 !important;
}

.extend-width-doc {
  width: fit-content;
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-cell {
  padding: 0 25px 0 25px !important;
}

.rightmanageMore {
  padding-right: 17px !important;
}

.backlink img {
  cursor: pointer;
}

.popupmapped-issues {
  max-width: 650px;
  margin: 0px auto;
  height: 600px;
  overflow-y: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #120a3a;
}

.popupmapped-issues::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Match this with your container background color */
}

.popupmapped-issues::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.popupmapped-issues::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.popupmapped-issues::-webkit-scrollbar-thumb {
  background: #4c4c74;
  /* Customize the thumb color */
  border-radius: 8px;
}

.popupmapped-issues h3 {
  font-weight: 500;
  font-family: Mona-Sans, sans-serif;
  margin-top: 0px;
}

.popupmapped-issues .pop-flex-remiadation {
  padding: 0;
  margin-bottom: 20px;
}

.category-three-tabs-left {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 15px 0px 0px;
}

.bg-attack-surface-cover {
  background-color: #150a4c;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  align-items: center;
}

.bg-attack-surface-cover .add-top {
  margin-bottom: 4px;
}

.vendor-info-text-left {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 32px;
}

.vendor-info-text-left h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}

.circular-progress-new-class {
  padding-top: 4px !important;
}

.export-ex .value-text text tspan {
  fill: #000 !important;
  text-shadow: none !important;
}

.AttackScore.export-ex .MuiOutlinedInput-input {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  color: #000 !important;
}

.AttackScore.export-ex .MuiSvgIcon-root {
  color: #000 !important;
}

.impact-on-vendor-box {
  background: #150a4c;
  border-radius: 10px;
  width: 100%;
  padding-bottom: 12px;
}

.impact-on-vendor-box h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 17.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-bottom: 1px solid #ffffff1a;
  padding-bottom: 20px;
  color: #fff;
  padding-left: 25px;
}

.impact-on-vendor-box .impact-analysis-box p {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 15.47px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  margin-bottom: 0px;
}

.impact-on-vendor-box .impact-analysis-box span {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 15.47px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}

.flex-middle-analysis {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.impact-analysis-box {
  padding: 0px 25px 5px;
}

.chart-background-fill {
  position: relative;
}

.chart-background-fill:before {
  content: "";
  position: absolute;
  background: #d9d9d933;
  border-radius: 5px;
  width: 100%;
  height: 9px;
  top: 10px;
}

.container-flex-low-high {
  display: flex;
  gap: 97px;
  width: 100%;
  margin-top: 18px;
  font-size: 13px;
  padding-left: 47px;
}

.container-flex-low-high span {
  display: block;
}

.another-new-class .MuiTypography-subtitle2 {
  display: none;
}

.position-all-button {
  position: absolute;
  right: 0;
}

.issue-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Aligns date to the top-right */
  padding: 8px;
  border-bottom: 1px solid #ddd; /* Optional: Adds separation */
  position: relative;
}

.issue-date {
  font-size: 12px;
  color: gray;
  /* margin-bottom: 4px; Adds spacing between date and text */
  margin: 0px 0px 2px auto !important;

}

.issue-content {
  text-align: left;
  width: 100%;
}
