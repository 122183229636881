.fullWidthTextarea{
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    border-radius: 15px !important;
    padding: 16.5px 14px;
    border-color: #4b456e;
    font-size: 14px;
    background: #150d43;
    color: #fff;
}

.w-100{
    width: 100% !important;
    height: 100px !important;
    margin: 0!important;
}

.btn-arrange {
    display: flex;
    justify-content: right;
    flex-direction: row;
    width: 100%;
}

.dark-mode-class .bg-dark-assessment{
    background-color: #150d43;
}

.btn-arrange button{
 margin:20px 0px 0 15px!important;
 min-width: 140px;
}

textarea:focus-visible{
outline: -webkit-focus-ring-color auto 0px;
}

.mb10{
    margin-bottom: 10px;
}

textarea::placeholder, 
.MuiSelect-select input::placeholder{
    color: #B1B3B7!important;
}

input::placeholder{
    color: #B1B3B7!important;
}

.MuiBox-root.css-wb8uet {
    min-width: 100px;
}
.MuiBox-root.css-zxo5k6 {
    min-width: 100px;
}

.MuiBox-root.css-12nk3c2 {
    min-width: 100px;
}

.MuiBox-root.css-1w276tl {
    min-width: 100px;
}


.css-1b1gipw-MuiFormControl-root-MuiTextField-root {
    color: #000;
}

.send-assessment {
    min-width: 189px;
    height: 40px;
    font-family: Mona-Sans, sans-serif!important;
    font-size: 14px!important;
    font-weight: 600!important;
    line-height: 13.54px;
    text-align: center;
    padding: 15px 20px!important;
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
    margin: 23px 30px 23px auto!important;
  }
  .send-assessment-button {
    min-width: 189px;
    height: 40px;
    font-family: Mona-Sans, sans-serif!important;
    font-size: 14px!important;
    font-weight: 600!important;
    line-height: 13.54px;
    text-align: center;
    padding: 15px 20px!important;
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
    margin: 11px 30px 11px auto!important;
  }
  
  .send-arrow-icon{
    margin-right: 15px;
  }
  .deadline-icon{
    padding-left: 0px;
  }

  .select-entries.topmove-form{
    top: 0;
    padding-bottom: 0!important;
 
  }

  .topmove-entries{
    padding-top: 0!important;
  }

.select-vendor-popup{
width: 486px;
height: 316px;
background: #120A3A;
padding: 0px!important;
}

.select-vendor-popup::-webkit-scrollbar-corner {
    background-color: transparent; /* Match this with your container background color */
}

.select-vendor-popup::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: transparent!important;
}

.select-vendor-popup::-webkit-scrollbar-track {
    background: transparent!important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
}

.select-vendor-popup::-webkit-scrollbar-thumb {
    background: #4c4c74; /* Customize the thumb color */
    border-radius: 8px;
}

.select-assessment-heading{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #FFFFFF33;
    padding: 20px;
}

.select-assessment-heading img{
    cursor: pointer;
}

.padding-form-assessment{
    padding: 20px;
}

.select-dropdown-assessment{
    width: 349px!important;
    height: 49px;
    margin-bottom: 36px !important;
}

.select-dropdown-assessment label{
    top: 10px;
    font-family: Mona-Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 13.54px;
    text-align: left;
    left: 4px;

}

.select-dropdown-assessment .MuiOutlinedInput-root{
    height: 49px;
    border-radius: 10px;
}

.padding-form-assessment p{
    text-align: left;
    margin-top: 15px;
    margin-bottom: 36px;

}

.cancel-popups-btn{
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
    padding: 15px 65px;
    font-family: Mona-Sans, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 13.54px;
    text-align: left;
    border-radius: 10px;
    width: 162px;
    height: 43px;

}

.assessment-send .css-at26bj-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
    min-width: 189px;
}

.topspace-extend{
    margin-top: 15px;
}

body .cancel-as-well{
    background: transparent!important;
    border: 1px solid #FFFFFF66!important;
    margin-right: 24px!important;
}

.deadline-input{
    height: 49px;
}

.deadline-input .MuiOutlinedInput-root{
    border-radius: 10px;
    font-family: Mona-Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 15.47px;
    text-align: left;
    height: 49px;
}

.deadline-input label{
    font-family: Mona-Sans, sans-serif;
    font-weight: 600;
    line-height: 13.54px;
    text-align: left;
    
}
.deadline-input input{
    position: relative;
    top: 2px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
  }

 .bgmodalpop .MuiBox-root.css-noekc2{
    background-color: #120A3A;
  }

  .vendor-contact-heading{
    font-family: Mona-Sans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    margin-top: 0px;

  }

.MuiDrawer-paper{
  border-radius: 10px 0px 0px 0px;
}

.MuiDrawer-paper::-webkit-scrollbar-corner {
    background-color: transparent; /* Match this with your container background color */
}

.MuiDrawer-paper::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: transparent!important;
}

.MuiDrawer-paper::-webkit-scrollbar-track {
    background: transparent!important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
}

.MuiDrawer-paper::-webkit-scrollbar-thumb {
    background: #4c4c74; /* Customize the thumb color */
    border-radius: 8px;
}

.drawerlist{
    background: #140A43;
    padding: 35px 30px;
    height: 100%;
}

.mapping-tab-heading{
font-family: Mona-Sans, sans-serif;
font-size: 18px;
font-weight: 600;
line-height: 14px;
letter-spacing: 0.03em;
text-align: center;
border-bottom: 1px solid #FFFFFF0F;
}

.mapping-tab-heading button{
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
}

.mapping-tab-heading button.Mui-selected{
background: #231477;
border: 1px solid #6e0e9b;
border-radius: 10px 10px 0 0;
color: #fff !important;
}

.bordertab-none{
    margin-top: 29px;
}

.issue-mapped-tab{
    background: linear-gradient(90deg, rgba(191, 6, 198, 0.04) 0%, rgba(77, 16, 165, 0.04) 100%);
    padding: 20px 25px;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: relative;
    width: 100%;
}
.issue-mapped-tab:before{
    content: '';
    position: absolute;
    top: -17px;
    left: 67px;
    border-left: 12px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 13px solid #1a0a48;
    width: 21px;
    height: 18px;
}

.issue-mapped-tab p{
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #fff;
}

.mr-image{
    padding-right: 10px;
}

.bordertab-none .mapping-issue{
    border-radius: 0px;
}

.question-mapping{
    margin-top: 40px;
}

.form-group-radiotype{
    padding-left: 46px !important;
    padding-top: 15px !important;
}

.MuiDrawer-paper{
    background-color: #140A43!important;
    background-image: none!important;
}

.table-top-map{
    margin-top: 40px;
    width: 100%;
    padding-bottom: 15px;
}

.table-header-mapping{
    background: #190C53;
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 41px;
    text-align: left;
    padding: 20px;
}
.table-header-mapping th{
font-family: Mona-Sans, sans-serif;
font-size: 16px;
font-weight: 600;
line-height: 41px;
text-align: left;
border-bottom: 0px!important;
}

.table-top-map td{
    border-bottom: 1px solid #FFFFFF1A;
    background-color: #1E0F60;
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}

.dns-box{
    display: flex!important;
    align-items: center;
    gap: 12px;
}


.form-control-labels .MuiFormControlLabel-label{
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 400!important;
line-height: 20px;
text-align: left;

}

.form-control-labels .PrivateSwitchBase-root{
    color: #ffffff33!important;

}

.button-high-low img{
    display: block;
}

.evidence-mapped{
    background: #190C53;
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 17.41px;
    text-align: left;
    padding: 25px 47px;
    margin-top: 25px;
}

.evidence-map-questions{
    background-color: #1E0F60;
    padding: 20px;
}

.evidence-map-questions .question-mapping{
    margin-top: 0px;
}

.flexpdf-page{
    background: #271771;
    border-radius: 2px;
    /* width: 191px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 13px;
    margin-left: 47px;
}

.flexpdf-page span{
font-family: Mona-Sans;
font-size: 14px;
font-style: italic;
font-weight: 400;
line-height: 41px;
text-align: left;

}

.flex-from-group{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 35px;
    margin-bottom: 15px;
    justify-content: space-between;
}

.clear-filter{
background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 600;
line-height: 13.54px;
text-align: center;
border-radius: 10px;
}

.contact-person .MuiSelect-select{
    height: 60px;
    border-radius: 10px;
}
.viewbutton-map{
    background: linear-gradient(90deg, #AF1BFF 0%, #480D95 100%);
    border-radius: 10px!important;
    font-family: Mona-Sans, sans-serif!important;
    font-size: 16px!important;
    font-weight: 600;
    line-height: 41px;
    text-align: center;
    width: 131px;
    padding: 7px!important;
}

.flex-views-btn{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.Select-Json-File{
   margin: 0px!important;
}

.margin-right-send-assessment-button{
    margin: 0px 0px 0px auto !important;
}


form.save-vendor-jsonfile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.savejson {
    width: 172px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    letter-spacing: 0.5px !important;
    border-radius: 10px !important;
    font-size: 15px!important;
}

.color-gradient-aai{
    background: linear-gradient(90deg, #FF00D8 0%, #8324F9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.assessment-top-table{
    margin-top: 25px;
}

.assessment-top-table .select-entries{
    top:-4px;
}   