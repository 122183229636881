.vendor-information{
    background: #150D43;
    padding: 33px 30px 30px 30px;
    border-radius: 10px;
    border: 1.26px solid #404040;
}

.relation{
    color: #242526;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    margin-top: 25px;
}

.btn-right {
    text-align: right;
}


.m-l {
    margin: 0px 20px 0!important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.m-l-0{
margin: 0px 0px 0px 0px!important;
}

.d-flex-back-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.vendor-information-head{
    color: #242526;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
}

.vendor-information-head span{
    border: 1px solid #dbc5ff;
    width: 40px;
    display: inline-block;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
    border-radius: 50%;
    background: #f5f6f7;
    color: #4a10a4;
    line-height: 39px;
    font-weight: 500;
}

.line-hr {
    border-top: 1px solid #E4E6EA;
    display: block;
    width: 100%;
    margin-top: 10px;
}

.dark-mode-class .vendor-information-head{
    color: #fff;
}
.dark-mode-class .line-hr{
    border-top: 1px solid #3A3B3C; 
}
.dark-mode-class .relation{
    color: #fff;
}

.mt-0{
    margin-top: 0px;
    margin-bottom: 0;
}

.vendor-information p {
    font-size: 14px;
    color: #242526;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
}

.relation svg {
    vertical-align: middle;
    color: #7c7c7d;
}

.dark-mode-class .relation svg{
color: #fff!important;
}

.MuiCheckbox-colorPrimary.Mui-checked{
    color: #4a10a4!important;
}

.assessment-card .MuiPaper-elevation1{
    background: #21185833 !important;
    width: 100%;
    min-height: 200px;
    position: relative;
    border: none!important;
    transition: 0.8s all;

}
.assessment-card .MuiPaper-elevation1:hover{
    background: linear-gradient(180deg, rgba(33, 24, 88, 0.2) 0%, rgba(190, 51, 187, 0.2) 100%)!important;
}
.name-optional{
background: #1D125B;
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 14px;
text-align: center;
padding: 15px;
position: absolute;
width: 100%;
bottom: 0;
color: #fff;
}

.contact-person .MuiOutlinedInput-root {
    height: 60px;
    border-radius: 10px;
}

.reassessments-btn{
    border-radius: 10px;
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
    font-family: Mona-Sans, sans-serif!important;
    font-size: 16px!important;
    font-weight: 600!important;
    line-height: 15.47px;
    text-align: center;
    height: 60px;
    width: 100%;

}

.reassessments-btn img{
    margin-right: 14px;
}

.border-bottom{
    border-bottom: 1px solid #FFFFFF0F;
    padding-bottom: 40px;
}

.re-assessment-date{
    padding-top: 40px;
}

.border-separate{
    border-bottom: 1px solid #FFFFFF0F;
    padding-bottom: 20px;
    margin-top: 20px;
}

.mtop-vendor{
    margin-top: 30px;
    padding-top: 0px;
}

.vendor-options-below{
font-family: Mona-Sans, sans-serif;
font-size: 22px;
font-weight: 600;
line-height: 14px;
text-align: left;
color: #fff;
padding-top: 50px;
}

.card-media-img {
    position: relative;
    top: 35px;
}

.next-steps-button{
background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
font-family: Mona-Sans, sans-serif!important;
font-size: 18px!important;
font-weight: 600!important;
line-height: 17.41px;
text-align: center;
padding: 10px 90px !important;
height: 60px;
margin-top: 45px !important;
margin-bottom: 35px!important;
}

.assessment-card{
    margin-left: -12px!important;
}

.cybersecurity-assessment-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: linear-gradient(90deg, rgba(39, 25, 105, 0.6) 0%, rgba(21, 13, 67, 0.6) 100%);
    border: 1px solid #404040;
    border-radius: 10px 10px 0px 0px;
    padding: 16px 23px;
    margin-top: 35px;
}

.cybersecurity-assessment-box p{
    font-family: Mona-Sans, sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    padding: 0;
    margin: 0px;
    display: flex;
    align-items: center;
    gap: 15px;
    color: #fff;
}
.dpcyber{
    width: 40px;
    height: 40px;
    background-color: #150D43;
    border-radius: 100px;
}

.dpcyber img{
    border-radius: 100px;
}

.top-demo-space{
    margin-top: 12px;
}

.ai-fill-btn{
    background: linear-gradient(90deg, #371D5C 0%, #6826AC 39.2%, #7028B1 57.2%, #5E1386 100%);
    border: 1.5px solid transparent!important; 
    border-image: linear-gradient(90deg, #9036FF 0%, #8E31FF 100%)!important;
    border-image-slice: 1!important;
    box-shadow: 0px 0px 0.45px 0px #5B4162!important;
    box-shadow: 0px 0px 0.9px 0px #5B4162!important;
    box-shadow: 0px 0px 3.14px 0px #5B4162!important;
    box-shadow: 0px 0px 6.29px 0px #5B4162!important;
    box-shadow: 0px 0px 10.78px 0px #5B4162!important;
    box-shadow: 0px 0px 18.87px 0px #5B4162!important;
    width: 117px;
    font-family: Mona-Sans, sans-serif!important;
    font-size: 16px;
    font-weight: 600;
    line-height: 15.47px;
    letter-spacing: -0.02em;
    text-align: left;
    height: 33px;
    border-radius: 50px;
}

.ai-fill-img{
cursor: pointer;
height: 65px;
width: 126px;
object-fit: cover;
}

.tabs-bg.top-demo-space{
    padding: 0px;
    background: #1b134b;
    border: 1px solid #3A3B3C;
}

.tabs-bg.top-demo-space .Mui-selected{
    background: linear-gradient(90deg, rgba(191, 6, 198, 0.3) 0%, rgba(77, 16, 165, 0.3) 100%);
}

.tabs-bg.top-demo-space  .MuiTab-root{
    font-family: Mona-Sans, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 13.54px;
    text-align: left;
    border-bottom: 1px solid #FFFFFF0F;
}

.tabs-bg.top-demo-space .MuiTabs-vertical {
    max-width: 100%;
    min-width: 100%;
    border: none!important;
    border-color: transparent!important;
}
.tabs-bg.top-demo-space .MuiTabs-vertical .Mui-selected:first-child{
    border-radius: 10px 10px 0 0!important;
}
.tabs-bg.top-demo-space .MuiTabs-vertical .Mui-selected{
    border-radius: 0px!important;
    font-weight: 400!important;
    color:#fff!important;
}

.tabs-bg.top-demo-space .MuiButtonBase-root.MuiTab-root{
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 13.54px;
text-align: left;
padding: 24px;
color:#FFFFFFAD;

}

.space-questionnaire{
    display: flex;
    width: 100%;
    gap: 13px;
}

.w-100-160{
    width: 500px;
    padding: 0px;
}

.w-100-cover{
    width: 100%;
}

.tabs-bg.top-demo-space .MuiTabs-indicator{
    background-color: transparent!important;
}

.flex-radio-check {
    display: flex;
    align-items: center;
    margin: 11px 0px;
}

.flex-radio-check span{
    padding: 0px 5px 0 0;
}

.flex-radio-check label{
    margin-right: 0px;
    width: 29px;
}

.flex-radio-check p{
    margin: 0px;
}

.flex-radio-check .data-text-preview{
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
margin-left: 0px;

}

.options-flex {
    display: flex;
    gap: 20px;
    padding-left: 75px;
    margin-top: 20px;
    margin-bottom: 26px;
}


.textarea-auto-size {
    width: 100%;
    display: block;
    margin-left: 70px;
    background: transparent;
    border-radius: 10px;
    height: 107px!important;
    margin-right: 27px;
    color: #fff;
}
.textarea-auto-size-answered{
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    border: 1px solid #E4E6EA;    
    display: block;
    margin-right: 27px;
    border-radius: 10px;
    margin-left: 70px;
    background: transparent;
    color: white;
}    
.textarea-auto-size::placeholder {
    font-size: 14px; /* Adjust font size */
    color: #fff;
    font-family: Mona-Sans;
    font-size: 16px;
    /* font-weight: 400; */
    line-height: 22px;
  }
    

.multiple-questionniareloop {
    margin-bottom: 25px;
}

.que-indexing{
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
}

.que-indexing .preview-question-heading{
    margin: 0px;
    background: #271771;
    width: 35px;
    height: 30px;
    text-align: center;
    font-family: Mona-Sans, sans-serif !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    line-height: 29px;
    border-radius: 5px;
    margin-top: 3px;
}

.flex-preview-tab{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.flex-preview-tab-failed-controls{
    display: flex;
    align-items: center; 
    gap: 4px; 
    width: 100%;
}
.multiple-questionniareloop .options-flex{
  display: block;
}

.mapping-issue{
    background: linear-gradient(90deg, rgba(191, 6, 198, 0.3) 0%, rgba(77, 16, 165, 0.3) 100%);
    padding: 16px 20px;
    border-radius: 10px 10px 0px 0px;
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 15.54px;
    text-align: left;
    color: #fff;
}

.mapping-issue img{
    vertical-align: bottom;
}

.tick-selected-icon{
    display: none;
}

.selected-assessment-card .tick-selected-icon{
    display: block;
    position: absolute;
    right: 7px;
    top: 7px;

}

.flex-qustionnire-property{
    display: flex;
    align-items: center;
}

.questionnnire-send-assessment{
    min-width: 189px;
    height: 43px;
    font-family: Mona-Sans, sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 13.54px;
    text-align: center;
    padding: 15px 20px !important;
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
    margin: 0px 0px 0px auto !important;
}

.contact-person-vendor{
    height: 60px;
    border-radius: 10px!important;
}
.fullpopup-send-assessment{
    text-align: center;
}

.fullpopup-send-assessment .inner-popup-design{
    background: url('../../assets/ellipse-bg-send.svg'), #080418;
    box-shadow: 4px 4px 4px 20px #0A0C2200 !important;
    width: 541px;
    height: 352px;
    border-radius: 10px;
    text-align: center;
    padding: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    display: flex;
    align-items: center;

}

.fullpopup-send-assessment .inner-popup-design h2{
font-family: Mona-Sans;
font-size: 21.2px;
font-weight: 600;
line-height: 20.5px;
text-align: center;
background: linear-gradient(90deg, #BA55DF 0%, #A96DFF 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}

.fullpopup-send-assessment .inner-popup-design p{
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;
padding: 10px 30px;
}

.color-ok-pop{
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
    font-family: Mona-Sans, sans-serif!important;
    font-size: 13px!important;
    font-weight: 600!important;
    line-height: 12.57px;
    text-align: left;
    border-radius: 10px!important;
    padding: 10px 20px;
    color: #fff!important;
    width: 81px;
    height: 36px;
    
}
.css-1qxadfk-MuiPaper-root-MuiDialog-paper{
    border-radius: 10px!important;
}

