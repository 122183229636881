body {
    overflow-y: hidden;
    background-color: #120A3A!important;
}

html {
    scroll-behavior: smooth;
}

.username {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: 20px 20px 0px;
    font-family: 'Poppins';
}

.username img {
    width: 65px;
    height: 65px;
    border-radius: 100px;
    object-fit: cover;
    margin-right: 10px;
    border: 2px solid #4a10a4;
}

.username p {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Poppins';
}

.position-absolute {
    top: 12px;
    left: 8px;
}

.position-relative input {
    padding-left: 30px;
    margin-top: 15px;
    font-family: 'Poppins';
}

.position-relative input:focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
}

.bottom-chat h4 {
    font-size: 15px;
    margin: 20px 0px 10px;
    color: #afafaf;
    font-family: 'Poppins';
}

.bottom-chat i {
    font-size: 14px;
}

.recentchat img {
    width: 65px;
    height: 65px;
    border-radius: 100px;
    object-fit: cover;
}

.recentchat {
    display: block;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 5px;
}

.recentchat img {
    margin-right: 15px;
    float: left;
}

.recentchat span {
    color: #afafaf;
}

.time {
    font-size: 12px;
}

.common-chat img {
    width: 65px;
    height: 65px;
    border-radius: 100px;
    object-fit: cover;
}

.chats-box {
    display: flex;
    margin-top: 30px;
    margin-left: 25px;
}

.chats-box img {
    width: 65px;
    height: 65px;
    border-radius: 100px;
    object-fit: cover;

}


.chats-text {
    background-color: #fff;
    padding: 15px;
    border-radius: 12px;
    margin-bottom: 8px;
    width: 100%;
    font-family: 'Poppins';
    word-break: break-all;
}

.textareas {
    margin: 0 30px;
}

.textareas span {
    font-size: 13px;
    color: #afafaf;
    display: block;
    margin-top: -5px;
}

.response-reply {
    background-color: #e3effd;
    padding: 15px;
    border-radius: 12px;
    width: 100%;
    margin-bottom: 8px;
    font-family: 'Poppins';
    word-break: break-all;
}

/* @keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.blinking-dot {
    animation: blink 1s infinite;
    padding: 15px;
    border-radius: 12px;
    width: fit-content;
    margin-bottom: 8px;
    font-family: 'Poppins';
} */

@keyframes increaseDots {
    0% {
        content: 'Sending';
    }

    25% {
        content: 'Sending.';
    }

    50% {
        content: 'Sending..';
    }

    75% {
        content: 'Sending...';
    }

    100% {
        content: 'Sending....';
    }
}

.blinking-dot::after {
    display: inline-block;
    animation: increaseDots 1s linear infinite;
    content: 'Sending....';
}



.reply-chat {
    float: right;
    margin-top: 8px;
    font-family: 'Poppins';
}

.reply-chat span {
    font-size: 14px;
    color: #afafaf;
    display: block;
    margin-top: -5px;
}



.scroll-chats::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.scroll-chats::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.scroll-chats::-webkit-scrollbar-thumb {
    background: #cbc7c7;
}

.write-message {
    border: none !important;
    width: 83%;
    height: 50px;
    margin-left: 0;
    padding: 10px;
    background: transparent;
    padding-left: 65px;
    color: #fff;
}




.position-relative i {
    color: #afafaf;
}

.position-relative::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #afafaf;
}

.position-relative:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #afafaf;
}

.position-relative::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #afafaf;
}

input:focus {
    outline: none;
}

.send-plane {
    position: absolute;
    right: 14px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
    background-color: #fafafa;
    color: #7a7a7a;
}


.upload-btn-wrapper {
    position: absolute;
    cursor: pointer;
    left: 11px;
    border-radius: 50px;
    background: #fafafa;
    color: #7a7a7a;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 49px;
    font-size: 19px;
}

.upload-btn-wrapper i {
    cursor: pointer;
}

.position-relative {
    margin: 20px;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    right: 0;
}

.active-chat {
    border-radius: 10px;
    box-shadow: 0px 0px 5px #0000000d;
    background-color: #f8f3ff;
}



.write-message::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
}

.write-message:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

.write-message::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
}

.color-place::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #afafaf;
}

.color-place:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #afafaf;
}

.color-place::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #afafaf;
}

.overlay-hide {
    overflow: hidden;
}

.dark-mode-class .chats-text {
    color: #000;
}

.dark-mode-class .response-reply {
    color: #000;
}

.bg-recent-chat.active {
    background-color: #EDE9FC;
    font-weight: 600;
}

.dark-mode-class .bg-recent-chat.active {
    background-color: #4a10a4;
}


@media screen and (min-width: 768px) and (max-width: 991px) {
    .chats-box img {
        width: 35px;
        height: 35px;
    }

    .recentchat img {
        width: 35px;
        height: 35px;
    }

    .username img {
        width: 35px;
        height: 35px;
    }

    .recentchat p {
        font-size: 13px;
    }

    .bottom-chat {
        padding-left: 10px;
    }

    .username {
        padding-left: 10px;
    }

    .position-relative {
        margin-left: 10px;
    }

    .username p {
        font-size: 14px;
    }

    .recentchat img {
        margin-right: 7px;
    }

}

.cover-bgs {
    background-color: #f8f3ff;
    padding-bottom: 5px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .chats-box img {
        width: 35px;
        height: 35px;
    }

    .recentchat img {
        width: 35px;
        height: 35px;
    }

    .username img {
        width: 35px;
        height: 35px;
    }

    .textareas {
        margin: 0 20px;
    }
}


.left-profilepart {
    background-color: #fff;
    /* box-shadow: 0px 0px 6px rgb(0 0 0 / 30%); */
    /* border-right: 1px solid #dee2e6; */
    height: 100%;
    position: relative;
    z-index: 9999;
}

.form {
    position: relative;
}

.chat-box-circle {
    border-radius: 50% 50%;
    border: 1px solid #242526;
    background-color: #18191a;
    width: 50px;
    height: 50px;
    line-height: 47px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.dark-mode-class .chat-box-circle {
    border: 1px solid #242526;
    background-color: #18191a;
}

img.chat-attachment-image {
    max-width: 250px !important;
    padding: 10px 0;
    border-radius: 0 !important;
    min-width: 250px !important;
    height: auto !important;
}

a.chat-attachment-click {
    max-width: 250px !important;
    padding: 10px 0 0;
    border-radius: 0 !important;
    min-width: 250px !important;
    height: auto !important;
}

.username {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: 20px 20px 0px;
    font-family: 'Poppins';
}

.username img {
    width: 65px;
    height: 65px;
    border-radius: 100px;
    object-fit: cover;
    margin-right: 10px;
    border: 2px solid #4a10a4;
}

.username p {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Poppins';
}

.position-absolute {
    top: 12px;
    left: 8px;
}

.position-relative input {
    padding-left: 30px;
    margin-top: 15px;
    font-family: 'Poppins';
}

.position-relative input:focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
}

.bottom-chat h4 {
    font-size: 15px;
    margin: 20px 0px 10px;
    color: #afafaf;
    font-family: 'Poppins';
}

.bottom-chat i {
    font-size: 14px;
}

.recentchat img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    object-fit: cover;
}

.recentchat {
    display: block;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 5px;
}

.recentchat p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: 'Poppins';
}

.recentchat img {
    margin-right: 15px;
}

.recentchat span {
    color: #afafaf;
    font-size: 12px;
}

.time {
    font-size: 12px;
}

.common-chat img {
    width: 65px;
    height: 65px;
    border-radius: 100px;
    object-fit: cover;
}

.chats-box {
    display: flex;
    margin-top: 30px;
    margin-left: 25px;
    width: 100%;
}

.chats-box img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    object-fit: cover;

}

.bg-chatground {
    background: #F5F6F7;
}

.heightfull-chat {
    height: 100%;
    /* background-image: url('../../assets/login-background.svg'); */
    /* background-repeat: repeat; */
    background-size: 60%;
    margin-top: -20px;
}

.dark-mode-class .heightfull-chat {
    height: 100%;
    /* background-image: url('../../assets/darklogin.svg') !important; */
    /* background-repeat: repeat; */
    background-size: 60%;
    margin-top: -20px;
}


.chats-text {
    background-color: #ffffff;
    padding: 11px 13px;
    border-radius: 12px 12px 12px 0px;
    margin-bottom: 8px;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Poppins';
    font-size: 14px;
    color: #000;
    border: 1px solid #dccfef;
}

.textareas {
    margin: 0 15px;
}

.textareas span {
    font-size: 12px;
    color: #afafaf;
    display: block;
    margin-top: -5px;
}

.response-reply {
    background-color: #4a10a4;
    padding: 11px 13px;
    border-radius: 12px 12px 0;
    width: fit-content;
    margin-bottom: 8px;
    font-family: 'Poppins';
    font-size: 14px;
    color: #fff;
}

.reply-chat {
    display: flex;
    justify-content: right;
    margin-top: 8px;
    font-family: 'Poppins';
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap-reverse;
}

.time-hour {
    display: flex;
    justify-content: right;
}

.reply-chat span {
    font-size: 12px;
    color: #afafaf;
    display: block;
    margin-top: -5px;
}

.scroll-chats {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    /* padding-right: 10px; */
    display: block;
    padding: 0px 11px 0px 11px;
    background-color: #1e164b;
}

.scroll-chats::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.scroll-chats::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.scroll-chats::-webkit-scrollbar-thumb {
    background: #cbc7c7;
}


.footer-chat {
    width: 95%;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* bottom: 16px; */
    background-color: #fff;
    right: 0;
    /* left: 0; */
    border-radius: 100px;
    margin: 0px auto;
    font-family: 'Poppins';
    -webkit-box-shadow: -1px 4px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: -1px 4px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: -1px 4px 20px 0px rgba(0, 0, 0, 0.10);
}

.w-100full {
    width: 100%;
}

.write-message {
    font-family: 'Poppins';
    border: none !important;
    width: 83%;
    height: 50px;
    margin-left: 0;
    padding: 10px;
    background: transparent;
    padding-left: 35px;
    color: #000;
    font-size: 14px;
}

.write-message::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
    opacity: 1;
}

.write-message:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000;
    opacity: 1;
}

.write-message::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #000;
    opacity: 1;
}

.position-relative i {
    color: #afafaf;
}

.position-relative::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #afafaf;
}

.position-relative:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #afafaf;
}

.position-relative::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #afafaf;
}

input:focus {
    outline: none;
}

.send-plane {
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
    background-color: #fafafa;
    color: #7a7a7a;
    padding: 0px;
    cursor: pointer;
}


.upload-btn-wrapper {
    position: absolute;
    cursor: pointer;
    left: 11px;
    border-radius: 50px;
    background: #fafafa;
    color: #7a7a7a;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 49px;
    font-size: 19px;
}

.upload-btn-wrapper i {
    cursor: pointer;
}

.position-relative {
    margin: 20px;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    right: 0;
}

.active-chat {
    border-radius: 10px;
    box-shadow: 0px 0px 5px #0000000d;
    background-color: #f8f3ff;
}

.active-chat p {
    color: #212529;
    font-weight: 600;
}

.active-chat span {
    color: #212529;
    font-weight: 600;
}

.full-chat-screen {
    width: 100%;
    /* min-height: 100vh; */
}

.scroll-chats-right {
    overflow-x: hidden;
    overflow-y: auto;
    /* height: 72vh;  */
    height: calc(100vh - 150px);
}

.chatheader-removebg {
    background-color: transparent;
}

.color-place::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #afafaf;
}

.color-place:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #afafaf;
}

.color-place::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #afafaf;
}

.overlay-hide {
    overflow: hidden;
}

.plane-send {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: #4a10a4;
    border: none;
    padding: 11px;
    cursor: pointer;
    margin: 5px 5px 5px 15px;
    position: relative;
    z-index: 999;
}

.plane-send img {
    width: 26px;
    height: 26px;
    margin: -3px -5px;
    padding: 0px;
}

.online-user {
    position: relative;
}

.online-user::before {
    content: '';
    position: absolute;
    display: block;
    width: 11px;
    height: 11px;
    background-color: #32ab32;
    top: 0;
    z-index: 999;
    right: 6px;
    border-radius: 100px;
}

.attach-icon {
    padding: 9px;
    opacity: 0.5;
    width: 55px;
    height: 55px;
}

.badge-numbers {
    right: 5px;
    top: -9px;
}

/* .dark-mode-class .chats-text{
color: #000;
}

.dark-mode-class .response-reply{
    color: #000;
} */

.dark-mode-class .left-profilepart {
    background-color: #1e164b;
    border-right: 1px solid #120a3a;
}

.dark-mode-class .response-reply,
.dark-mode-class .chats-text {
    color: #18191a;
}

.dark-mode-class .write-message {
    color: #18191a;
}

.heading-chect-middle {
    font-family: "Mona-Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    color: #fff;
    margin-top: 0px;
}

.set-welcome {
    text-align: center;
    margin-inline: auto;
    display: flex;
    align-items: center;
}

.please-select-vendor {
    font-weight: 400;
    font-size: 15px;
}

.padding-top0 {
    padding-top: 0 !important;
}

.padding-top0 .MuiGrid-container {
    height: 100%;
}

.flex-property-chat {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.response-bg-none {
    background-color: transparent;
}

.bg-recent-chat {
    background-color: #342c5c;
    border-right: 1px solid #342c5c;
    border-radius: 5px;
    margin-bottom: 10px;
    max-width: 100%;
    color: #fff;
}

.short-name {
    background: #f5f6f7;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    padding: 9px !important;
    margin: 0;
    border: 1px solid #dccfef;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.mr-space {
    margin-right: 10px !important;
}

.topbar-user {
    /*background: #fff; */
    margin: 0;
    padding: 15px;
    border-bottom: 1px solid #dee2e6;
}

.dark-mode-class .topbar-user {
    border-bottom: 1px solid #242526;
}

.self-name {
    font-size: 16px;
}

.dark-mode-class .self-name {
    color: #fff;
}

.normal-text {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    text-align: center;
    line-height: 52px;
    margin-right: 10px;
}

.dark-mode-class .bg-recent-chat {
    background-color: #342c5c;
    border-right: 1px solid #342c5c;
}



.dark-mode-class .response-reply {
    color: #fff;
}

.dark-mode-class .short-name {
    color: #18191a !important;
}

.dark-mode-class .heading-chect-middle {
    color: #fff;
}


@media (max-width: 767px) {


    .scroll-chats {
        height: auto;
        padding: 0px 11px 0px 11px;
    }

    .set-welcome {
        display: block;
    }

    .overlay-hide {
        overflow: inherit;
    }

    .textareas {
        margin: 0 15px;
    }

    .chats-box img {
        width: 35px;
        height: 35px;
    }

    .footer-chat {
        position: absolute;
        bottom: 5px;

    }

}

.image-preview-container {
    max-width: 100px;
    /* Set the maximum width of the container */
    max-height: 100px;
    /* Set the maximum height of the container */
    overflow: hidden;
    /* Hide any overflow beyond the specified width and height */
    position: relative;
    /* Position the container relatively */
}

.image-preview {
    width: 100%;
    /* Make the image fill the container */
    height: 100%;
    /* Make the image fill the container */
    object-fit: cover;
    /* Maintain the aspect ratio and cover the entire container */
    border: 1px solid #ccc;
    /* Add a border for better visibility */
    border-radius: 4px;
    /* Optional: Add border radius for a rounded look */
}

/* Optional: Add styling to indicate that an image is selected */
.image-preview:after {
    content: 'Selected';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 2px;
    font-size: 10px;
    color: #333;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* Ensure it covers the entire height of the chat box */
}

.badge-color-chat span {
    background: #4a10a4;
    color: #fff;
}

/* .chats-text a, */
.response-reply a {
    color: #fff;
}

.preview-image {
    max-width: 100px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
}

.attachment-preview {
    position: absolute;
    bottom: 65px;
    left: 0;
    width: fit-content;
    border-radius: 15px;
    padding: 9px 8px;
}

.attachment-preview.dark {
    background: #18191a;
    border: 1px solid #242526;
}
.attachment-preview.light {
    background: #fff;
}

.attachment-preview-pdf {
    position: absolute;
    bottom: 65px;
    left: 0;
    background: #020202;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 15px;
    padding: 10px 10px 10px 10px;
}
.attachment-preview-pdf.dark {
    background: #020202;
    text-align: center;
}
.attachment-preview-pdf.light {
    background: #fff;
    text-align: center;
}
p.attachment-name {
    font-size: 13px;
    margin: 0;
}
.btn-cross {
    position: absolute !important;
    top: 8px;
    right: 8px;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    min-width: auto !important;
}

.btn-cross svg {
    color: #000000de;
    fill: #000000de;
    margin: -10px;
}

.dark-mode-class .btn-cross svg {
    fill: #fff;
    color: #fff;
    margin: -10px;
    background: #4a10a4;
    border-radius: 100%;
}

.added-blank-preview {
    height: 140px;
}

.dark-mode-class .footer-chat {
    background-color: #1e164b;
    border: 1px solid #120a3a;
}

.dark-mode-class .footer-space {
    margin-top: 150px;
}

.light-mode-class .footer-space {
    margin-top: 150px;
}

.dark-mode-class .attach-icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1102%) hue-rotate(285deg) brightness(122%) contrast(107%);
}

.scroll-chats .reply-chat .full-chat-preview:last-child {
    min-height: 150px;
}


.topHeader-move {
    background: transparent;
}

.dark-mode-class .write-message.enabled-class {
    color: #fff;
}

.upload-icon-chat:hover {
    background: none;
    /* or set it to your preferred background color or image */
}

.upload-icon-chat:hover {
    background: transparent !important;
}

.response-reply.pending {
    margin-top: 8px;
    width: 89px;
}

/* .empty-div-response{
    width: 750px;
    height: 150px;
}
.empty-div-reply{
    width: 750px;
    height: 142px;
} */