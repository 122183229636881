.addfile {

    display: flex;
    align-items: center;
    height: 100%;
}

.bgaddfiles {
    min-width: 100%;
    padding: 45px 35px;
    border-radius: 15px;
    margin-top: 24px;
    text-align: center;
}

.file-upload-modal {
    margin-top: 0px;
}

.select-jeson {
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%)!important;
    line-height: 35px!important;
    width: 169px;
}

.json-uploadfile-align{
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.centerpoint{
    text-align: center;
    width: 100%;
    margin-bottom: 0 !important;
}

.text-centerfeild {
    max-width: 50%;
    margin-bottom: 25px !important;
}

.save-btn-block{
/* display: block; */
margin-top: 20px;
margin-bottom: 20px;
font-family: Mona-Sans, sans-serif!important;
font-weight: 400;
width: 100%;
}

.save-file-btn {
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%) !important;
    width: 416px !important;
    height: 60px !important;
    color: #fff !important;
    border-radius: 10px !important;
    font-family: Mona-Sans, sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 17.41px !important;
    text-align: center;
    margin-top: 55px !important;
    margin-bottom: 35px !important;
}

.upload-questionnaire .mb-28 {
    margin-bottom: 28px !important;
}

.top-60 {
    margin-top: 60px;
}

.css-139lcja .bgaddfiles {
    background-color: #121212;
    color: white;
}

.css-139lcja .heading-main {
    color: white;
}

.json-upload-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.json-upload-form .send-assessment {
    margin: auto !important
}

.json-upload-form button {
    width: 120px;
}