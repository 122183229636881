.checkbox-view-answer .MuiTypography-root{
    font-size: 14px !important;
}

.d-flexalign{
    display: flex;
   align-items: end;
}

.text-copyicon{
    margin-right: 13px;
}

.send-icon {
    border-radius: 100px;
    width: 35px;
    height: 35px;
    text-align: center;
    right: 8px;
    background-color: #4a10a4;
    border: none;
    padding: 2px;
    cursor: pointer;
    margin: 5px;
}

.send-icon img {
    width: 18px;
    height: 31px;
    margin: 1px -3px;
    padding: 0px;
}

.top-anwer-padding{
    margin-top: 0px;
}

.left-space-tab{
    padding: 0px 25px;
    width: 100%;
}

.left-space-tab .inline-parts h3{
    font-family: Mona-Sans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    margin-top: 29px;   
}

.left-space-tab .flex-preview-tab{
  margin-left: 10px;
  max-width: 85%;
}

.left-space-tab .send-icon{
/* display: none; */
}

.left-space-tab .w100-textarea{
    background: transparent;
    border-radius: 10px;
    height: 107px !important;
    border: 1px solid #F8EEEE1A;
    margin-left: 63px;
    width: 90%;
    padding-inline: 15px;
}

.left-space-tab .w100-textarea::-webkit-scrollbar-corner {
    background-color: transparent; /* Match this with your container background color */
}

.left-space-tab .w100-textarea::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: transparent!important;
}

.left-space-tab .w100-textarea::-webkit-scrollbar-track {
    background: transparent!important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
}

.left-space-tab .w100-textarea::-webkit-scrollbar-thumb {
    background: #4c4c74;
    border-radius: 8px;
}

.left-space-tab .MuiFormGroup-root .MuiTypography-body1{
    font-family: Mona-Sans, sans-serif!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 20px;
    text-align: left;
    text-transform: lowercase;
    color: #fff!important;
}

.left-space-tab .MuiFormGroup-root .MuiTypography-body1::first-letter{
    text-transform: capitalize;
}

.icons{
    display: flex;
    align-items: center;
    gap:10px;
    justify-content: right;
}

.view-evidence-button{
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
    color: #fff !important;
    height: 40px;
    text-transform: capitalize !important;
    margin: 0px 0 15px!important;
    border-radius: 6px !important;
    font-family: Mona-Sans, sans-serif !important;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    padding: 10px !important;
    min-width: 20px !important;
}

.passed-failed-button{
    width: 122px;
    height: 29px;
    border-radius: 3.3px !important;
    margin-top: 25px;
    right:0px;
}
.passed-failed-status-button{
    width: 122px;
    height: 29px;
    border-radius: 3.3px !important;
    margin-top: 25px;
    right: 9px;
}

.bordertab-none .no-evidence-available{
    color: #fff;
    font-family: Mona-Sans, sans-serif;
    font-weight: 400;
}

.map-isssues-manage{
padding: 0;
}

.map-isssues-manage .inline-parts h3{
padding-left: 15px;
}

.padding-aling-view-answer {
    padding: 22px 25px 15px;
}

.padding-leftform-control {
    padding-left: 70px;
    margin-bottom: 10px;
}

.padding-leftform-control label{
    width: 100%;
}

.mt-questionpart .w100-textarea{
    max-width: 89%;
    margin-left: 70px;
    margin-bottom: 12px;
}

.contradiction-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #FFFFFF1A;
    padding-top: 20px;
    margin-top: 25px;
}

.flexstatus-side {
    display: flex;
    gap: 115px;
}

.contradiction-status p{
font-family: Mona-Sans, sans-serif;
font-size: 16px;
font-weight: 600;
line-height: 15.47px;
text-align: left;
margin-bottom: 20px;
margin-top: 10px;

}
.isa-text{
    width: 100%;
}
.isa-text span{
    background: #221463;
    font-family: Mona-Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 13.54px;
    text-align: left;
    border-radius: 2px;
    padding: 9px 10px;
    height: 29px;
    display: inline-block;
}

.isa-text span{
    margin-right: 7px;
    margin-bottom: 8px;
}

.status-right-lines{
   position: relative;
}
.status-right-lines .passed-failed-button{
right: 0;
}

.status-right-lines:before{
    content: '';
    position: absolute;
    width: 1px;
    height: 40px;
    background-color: #2c2356;
    right: -56px;
    top: 15px;

}
.response-belt{
    font-family: Mona-Sans, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    text-align: left;
    background: #1D0E65;
    padding: 15px 30px;
    border-radius: 10px 10px 0px 0px;
}

.full-dataaievidence{
    background: #170B52;
    border-radius: 10px;
    padding: 25px;
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-bottom: 20px;
    border-radius: 10px 10px 0px 0px;
}
.full-dataaievidence p{
    margin: 0;
}

.contradiction-found{
    background: #DB23234D;
    border-radius: 10px 10px 0px 0px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.contradiction-found-innerbox{
    background: #170B52;
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    padding: 25px;
    margin-bottom: 20px;
    border-radius: 10px 10px 0px 0px;
}

.contradiction-found-innerbox span{
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

}

.mapped-issue{
    height: 47px !important
}