.companyInfo-input-adornment{
    width: 160px;
    border-radius: 0px 15px 15px 0px !important;
}

.without-vendor{
    margin-top: 20px;
    margin-left: 20px;
    font-weight: 400 !important;
    height: 40px;
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-family: Mona-Sans, sans-serif !important;
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%) !important;
    color: #fff !important;
    border-radius: 10px !important;
    padding: 0px 20px !important;
    line-height: 25px !important;
}