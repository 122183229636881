.heading-main {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: #242526;
  margin-top: 0px;
}

.table-vendor th {
  font-family: Mona-Sans, sans-serif!important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 15px;
  color: #242526 !important;
}

.table-vendor td {
  font-family: Mona-Sans, sans-serif!important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 15px;
  color: #b1b3b7 !important;
}

.vendor-table-card .green-cell {
  color: green !important;
}

.vendor-table-card .red-cell {
  color: red !important;
}

.vendor-table-card .blue-cell {
  color: blue !important;
}

.vendor-table-card .yellow-cell {
  color: yellow !important;
}

.table-vendor td:nth-child(3):not().not-third {
  width: 100px;
}

.css-1ygcj2i-MuiTableCell-root {
  background: rgba(228, 230, 234, 0.35) !important;
}

.table-vendor h6 {
  font-size: 14px !important;
  color: #b1b3b7 !important;
}

.table-vendor td a {
  padding: 7px 12px !important;
  border-radius: 10px !important;
  font-family: Mona-Sans, sans-serif!important;
  font-size: 13px;
}

.table-vendor th:nth-child(4) {
  width: 100px;
}

.addnew {
  width: 250px;
  height: 40px;
  padding: 0 !important;
  display: flex !important;
  margin: 0px 0px 0px auto !important;
}

.executive-report-btn {
  height: 40px;
  padding: 6px 16px !important;
  display: flex !important;
  margin: 0px 0px 0px auto !important;
}

.domain-name {
  margin-bottom: 0px;
  font-family: Mona-Sans, sans-serif!important;
  font-style: normal;
  font-weight: 600;
  /* text-transform: capitalize; */
  margin-top: 10px;
  color: #fff;
}

.add-top {
  margin-top: 0px;
  font-family: Mona-Sans, sans-serif!important;
  font-style: normal;
  color: #fff;
}

.font-p {
  font-family: Mona-Sans, sans-serif!important;
  font-style: normal;
}

.flx-property {
  display: flex;
  /* height: 42vh; */
}

.vendordetail th {
  font-family: Mona-Sans, sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 15px;
  color: #242526 !important;
}

.vendordetail td {
  font-family: Mona-Sans, sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 15px;
}

.css-bj2p8a-MuiPaper-root-MuiCard-root {
  background: #1e1e1e !important;
}

.flx-property .MuiPaper-elevation1 {
  width: 100%;
}

.cover-table {
  background: #150a4c !important;
  width: 100%;
  border-radius: 10px;
  position: relative;
}

.cover-table th {
  background: #1b104e!important;
  border: 0px solid #ffffff08;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  height: 70px;
  color: #fff;
}

.three-dots-btn{
  color: #fff!important;
}

.dark-mode-class .cover-table {
  background: transparent;
  position: relative;
}

.dark-mode-class .cover-table h3 {
  color: #fff;
}

.dark-mode-class .MuiTableRow-headth th {
  background: rgba(82, 79, 79, 0.14) !important;
}

.dark-mode-class .vendordetail th {
  color: #fff !important;
}

.dark-mode-class .total-score {
  color: #fff;
}

.vendor-head {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  color: #ffffff;
  margin-left: 12px;
  margin-bottom: 15px;
}

.full-coverdesign {
  box-shadow: 0px 0px 5px rgb(0 0 0 / 5%) !important;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 0px 0px !important;
}

.total-score {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 50px;
  color: #242526;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.domain-part {
  margin-left: 15px;
}

.manage-middle {
  position: relative;
}

.manage-mid a {
  text-decoration: none !important;
}

.vendordetail .css-1y04ugt-MuiTableCell-root {
  background: rgba(228, 230, 234, 0.35) !important;
}

.vendordetail .css-1drgtl0-MuiButtonBase-root-MuiIconButton-root {
  position: relative !important;
}

.vendordetail {
  padding: 0 !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
}

.css-rylm9l-MuiTableCell-root {
  background: rgba(228, 230, 234, 0.35) !important;
}

.css-1azl6jz-MuiTableCell-root {
  background: rgba(228, 230, 234, 0.35) !important;
}

.vendordetail .css-jhd7ab-MuiTable-root td:first-child {
  padding-bottom: 0px;
}

.dark-mode-class .vendordetail td a {
  color: #fff !important;
}

.css-1r7avug-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
}

.css-3sx0hq-MuiTabs-indicator {
  background-color: #6e0e9b!important;
  height: 1px !important;
}

#chart {
  max-width: 500px;
}

.tabspart .MuiTab-root {
  color: #242526 !important;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
}

.tabspart .Mui-selected {
  color: #4a10a4 !important;
  font-weight: 500;
}

.tabspart .MuiTabs-indicator {
  background-color: #4a10a4 !important;
}

.dark-mode-class .tabspart .Mui-selected {
  background-color: #150d43 !important;
  color: #fff !important;
}

.dark-mode-class .tabspart button {
  color: #fff !important;
  text-transform: capitalize;
}

.compliance-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242526 !important;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
}

.compliance-inline a {
  text-decoration: none;
}

.click-to-begin {
  height: 40px;
  margin: 0px auto !important;
}

.Assessment-box {
  background-color: #fff;
  padding: 25px 35px 10px;
  border-radius: 15px;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #242526 !important;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
}

.Assessment-box-attack {
  background-color: #170B52;
  padding: 0px 0px;
  border-radius: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  color: #ffff !important;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 15px;
}

.manage-mid {
  text-align: center;
  margin-bottom: 25px;
}

.dark-mode-class .Assessment-box {
  background: #242526;
  border: 1px solid #3a3b3c;
}

.Assessment-box h2 {
  margin-bottom: 30px;
}

.dark-mode-class .Assessment-box h2,
.dark-mode-class .compliance-inline h2 {
  color: #fff;
}

.Assessment-box-attack h2 {
  margin-bottom: 30px;
}

.dark-mode-class .Assessment-box-attack h2,
.dark-mode-class .compliance-inline h2 {
  color: #fff;
}

.box-Assessment {
  display: flex;
  width: 100%;
  align-items: center;
  background: #fff;
  /* box-shadow: 0px 0px 5px rgb(0 0 0 / 5%); */
  border-radius: 15px;
  /* padding: 0px 15px; */
  border: 1px solid #e4e6ea;
  margin-top: 0 !important;
  margin-bottom: 25px !important;
  margin-left: -12px !important;
}

.dark-mode-class .box-Assessment {
  background: #242526;
  border: 1px solid #3a3b3c;
}

.dark-mode-class .box-Assessment h5,
.dark-mode-class .box-Assessment h4 {
  color: #fff;
}

.box-Assessment-attack {
  width: 100%;
  align-items: center;
  border-radius: 15px;
}

 .box-Assessment-attack {
  background: #170B52;
  border: 1px solid #170b52;
  box-shadow: 3px 1px 0px 0px #00000000;
  padding-bottom: 5px;
}

.dark-mode-class .box-Assessment-attack h5,
.dark-mode-class .box-Assessment-attack h4 {
  color: #fff;
}

.Assessment-box-attack-a-tag {
  text-decoration: none;
}

.widthboxes {
  width: 100%;
  text-align: left;
}

.fitbutton {
  display: flex;
}

.fitbutton a {
  text-decoration: none;
}

.fitbutton button {
  margin: 0px 0px 0px 15px !important;
  width: 100px;
}

.widthboxes h5 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 20px;
}

.widthboxes h4 {
  font-size: 18px;
  font-weight: 500;
}

.widthboxes p {
  font-weight: 400;
  margin-top: 5px;
  font-size: 14px;
}

.wi-50 {
  width: 50%;
}

.Security-control {
  background-color: #fff;
  padding: 50px 35px;
  border-radius: 15px;
  width: 100%;
}

.Security-control a {
  text-decoration: none;
}

.box-Security-control {
  background: #ffffff;
  border: 1px solid #e4e6ea;
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 20px;
}

.box-Security-control h2 {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  width: 100%;
  padding: 0px 7px;
  margin-bottom: 0px;
}

.fit-bottom {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.fit-bottom h2 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 15px;
  margin: 6px 0 8px;
  padding: 0 7px 11px;
}

.security-text {
  font-family: Mona-Sans, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.dark-mode-class .security-text {
  color: #fff !important;
}

.flex-align {
  display: flex;
}

.dark-mode-class .Security-control {
  background: #242526;
  border: 1px solid #3a3b3c;
}

.dark-mode-class .box-Security-control {
  background: #150a4c;
  border: 1px solid #150a4c;
}

.m0-graph {
  height: auto !important;
}

.score-mail h4 {
  margin-top: 15px;
  margin-bottom: 0px;
}

.score-mail p {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

.bottom-circle {
  margin-left: -144px;
}

.icons-aligns {
  display: flex;
  justify-content: left;
  margin-top: 5px;
  margin-bottom: 10px;
  gap: 6px;
}

.icons-aligns svg {
  width: 20px;
  height: 20px;
  color: #4a10a4;
  /* margin-right: 9px; */
}

.itm-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.dark-mode-class .svg-color svg {
  color: #fff;
}

.icons-aligns a {
  background-color: #4a10a4;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: #fff !important;
  font-family: Mona-Sans, sans-serif!important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  text-transform: capitalize !important;
  padding: 1px 12px !important;
  text-decoration: none;
  margin-right: 6px;
}

.remove-color {
  background-color: #d32f2f !important;
}

.audits-images {
  width: 100px;
  height: 100px;
}

.audit-cards {
  text-align: center;
  border-right: 1px solid #f5f6f7;
}

.link-circle {
  background-color: #242526;
  width: 23px;
  margin-inline: auto;
  border-radius: 100%;
  height: 23px;
  color: #fff;
  text-align: center;
  margin-top: 8px;
}

.link-circle svg {
  font-size: 24px;
}

.row-grades {
  background-color: #4a10a4;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: block;
  color: #fff;
  text-align: center;
  line-height: 29px;
}

.audit-cards h3 {
  margin-bottom: 15px;
}

.dark-mode-class .link-circle {
  background-color: #121212;
}

.dark-mode-class .audit-cards {
  border-right: 1px solid #3a3b3c;
}

.anchor-click {
  cursor: pointer;
}

.tab-anchor-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  padding-bottom: 0;
}

.tab-anchor-flex h4 {
  margin-bottom: 5px;
}

.tab-anchor-flex p {
  padding-top: 0px;
  margin-top: 0px;
}

.in-dataspace {
  padding: 15px;
}

.in-dataspace h4 {
  margin-bottom: 5px;
}

.in-dataspace p {
  margin-top: 0;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  /* background-color: rgb(0 0 0 / 25%) !important; */
}

.space-cross {
  margin: 5px;
  cursor: pointer;
}

.icons-aligns-new a {
  padding: 0px !important;
}

.icons-aligns-new a:hover {
  background-color: rgb(231, 227, 227);
}

@media (min-width: 900px) {
  .flx-property {
    /* height: 364px; */
  }
}

@media (min-width: 1367px) {
  .flx-property {
    /* height: 464px; */
  }
}

@media (min-width: 1800px) {
}

@media (max-width: 767px) {
  .bottom-circle {
    width: 6rem !important;
    height: 6rem !important;
    margin-left: -95px !important;
  }

  .issues-box h3 {
    font-size: 35px;
  }

  .security-text {
    font-size: 1.4rem !important;
  }

  .box-Assessment {
    padding: 15px;
  }

  .fitbutton button {
    margin: 0px 15px 0px 0px !important;
  }

  .Security-control {
    padding: 15px 15px;
  }

  .circularprogress {
    width: 6rem !important;
    height: 6rem !important;
  }

  .box-Security-control {
    padding: 25px;
  }

  .box-Security-control h2 {
    font-size: 16px;
  }

  .addnew {
    margin: 0px 0px 0px 0px !important;
  }

  .compliance-inline {
    display: block;
    margin-bottom: 15px;
  }

  .manage-mid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* .box-border-0:first-child{
margin-bottom: 25px;
} */

.score-flx {
  display: flex;
  align-items: center;
  height: 87%;
}

.flx-property .css-tchva {
  height: auto;
  margin-bottom: 25px;
}

.box-border-0 .box-Security-control {
  border: none;
  padding: 0;
}

.border-right-1 {
  border-right: 1px solid #d7d8d9;
  border-radius: 0px;
}

.subheader-scrore {
  margin: 0;
}

.fullcover-width {
  width: 100%;
  text-align: center;
}

.no-data-card {
  margin: auto;
  padding: 10px;
  height: 185px;
  display: flex;
  align-items: center;
}

.fullcover-width .MuiTypography-h5 {
  font-size: 16px !important;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .fullcover-width .MuiTypography-h5 {
    margin-bottom: 30px;
  }
}

.flex-domain {
  display: flex;
  align-items: center;
}

.domain-label-left {
  margin-left: 20px;
}

.flx-property .css-sfsq6u {
  height: auto;
  margin-bottom: 25px;
}

.ad-domain-btn {
  width: 252px;
  height: 53px;
}

.no-card-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 81%;
  color: #fff;
}

.circularprogress {
  width: 5.5rem !important;
  height: 5.5rem !important;
}

.bottom-circle {
  width: 5.5rem !important;
  height: 5.5rem !important;
  margin-left: -89px !important;
}

.css-1yz83pi-MuiFormControl-root {
  margin-bottom: 9px !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight.MuiTableCell-sizeMedium.css-1i36psw {
  color: #000 !important;
}

span.MuiBox-root.css-mxncak {
  width: 57.97px;
}

span.MuiBox-root.css-jom3hb {
  width: 57.97px;
}

span.MuiBox-root.css-e95m82 {
  width: 57.97px;
}

.domain-label-left span.MuiBox-root.css-e95m82 {
  width: auto !important;
}

.domain-label-left span.MuiBox-root.css-ma9nno {
  width: auto !important;
}

span.MuiBox-root.css-m4fvc1 {
  width: 57.97px;
}

span.MuiBox-root.css-1dzhudb {
  width: 57.97px;
}

span.MuiBox-root.css-ma9nno {
  width: 57.97px;
}

body .margin-ecosystem.MuiPaper-elevation1 {
  margin-bottom: 20px;
  background: #150a4cb2 !important;
}

.plane-send:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.right-arrow-compliance {
  margin: 0px 39px 0px;
}

.text-align {
  text-align: center;
}

.dark-menu-item-btn {
  text-decoration: none;
  color: #fff;
}

.light-menu-item-btn {
  text-decoration: none;
  color: #000;
}

/* high */
.css-dpple9 {
  color: #4e2606 !important;
  background-color: #ff7100 !important;
}

/* high */
.css-13u3hyn {
  color: #4e2606 !important;
  background-color: #ff7100 !important;
}

/* medium */
.css-sxwn16 {
  color: #583c12 !important;
  background-color: #feb019 !important;
}

/* medium */
.css-1r090vx {
  color: #583c12 !important;
  background-color: #feb019 !important;
}

.css-1l8ks0t {
  color: #ffc4c4 !important;
  background-color: #d32f2f !important;
}

.css-1fvim2j {
  color: #ffc4c4 !important;
  background-color: #d32f2f !important;
}

.css-1lhro45,
.css-1o7kn82 {
  color: #17320b !important;
  background-color: #6aa84f !important;
}

.bar-chart-small {
  position: relative;
}

.bar-chart-small h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  text-align: left;
  margin: 0;
  position: absolute;
  top: 42px;
  z-index: 1;
  left: 6%;
  color: #fff;
}

.bar-chart-small span {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.Pending{
   font-family: Mona-Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px !important;
    text-align: center !important;
    border-radius: 5.9px;
    position: relative;
    width: 105px;
    height: 28px;
    display: block;
    color: rgba(33, 150, 243, 1);
    border-image-source: linear-gradient(90deg, rgba(64, 64, 64, 0.2) 0%, rgba(166, 166, 166, 0.2) 100%);
    background: #2196f333;
}

.ToSent{
  font-family: Mona-Sans, sans-serif;
   font-size: 14px;
   font-weight: 400;
   line-height: 28px !important;
   text-align: center !important;
   border-radius: 5.9px;
   position: relative;
   width: 105px;
   height: 28px;
   display: block;
   /* color: rgba(33, 150, 243, 1); */
   /* border-image-source: linear-gradient(90deg, rgba(64, 64, 64, 0.2) 0%, rgba(166, 166, 166, 0.2) 100%); */
   /* background: #2196f333; */
}
.id-detail .Completed{
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px !important;
  text-align: center !important;
  border-radius: 5.9px;
  position: relative;
  width: 105px;
  height: 28px;
  display: block;
  color: #20FF29;
  background: #22FF2B33;
}

.pending-remediation p {
  font-family: Mona-Sans, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  color: #fff;
  margin-bottom: 25px;
  margin-top: 0;
}
.pending-remediation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 21px;
}
.pending-remediation .number-up {
  font-family: Mona-Sans, sans-serif;
  font-size: 11.66px;
  font-weight: 600;
  line-height: 16.66px;
  text-align: left;
}
.pending-remediation .number-up img {
  width: 9.99px;
  height: 9.99px;
}
.pending-remediation .number-down {
  font-family: Mona-Sans, sans-serif;
  font-size: 11.66px;
  font-weight: 600;
  line-height: 16.66px;
  text-align: left;
  color: #ff0000;
  position: relative;
  top: -32px;
}
.pending-remediation-h2 h2 {
  text-align: center !important;
}

.dark-mode-class .margin-ecosystem th {
  background: #3c32702b;
  border-top: 1px solid #ffffff08;
  border-bottom: 1px solid #ffffff08;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
}

.dark-mode-class .margin-ecosystem td {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo-image-vendor {
  width: 40px;
  height: 40px;
  background: #1c1052;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-vendor-radius {
  width: 45px;
  height: 45px;
  background: #1c1052;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-vendore-name {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  display: block;
  line-height: 35px;
  text-align: center;
  font-family: Mona-Sans;
  font-size: 14px;
  font-weight: 600;
}

.logo-image-vendor img {
  text-align: center;
  vertical-align: middle;
}

.grade-boxes {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 5.13px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  background-image: url("../../assets/grade-bg-img.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}

/* .btn-hight-risk{
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 11px;
color: rgba(241, 181, 3, 1);
padding: 7px 15px;
text-align: center;
background-image: url('../../assets/serverity-img.svg');
background-repeat: no-repeat;
background-size: 92px 100%;
background-position: center center;
width: 92px;
height: 25px;
} */

.btn-hight-risk {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 11px;
  color: rgba(241, 181, 3, 1);
  padding: 7px 15px;
  text-align: center;
  background-image: url("../../assets/serverity-img.svg");
  background-repeat: no-repeat;
  background-size: contain; /* Ensures the background scales proportionally */
  background-position: center center;
  width: 92px; /* Adjusts the width automatically */
  height: 25px; /* Fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cloud-vpn-btn {
  font-family: Mona-Sans, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 9px;
  color: #fff;
  padding: 8px 10px;
  text-align: center;
  background-image: url("../../assets/tags-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 25px;
  width: 92px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  margin: 0px 3px 0 0;
}
.statusClass {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 9px;
  text-align: center;
  padding: 7px 7px;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: 4px 4px 4px -20px #00000040;
  width: 140px;
  height: 25px;
  display: block;
  color: rgba(33, 150, 243, 1);
  border-image-source: linear-gradient(
    90deg,
    rgba(64, 64, 64, 0.2) 0%,
    rgba(166, 166, 166, 0.2) 100%
  );
  background: #2196f333;
}
.Sent {
  border-radius: 4px;
  position: relative;
  z-index: 1;
  box-shadow: 4px 4px 4px -20px #00000040;
  background-color: #22FF2B33;
  color: #20FF29;
  border: none;
  line-height: 12px;
}
.Onboarding {
  background-color: #22FF2B33;
  color: #20FF29;
}

.Completed {
  background: #22FF2B33;
  color: #20FF29;
}
.Submitted {
  color: rgba(255, 193, 7, 1);
  border-image-source: linear-gradient(
    90deg,
    rgba(64, 64, 64, 0.2) 0%,
    rgba(166, 166, 166, 0.2) 100%
  );
  background: rgba(255, 193, 7, 0.3);
}

.Extended_Deadline {
  background: #FF57224D;
  color: #FF4003;
  padding: 7px 1px !important;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  box-shadow: 4px 4px 4px -20px #00000040;
  border: none;
  line-height: 12px;
  /* width: fit-content; */
}
.Delayed {
  color: rgba(255, 193, 7, 1);
  border-image-source: linear-gradient(
    90deg,
    rgba(64, 64, 64, 0.2) 0%,
    rgba(166, 166, 166, 0.2) 100%
  );
  background: rgba(255, 193, 7, 0.2);
}

.Engagement {
  background: #FD8C004D;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  box-shadow: 4px 4px 4px -20px #00000040;
  color: #FC8C00;
  border: none;
  line-height: 12px;
}

.In.Progress{
  background: #2196F333;
  color: #2196F3;

}

.Monitoring {
  background: #1798FF33;
color: #0BBAFF;
}

.Deboarding {
  background: #FF0F0F33;
  color: #FF4C4C;
}

.Due_diligence {
  color: #FF9800;
  background: #F7A32633;
  text-transform: capitalize;
}

.tab-main-heading {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #fff;
  margin-left: 0;
  margin-top: 36px;
  margin-bottom: 25px;
  border-bottom: 1px solid #ffffff14;
}

.tab-main-heading .MuiTabs-flexContainer{
gap: 20px;
}

.tab-main-heading .MuiTabs-fixed {
  margin-left: 30px;
}

.tab-main-heading.margin-mui-tabfix .MuiTabs-fixed{
margin-left: 0!important;
}

.tab-main-heading .MuiTab-textColorPrimary {
  text-transform: capitalize !important;
  font-family: Mona-Sans, sans-serif !important;
  font-size: 18px !important;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #FFFFFF99;
}

.tab-main-heading .MuiTab-textColorPrimary.Mui-selected{
  /* background-color: #271969 !important; */
  color: #fff !important;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #6E0E9B;
}

.menu-container {
  position: relative;
  display: inline-block;
}

.dots-menu {
  cursor: pointer;
  font-size: 16px;
}

.menu-content {
  display: none;
  position: absolute;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 133px;
  border-radius: 4.43px;
  color: #fff;
  background: linear-gradient(180deg, #1b114d 0%, #15075c 100%);
}

.menu-content a {
  color: #fff;
  text-decoration: none;
  width: 100%;
  display: inline-block;
  padding: 10px 20px;
  font-family: Mona-Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.85px;
  text-align: left;
  border-bottom: 1.48px solid #ffffff0f;
}

.menu-content a:hover {
  background: linear-gradient(180deg, #1b114d 0%, #15075c 100%);
}

.menu-container:hover .menu-content {
  display: block;
}

.table-auto-x {
  overflow-x: auto;
  width: 100%;
}

#radialBar-new {
  position: absolute;
  top: -30px;
  width: 100px;
  display: block;
  left: -11px;
}
#radialBar-new-overviewpage {
  position: absolute;
  width: 100px;
  display: block;
  left: -11px;
}
.radialBar {
  position: relative !important;
}
.radialBar-new-overviewpage .number-up {
  font-family: Mona-Sans;
  font-size: 9.3px;
  font-weight: 600;
  line-height: 15.27px;
  text-align: right;
  top: -9px;
  right: -65px;
}
.radialBar-new .number-up {
  font-family: Mona-Sans;
  font-size: 9.3px;
  font-weight: 600;
  line-height: 15.27px;
  text-align: right;
  top: -60px;
  right: -65px;
}
.radialBar-new .number-up-overviewPage {
  font-family: Mona-Sans;
  font-size: 9.3px;
  font-weight: 600;
  line-height: 15.27px;
  text-align: right;
  top: -62px;
  right: -65px;
}
.radialBar-new .number-down {
  color: #ff1919;
}
.radialBar-new-overviewpage .number-down {
  color: #ff1919;
}

.radialBar-new .number-up img {
  width: 7px;
  height: 7px;
}

.black-bg {
  background: #120a3a;
  border: 1px solid #3a3b3c;
}

.black-bg.attackpart::-webkit-scrollbar-corner {
  background-color: transparent; /* Match this with your container background color */
}

.black-bg.attackpart::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.black-bg.attackpart::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.black-bg.attackpart::-webkit-scrollbar-thumb {
  background: #4c4c74; /* Customize the thumb color */
  border-radius: 8px;
}

.MuiDataGrid-columnHeaders {
  background: #3c32702b;
  border-radius: 10px;
  border: none !important;
  outline: none;
  min-height: 70px !important;
}

.css-1gbmlfq-MuiDataGrid-root.MuiDataGrid-autoHeight {
  border: none;
}
.css-1gbmlfq-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: #ffffff1a !important;
}

.css-wop1k0-MuiDataGrid-footerContainer {
  border-top: 0px !important;
}

.searh-text-field {
  max-width: 236px;
  float: right;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}
.searh-text {
  max-width: 236px;
  float: right;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  margin-top: 75px !important;
}
.searh-text-field .MuiInputBase-adornedStart {
  border-radius: 100px;
  height: 45px;
  font-family: Mona-Sans, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  background-color: #271969;
  border-radius: 100px !important;
}
.searh-text-field .css-9425fu-MuiOutlinedInput-notchedOutline {
  border-color: #271969 !important;
}

.searh-text-field .MuiInputAdornment-positionStart svg {
  fill: #bd06c5;
}

.searh-text-field
  .css-9477tm-MuiTablePagination-root
  .MuiTablePagination-selectLabel {
  font-family: Mona-Sans, sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: right;
  margin-top: 100px;
  color:#fff;
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-select{
  color: #fff!important;
}

.css-1w53k9d-MuiDataGrid-overlay {
  background: #140a47 !important;
}

.rowmenu .css-at26bj-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  width: 133px;
  border-radius: 4.43px;
  border: 1.11px solid #fffbfb1a;
  background: linear-gradient(180deg, #1b114d 0%, #15075c 100%);
  position: relative;
  overflow: visible;
}

.rowmenu .css-at26bj-MuiPaper-root-MuiMenu-paper-MuiPopover-paper:before {
  content: "";
  position: absolute;
  top: -20px;
  left: 69%;
  transform: translateX(-70%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #2d206d;
  width: 19px;
  height: 19px;
}
.rowmenu li {
  font-family: Mona-Sans, sans-serif;
  font-size: 14.78px;
  font-weight: 600;
  line-height: 20.85px;
  text-align: left;
  color: #fff!important;
}
.rowmenu li img {
  margin-right: 10.3px;
  width: 26px;
  height: 26px;
  object-fit: contain;
}
.image{
  margin-right: 10.3px;
  width: 20px !important;
  height: 17px !important;
}
.rowmenu .border-bot-line {
  border-bottom: 1.48px solid #ffffff0f;
  padding-bottom: 11px;
  margin-bottom: 6px;
}

.rowmenu .border-bot-line:first-child{
  padding-bottom: 4px;
  border: none;
  margin-bottom: 0;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-corner {
  background-color: transparent; /* Match this with your container background color */
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: #4c4c74; /* Customize the thumb color */
  border-radius: 8px;
}

body .datagrid-table .Mui-selected {
  background-color: #271969 !important;
  border: none !important;
  outline: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border-radius: 0 !important;
}

.datagrid-table .MuiList-padding {
  background: #4c4c74 !important;
  font-size: 14px !important;
}

.css-1gbmlfq-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1gbmlfq-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: 0px !important;
}

.css-1gbmlfq-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-1gbmlfq-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: 0px !important;
}

.bar-ecosystem-chart .apexcharts-legend-series {
  margin-bottom: 14px !important;
}

.failed-controls {
  width: 27px;
  height: 25px;
  border-radius: 3px 0px 0px 0px;
  background: #1d105eb2;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  margin-inline: auto;
}

.icons-td {
  display: flex;
  align-items: center;
  gap: 15px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: #140a47;
  font-family: Mona-Sans, sans-serif!important;
  font-size: 13px !important;
}

.MuiMenu-list{
  background-color: #140a47!important;
  font-family: Mona-Sans, sans-serif!important;
  font-size: 13px !important;
  border: 1.11px solid #FFFBFB1A;
  border-radius: 5px;
  padding-left: 4px!important;
  padding-right: 4px!important;
  color: #fff;
}

.relative.radialBar-new {
  left: -10px;
}

.barchart-vendor .apexcharts-canvas {
  left: 15px;
}

.select-entries {
  width: 66px !important;
  padding: 0;
  left: 15px;
  top: 19px;
  padding-bottom: 15px !important;
  display: flex;
  align-items: center;
}

.select-entries .MuiInputBase-colorPrimary {
  font-family: Mona-Sans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: center;
}

.entries-per {
  padding-left: 20px;
  padding-top: 24px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #fff;
}

.common-padding-manage {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.css-0 {
  position: relative;
}

.MuiTablePagination-selectLabel {
  font-size: 12px !important;
  font-family: Mona-Sans, sans-serif !important;
  font-weight: 600 !important;
  color: #fff;
}
.css-1xif2b4-MuiPopper-root-MuiDataGrid-menu .MuiDataGrid-menuList {
  background-color: #140a47;
}

.css-1tbgwje-MuiDataGrid-root {
  border-color: transparent !important;
}

/* button:focus {
  border: none !important;
  outline: 0px !important;
} */

.css-1tbgwje-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1tbgwje-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: 0px !important;
}

.MuiDataGrid-cellContent {
  padding-left: 3px;
}


.MuiDataGrid-cell.MuiDataGrid-withBorderColor{
  border-bottom: 1px solid #FFFFFF1A!important;
}


.active-color-remove .MuiTabs-indicator {
  background-color: #3232c9 !important;
}

.topsearch-top-0 {
  margin-top: 0 !important;
}

.industry-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-vendore-base {
  background: #120a3a;
}

.heading-add-vendor {
  background: linear-gradient(
    180deg,
    rgba(153, 19, 171, 0.3) 0%,
    rgba(23, 10, 93, 0.3) 100%
  );
  padding: 30px 25px;
  position: relative;
  z-index: 1;
  border: none;
  border-radius: 10px 10px 0px 0px;
}
.heading-add-vendor:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px 10px 0px 0px;
  padding: 1.5px;
  background: linear-gradient(
    180deg,
    rgba(85, 6, 242, 0.3) 0%,
    rgba(163, 15, 243, 0.3) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  z-index: -1;
}

.heading-add-vendor p {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin: 0;
}
.all-tabsinformation {
  border: 1px solid #3d099b;
}

.information-tab-heading {
  background-color: #22146b;
  padding: 15px 25px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.information-tab-heading p {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  gap: 15px;
}
.vendor-logoinformation {
  display: flex;
  gap: 25px;
  width: 90%;
  padding: 30px;
}

.input-information {
  background-color: #1c105d;
  width: 100%;
  padding-bottom: 25px;
}

.logo-vender-new {
  width: 124px;
}
.add-newvendor-logo-here {
  background-color: #120a3a;
  width: 125px;
  height: 125px;
  border-radius: 10px;
  box-shadow: 4px 4px 4px 0px #00000040;
}

.add-newvendor-logo-here img{
  width: 125px;
  height: 125px;
}

.flex-inputcontrol-full {
  width: 100%;
  display: flex;
  gap: 25px;
  /* align-items: center; */
  margin-bottom: 25px;
}

.right-input-fields {
  width: 100%;
}

.contact-information-vender-new {
  padding: 30px;
  position: relative;
}

.primary-text-contact {
  font-family: Mona-San, sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  border-top: 1px solid #ffffff1a;
  border-bottom: 1px solid #ffffff1a;
  padding: 20px 0px;
  margin: 0;
}

.add-contact-button {
  margin: 5px 0px 0px auto !important;
  display: block !important;
}

.three-steps-tabs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* gap: 140px; */
  margin-top: 55px;
  margin-bottom: 60px;
}

.three-steps-tabs .steps-image {
  width: 57px;
  height: 57px;
  background: linear-gradient(180deg, #989898 0%, #ac9f9f 100%);
  border-radius: 100px;
  text-align: center;
  line-height: 81px;
  margin-inline: auto;
  position: relative;
  z-index: 99;
}

.three-steps-tabs .select-steps p {
  font-family: Mona-Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
}

.add-newvendor-steps{
  gap: 101px;
  justify-content: center;
}

.activeprofile .steps-image {
  background: linear-gradient(180deg, #350d59 0%, #721cbf 100%);
  position: relative;
  z-index: 1;
  border: none;
}

.activeprofile .steps-image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100px;
  padding: 1.5px;
  background: linear-gradient(180deg, #4c2ef4 0%, #ff36e8 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  z-index: -1;
}

.select-steps.behind-line {
  position: relative;
}

.select-steps.behind-line:before {
  content: "";
  position: absolute;
  width: 153%;
  height: 5px;
  top: 29px;
  left: 89px;
  background: #ffffffb2;
}

.select-steps.behind-line.activeprofile:before {
  background: linear-gradient(90deg, #9017ff 0%, #8c13fc 100%);
}

.add-vender-input-design .MuiInputBase-formControl {
  height: 60px;
}

.add-vender-input-design label {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 27.54px;
  text-align: left;
}

.add-vender-input-design .MuiInputBase-colorPrimary.Mui-focused {
  border: 1px solid #541aae !important;
}

.add-vender-select-value .MuiInputBase-formControl {
  height: 60px;
}

.add-vender-select-value.Mui-focused {
  border: 1px solid #541aae !important;
}

.relationship-text-tag {
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.24px;
  text-align: left;
  color: #ffff;
}

.data-sensitivity {
  background: #22146b;
  padding: 25px;
  width: 100%;
  margin-top: 40px;
}

.data-sensitivity h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 21.27px;
  text-align: left;
  margin: 0;
  color: #fff;
  border-bottom: 1px solid #ffffff1c;
  padding-bottom: 20px;
}
.data-sensitivity p {
  font-family: Mona-Sans, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #fff;
  margin-bottom: 35px;
}

.check-boxes-multiples {
  background: linear-gradient(
    90deg,
    rgba(42, 25, 129, 0.38) 0%,
    rgba(62, 57, 157, 0.38) 100%
  );
  padding: 20px;
}

.check-boxes-multiples .MuiGrid-root{
  display: flex;
  align-items: center;
}

.check-boxes-multiples .d-flex-checks {
  padding-left: 0px;
  width: auto;
}

.check-boxes-multiples .assessment-datacheck {
  text-align: left;
  font-size: 14px;
  line-height: 18px;
}
.tab-flex-realtionship{
  display: flex;
  gap: 25px;
  align-items: center;
}
.check-boxes-multiples .checkmark {
  border-radius: 1px;
  border: 1px solid #fff;
  height: 19px;
  width: 19px;
}
.check-boxes-multiples .custom-checkbox .checkmark:after {
  top: 2px;
  width: 7px;
}

.radio-type-design [type="radio"]:checked,
.radio-type-design [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.radio-type-design :checked + label,
.radio-type-design :not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.41px;
  text-align: left;
  color: #fff;
}

.radio-type-design [type="radio"]:checked + label:before,
.radio-type-design [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 21px;
  height: 21px;
  border: 1px solid #fff;
  border-radius: 100%;
}

.radio-type-design [type="radio"]:checked + label:after,
.radio-type-design [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 13px;
  height: 13px;
  background: linear-gradient(180deg, #ed2cff 0%, #b164f0 46.7%, #4422ff 100%);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radio-type-design [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio-type-design [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.radio-type-design {
  background: linear-gradient(90deg, #241766 0%, rgba(105, 25, 176, 0.3) 100%);
  /* border: 3.75px solid #A836FF33; */
  box-shadow: 4px 4px 20px 0px #00000040;
  padding: 14px 18px;
  border-radius: 70px;
}

.upload-document-button {
  width: 100%;
}
.flex-uploadbtn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.radio-justify-center {
  justify-content: center;
}

.select-document-box-type {
  background: #22146b;
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  margin-top: 40px;
  padding: 25px;
  max-width: 500px;
  margin-inline: auto;
  text-align: center;
}

.select-document-box-type img {
  display: block;
  margin: 0px 0px 0px auto;
}

.max-widthform-selects {
  width: 280px !important;
  margin: 0px auto;
}

.max-widthform-selects.mb-20 {
  margin-bottom: 30px;
}

.add-another-btn-doc {
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%);
  font-family: Mona-Sans, sans-serif;
  font-size: 15px!important;
  font-weight: 400;
  line-height: 15.47px;
  text-align: center;
  color: #fff;
  padding: 5px 22px !important;
  height: 40px;
  margin: 0px 0px 0px auto !important;
  display: block !important;
}

.next-will-space{
  margin: 0px 25px 0px auto !important;
  width: 120px;
}

.MuiPopover-paper::-webkit-scrollbar-corner {
  background-color: transparent; /* Match this with your container background color */
}

.MuiPopover-paper::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent!important;
}

.MuiPopover-paper::-webkit-scrollbar-track {
  background: transparent!important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.MuiPopover-paper::-webkit-scrollbar-thumb {
  background: #4c4c74; /* Customize the thumb color */
  border-radius: 8px;
}

.add-another-btn-doc.save-in {
  width: 135px;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.line-top {
  border-top: 1px solid #ffffff33;
  width: 100%;
  padding-top: 30px;
}

.save-bt {
  margin-bottom: 30px !important;
  width: 154px;
  margin-top: 30px !important;
}

.margin-domain-spaces {
  margin-top: 45px;
}

.delete-bin-icon {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 36px;
}

.alert-absolute-left {
  position: absolute;
  left: 6%;
}

/* .datagrid-table .MuiDataGrid-columnHeaders{
  cursor: pointer;
} */

.click-hover-datagrid .MuiDataGrid-cell{
  cursor: pointer;
}
.click-hover-datagrid .MuiDataGrid-cell button{
  color: #fff!important;
}

.MuiDataGrid-cell:focus{
  outline: none!important;
}
.MuiDataGrid-cell:focus-within{
  outline: none!important;
}

.risk-profile-content h3{
  font-family: Mona-Sans, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  
}
.risk-profile-content p{
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  margin-bottom: 30px;

}

.risk-profile-content h4{
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  margin-top: 30px;
}

.bg-cover-colors{
  background-color: #120A3A!important;
}

.openai-response::-webkit-scrollbar-corner {
  background-color: transparent; /* Match this with your container background color */
}

.openai-response::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent!important;
}

.openai-response::-webkit-scrollbar-track {
  background: transparent!important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.openai-response::-webkit-scrollbar-thumb {
  background: #4c4c74; /* Customize the thumb color */
  border-radius: 8px;
}

@media (min-width: 1600px) {
  .bar-chart-small h3 {
    left: 28%;
    transform: translate(-50%, 9%);
  }
  .barchart-vendor .apexcharts-canvas {
    left: 0px;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill {
  background-color: #1E0C49 !important; /* Use your background color */
  -webkit-box-shadow: 0 0 0px 1000px #1E0C49 inset !important; /* Ensure the background color matches */
  -webkit-box-shadow: #1E0C49 !important; /* Text color */
  transition: background-color 5000s ease-in-out 0s;
}

.Fixed{
  font-family: Mona-Sans, sans-serif;
   font-size: 14px;
   font-weight: 400;
   line-height: 28px !important;
   text-align: center !important;
   border-radius: 5.9px;
   position: relative;
   width: 105px;
   height: 28px;
   display: block;
   color: #20FF29;
   border-image-source: linear-gradient(90deg, rgba(64, 64, 64, 0.2) 0%, rgba(166, 166, 166, 0.2) 100%);
   background: #22FF2B33;
}

.deadline-text{
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  position: relative;
}

.red-deadline-circle::after{
  content: '';
  background: #FF1919;
  width: 11px;
  height: 11px;
  border-radius: 50px;
  display: inline-block;
  margin-left: 6px;
}

.green-deadline-circle{
  position: relative;
  margin-left: 35px;
}

.green-deadline-circle::after{
  background: #0BFF68;
  right: -11px;
  position: absolute;
  top: 1px;
}

.selected-deadline {
   appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../../assets/vector-arrow.svg') no-repeat right center;
  background-size: 1em; 
  padding-right: 0em; 
}

.selected-deadline .MuiSelect-icon{
  color: transparent!important;
}

.selected-deadline .MuiSelect-select{
  padding: 5px;
  font-family: Mona-Sans, sans-serif;
}

.selected-deadline .MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: transparent!important;
}

.selected-deadline fieldset{
  border-color: rgb(255 255 255 / 0%) !important;
}

.deadline-text img{
  margin-right: 6px;
}

.green-deadline-circle .MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-width: 0px!important;
}

.flexi-deadline-date{
  display: flex;
  align-items: center;
  margin-right: 0px;
}

.something-fade{
  color:#FFFFFF66;
  position: relative;
  left: -17px;
}

.drop-down-datelist{
  background-color: #271969;
  border-radius: 5px;
  width: 120px;
  text-align: center;
  margin: 8px 0px 0px auto;
  position: absolute;
  right: 0;
}

.drop-down-datelist p{
  font-size: 14px !important;
  font-family: Mona-Sans, sans-serif;
  color: #fff;
  margin-bottom: 3px !important;
  font-weight: 500 !important;
  cursor: pointer;
  padding: 6px 10px !important;
  margin-top: 4px !important;
}

.drop-down-datelist p:hover{
  background-color: #15075c;
}



.deadline-click-show{
position: relative;
cursor: pointer;
}

.deadline-click-show img{
  margin-left: 6px;
}