body{
    font-family: 'Mona-Sans', sans-serif !important;
    font-size: 14px;
    color: #fff!important;
}

.rel-div .relation{
    color: #f4f5f6 !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size:15px !important;
}
.btn-right {
    text-align: right;
    float: right;
}
.rel-div .MuiTypography-root {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 15px !important;
    line-height: 18px;
    padding-top: 0px !important;
}
.relative-doc{
    position: relative;
}

.delete-icon{
    position: absolute;
    right: 12px;
    cursor:pointer;
    top: 40px;
}
.delete-icon-document{
    position: absolute;
    right: 12px;
    cursor:pointer;
}
.add-new-attack-heading.headingpop-cross {
    padding-left: 26px;
}
.add-vendor .MuiFormControlLabel-label{
    font-size: 12px !important;
    font-family: Mona-Sans, sans-serif!important;
    font-weight: 400;
    line-height: 14px;
}
.input-container .tag-name{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid gray;
    background-color: gray;
    border-radius: 3px;
    font-size: 12px;
    margin: 2px;
}

.model-field{
    text-align: left;
    margin-top: 10px !important;
    padding: 35px 95px 35px 95px !important;
    color: #fff;
}

.model-field input {
    border: 1px solid #4A10A4;
    border-radius: 10px;
    font-family: Mona-Sans, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 13.54px;
    text-align: left;
    color: #fff;
    height: 25px;
}

.max-widthform-selects .label {
    color: black !important;
}