.heading-main{
    font-family: Mona-Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 25px!important;
}

.change-password{
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 50px 45px;
    margin-top: 60px;
}

.change-password .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    margin-bottom: 10px;
}

.update-button{
    background: #4A10A4!important;
    border-radius: 10px!important;
    font-family: 'Poppins'!important;
    font-style: normal;
    font-weight: 400!important;
    font-size: 15px!important;
    line-height: 20px;
    color: #fff;
    text-transform: capitalize!important;
    padding: 15px;
    width: 150px;
    height: 40px;
    /* margin: 20px 0px 0px auto!important;
    text-align: center;
    display: flex!important; */
}

.change-forms{
    max-width: 45%;
    margin: 0px auto;
    text-align: center;
}

.icon-s{
    width: 65px;
    height: 65px;
    margin: 0px auto;
}

.css-asodjs-MuiContainer-root{
    max-width: 100%!important;
}

 .change-password{
    background: #150D43;
    border: 1px solid #404040;
}

.update-button{
   color: #fff!important;
}


@media (max-width: 767px){
.change-forms {
    max-width: 100%;
}
.change-password{
    padding: 30px;
    margin-top: 20px;
}
}