.top-assets {
  margin-top: 60px;
}

.cross-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.dark-attack-modal .black-bg {
  background: #120a3a;
  border: 1px solid #120a3a !important;
  border-radius: 20px;
  padding: 0;
  width: 656px;
  height: 450px;
}

.profileinfo-popup {
  padding: 15px 15px 15px 25px;
}

.profileinfo-popup h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.37px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
}

.add-vendor-by-client {
  width: 900px !important;
}

.radiodocument label {
  margin: 0px !important;
  padding: 0;
}

.add-vendor-by-client::-webkit-scrollbar-corner {
  background-color: transparent; /* Match this with your container background color */
}

.headingpop-cross img {
  cursor: pointer;
}

.contact-detail-text {
  margin-bottom: 10px !important;
}

.add-vendor-by-client .css-rb5gc9-MuiFormLabel-root-MuiInputLabel-root {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  left: 5px !important;
  top: 6px !important;
  color: #fff !important;
}

.add-vendor-by-client::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.add-vendor-by-client::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.add-vendor-by-client::-webkit-scrollbar-thumb {
  background: #4c4c74; /* Customize the thumb color */
  border-radius: 8px;
}

.min-add-another-document {
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%);
  padding: 15px 25px !important;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 13.54px;
  text-align: left;
  border-radius: 10px;
  min-width: 162px;
  height: 43px;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.dark-attack-modal .attackpart {
  background: #120a3a;
  padding-left: 0;
  padding-right: 0;
}

.bgaddfiles {
  background: #140a47;
}

body .bgaddfiles .MuiPaper-elevation1{
  border: 0px solid #3a3b3c !important;
}

.attack-tittle {
  font-family: Mona-Sans, sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 25px;
  color: #242526;
  padding-top: 0px !important;
  text-align: left !important;
  margin-left: 15px;
}

.Total-SubDomains span {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
}

.Total-SubDomains {
  padding: 10px 25px;
  border-radius: 10px;
  margin-bottom: 25px;
  margin-right: 25px;
}

.Total-SubDomains p {
  margin: 0px;
}

.attack-box {
  text-align: center;
}

.SubDomains .box-Security-control {
  border: none;
  background-color: transparent !important;
}

.alin-mid {
  display: flex;
  align-items: center;
}

.issue-severity {
  background: #ffffff;
  border: 1px solid #e4e6ea;
  border-radius: 15px;
  width: 100%;
  padding-bottom: 15px;
}

.linearprocess {
  margin: 30px 20px;
}

body .attack-surface-issue-total.MuiPaper-elevation1 {
  border: 0px !important;
}

.m0 {
  margin: 0px;
}

.issues-box {
  background: #ffffff;
  border: 1px solid #e4e6ea;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.issues-box h3 {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  margin-bottom: 0px;
  margin-top: 0;
  width: 100%;
}

.issues-box p {
  margin: 0px;
}

.alin-mids {
  display: flex;
  align-items: center;
  height: 72%;
}

.Security-Monitor {
  width: 100%;
  margin: 0;
}

.export-btn {
  width: 110px;
}

.fit-tomid {
  display: flex !important;
  align-items: center;
  padding-bottom: 20px;
}

.AttackScore .MuiOutlinedInput-input {
  font-size: 14px !important;
  font-family: Mona-Sans, sans-serif !important;
  background-color: #2a1d6f !important;
  padding: 3px 11px;
}

.dark-mode-class .MuiOutlinedInput-input {
  color: #fff !important;
}

.light-mode-class .MuiOutlinedInput-input {
  color: #fff !important;
}

.SubDomains {
  width: 100%;
  text-align: center;
}

.SubDomains .top-heading-dashboard h2 {
  width: 100%;
  text-align: center;
}

.dark-mode-class .issue-severity {
  background: #150d43;
  border: 1px solid #404040;
}

.dark-mode-class .attack-tittle {
  color: #fff;
}

.dark-mode-class .issues-box {
  background: #150d43;
  border: 1px solid #404040;
}

.text-lefts {
  text-align: left;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
}

.issues-box h4 {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 0px;
}

.flex {
  display: flex;
}

.subdomain {
  padding: 0px;
}

.domain-head {
  display: flex;
  align-items: center;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  background: #1d0e65;
  gap: 30px;
  padding: 0px 20px;
}

.domain-head h4 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 17.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px;
}

.domain-head p {
  text-align: center;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #242526;
}

.labm0 {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  text-align: left;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  width: 100%;
  padding: 15px;
}

.critical .MuiBox-root {
  margin-right: 15px;
  padding: 11px;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  height: 32px;
  display: inline-table;
}

.flex-aligns svg {
  vertical-align: sub;
}

.flex-aligns {
  display: flex;
  align-items: center;
  gap: 25px;
}

.link-l {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 17.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.affecteds-part {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
}

.affecteds-part h3 {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #fff;
}

.table-attacks th:nth-child(3) {
  width: 100px;
}

.table-attacks td a {
  padding: 7px 12px !important;
  border-radius: 10px !important;
  font-family: "Poppins" !important;
  font-size: 13px;
}

.VolumeBar {
  height: 7px !important;
  border-radius: 5px !important;
  margin-bottom: 20px !important;
}

.affecteds-part h3 {
  color: #fff;
}

.code {
  color: #fff;
}

/* desert scheme ported from vim to google prettify */
pre.prettyprint {
  display: block;
  background: #26186699;
  text-align: left;
  padding: 14px 28px;
}

pre .nocode {
  background-color: none;
  color: #fff;
}

pre .str {
  color: #ffa0a0;
}

/* string - pink */
pre .kwd {
  color: #f0e68c;
  font-weight: bold;
}

pre .com {
  color: #87ceeb;
}

/* comment - skyblue */
pre .typ {
  color: #98fb98;
}

/* type - lightgreen */
pre .lit {
  color: #cd5c5c;
}

/* literal - darkred */
pre .pun {
  color: #fff;
}

/* punctuation */
pre .pln {
  color: #fff;
}

/* plaintext */
pre .tag {
  color: #f0e68c;
  font-weight: bold;
}

/* html/xml tag - lightyellow */
pre .atn {
  color: #bdb76b;
  font-weight: bold;
}

/* attribute name - khaki */
pre .atv {
  color: #ffa0a0;
}

/* attribute value - pink */
pre .dec {
  color: #98fb98;
}

/* decimal - lightgreen */
/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
  color: #aeaeae;
}

/* IE indents via margin-left */
li.L0,
li.L1,
li.L2,
li.L3,
li.L5,
li.L6,
li.L7,
li.L8 {
  list-style-type: none;
}

/* Alternate shading for lines */
@media print {
  pre.prettyprint {
    background-color: none;
  }

  pre .str,
  code .str {
    color: #060;
  }

  pre .kwd,
  code .kwd {
    color: #006;
    font-weight: bold;
  }

  pre .com,
  code .com {
    color: #600;
    font-style: italic;
  }

  pre .typ,
  code .typ {
    color: #404;
    font-weight: bold;
  }

  pre .lit,
  code .lit {
    color: #044;
  }

  pre .pun,
  code .pun {
    color: #440;
  }

  pre .pln,
  code .pln {
    color: #000;
  }

  pre .tag,
  code .tag {
    color: #006;
    font-weight: bold;
  }

  pre .atn,
  code .atn {
    color: #404;
  }

  pre .atv,
  code .atv {
    color: #060;
  }
}

code,
.code,
pre {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}

code .o,
.code .o,
pre .o {
  color: orange;
}

code .w,
.code .w,
pre .w {
  color: white;
}

.alert-Critical {
  padding: 3px;
  font-family: Mona-Sans, sans-serif;
  font-size: 11.63px;
  font-weight: 400;
  line-height: 19.25px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 84px;
  height: 24px;
  border-radius: 4.5px;
  display: block;
}

.high-Critical {
  background-color: rgba(221, 235, 170, 0.16);
  color: orange;
  margin-right: 15px;
  padding: 11px;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border-radius: 10px;
  width: 110px;
  text-align: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.medium-Critical {
  background-color: rgba(134, 158, 224, 0.16);
  color: rgb(68, 68, 231);
  margin-right: 15px;
  padding: 11px;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border-radius: 10px;
  width: 110px;
  text-align: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.low-Critical {
  background-color: rgba(153, 235, 150, 0.16);
  color: green;
  margin-right: 15px;
  padding: 11px;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border-radius: 10px;
  width: 110px;
  text-align: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.sub-saoce-between p {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  font-family: Mona-Sans, sans-serif;
  font-size: 31.61px;
  font-weight: 600;
  line-height: 15.81px;
  letter-spacing: 0.03em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}

.box-remove-background .box-Security-control {
  background: transparent !important;
  border: 0px !important;
  align-items: center;
}
.total-issues-chart.box-remove-background {
  height: 70%;
  align-items: center;
}

.fullcoverheight.top-heading-dashboard {
  height: 100%;
}

p.dark-change-bg {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.success-critical {
  background-color: #2e7d32 !important;
  color: #fff !important;
}

.medium-color {
  background-color: #ffd700 !important;
  color: #000000 !important;
}

.high-color {
  background-color: #ffa500 !important;
  color: #333333 !important;
}
.critical-color {
  background-color: #ff0000 !important;
  color: #fff !important;
}
.pro-text-size {
  font-size: 3rem !important;
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  color: #242526 !important;
}

.pro-text-size {
  width: 30px;
  height: 30px;
  color: #fff !important;
  background-color: #008000;
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 32px !important;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-radius: 4px;
}

.mt-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mid-align-g {
  display: flex;
}

.mid-align-g .MuiBox-root {
  display: flex;
  align-items: center;
  height: 100%;
}

body .attack-surface-data.MuiPaper-elevation1 {
  background: #150a4cb2;
  border-radius: 10px;
  border: none !important;
}

.cover-table th {
  border-right: 0px !important;
}

body .cover-table .MuiPaper-elevation1 {
  border: none !important;
}

.cover-table .css-1ijrop1-MuiTableCell-root {
  border-bottom: 1px solid #ffffff1a !important;
}

.add-new-attack {
  background: #271969;
  border-radius: 10px;
  font-family: Mona-Sans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: #fff;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  gap: 14px;
  margin-right: 30px;
  margin-top: 0px;
  height: 39px;
  cursor: pointer;
  min-width: fit-content;
}

.equal-width {
  cursor: pointer;
  border-bottom: 1px solid #ffffff1a !important;
}
.row-menu {
  width: 0% !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.add-new-attack-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff33;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
}

.add-new-attack-heading p {
  margin: 0;
  font-family: Mona-Sans, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 21.27px;
  text-align: left;
  color: #fff;
}

.attack-field {
  max-width: 418px;
  text-align: left;
  margin-top: 57px !important;
}

.attack-field input {
  border: 1px solid #4a10a4;
  border-radius: 10px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 13.54px;
  text-align: left;
  color: #fff;
  height: 25px;
}

.attack-field .css-px39cz-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px;
}

.attack-field label {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.54px;
  text-align: left;
  color: #fff;
}

body .submit-domain-btn {
  background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%);
  border: 1px solid #000000;
  font-family: Mona-Sans, sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 17.41px;
  text-align: center;
  width: 100%;
  height: 60px;
  margin-top: 26px !important;
}

.table-row:first-child .MuiTableCell-root:first-child {
  border-top-left-radius: 10px;
}

.table-row:first-child .MuiTableCell-root:last-child {
  border-top-right-radius: 10px;
}

.table-row:last-child .MuiTableCell-root:first-child {
  border-bottom-left-radius: 10px;
}

.table-row:last-child .MuiTableCell-root:last-child {
  border-bottom-right-radius: 10px;
}

.search-add-new {
  display: flex;
  align-items: center;
  position: relative;
  top: 12px;
  right: 25px;
}

.searh-text-field.attack-surface-search {
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  width: 236px;
  margin-top: 0px !important;
}

.attack-surface-list-logo {
  border-radius: 50%;
  height: 38px;
  width: 38px;
}

.left-padding-0 .ml25 {
  margin-left: 0px !important;
}

.circularprogress-attack-surface {
  width: 8.5rem !important;
  height: 8.5rem !important;
}

.bottom-circle-new {
  width: 8.5rem !important;
  height: 8.5rem !important;
  margin-left: -136px !important;
}

.attack-surface-detail-issue {
  background: #150d43;
  border: 1px solid #404040;
  border-radius: 10px;
}

.attack-surface-detail-issue h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-bottom: 1px solid #ffffff1a;
  padding-bottom: 15px;
}

.attack-surface-detail-issue p {
  font-family: Mona-Sans, sans-serif;
  font-size: 31.61px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 40px 0;
}

.linearprocess-peragraph {
  margin: 0px;
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.linearprocess-peragraph span {
  font-family: Mona-Sans, sans-serif;
  font-size: 13.14px;
  font-weight: 400;
  line-height: 12.71px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.circular-progress-security {
  background: #150d43 !important;
  border: 1.12px solid #404040 !important;
  width: 100%;
  border-radius: 22.38px;
  text-align: center;
}

.circular-progress-security h2 {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-bottom: 1px solid #ffffff1a;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 6px;
  margin-bottom: 0px;
}

.padding-circular-bar {
  padding: 15px;
}

.circularprogressbardivided {
  width: 7rem !important;
  height: 7rem !important;
}

.second-circular-barchart {
  width: 7rem !important;
  height: 7rem !important;
  margin-left: -112px !important;
}

body .circularsecurity-text {
  font-family: Mona-Sans, sans-serif !important;
  font-size: 19px !important;
  font-weight: 500 !important;
  line-height: 40px !important;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}

.subdomain-heading {
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 30px;
  margin-bottom: 30px;
}

.affected-full-part {
  border: 1px solid #ffffff33;
  background: #150a4c;
  padding: 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.affected-full-part h3 {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
}

.affected-full-part .affecteds-part {
  display: flex;
  align-items: center;
  gap: 6px;
}

.affected-full-part .affecteds-part .subdomain-assets-text {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.cve-dynamic-number {
  background: #5b40dd17;
}

.cve-dynamic-number h4 {
  margin: 0;
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 21.27px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 20px 29px;
  background: #211461;
}

.description-subdomain {
  margin-top: 20px !important;
}

.description-subdomain h4 {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
  background: #26186699;
  padding: 15px 29px;
}

.description-subdomain p {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 15px 29px;
  margin: 0;
}

.bg-removedynamic {
  background-color: transparent !important;
}

body .export-ex .MuiPaper-elevation1 {
  background-color: #ffffff !important;
  border: 1.12px solid #00000029 !important;
}

.export-ex .card-headerpart {
  border-bottom: 1px solid #00000029 !important;
}

.export-ex .card-headerpart span {
  color: #000 !important;
}

.export-ex .box-Security-control {
  background-color: #ffffff !important;
  border: 0px solid #00000029 !important;
}

.export-ex .circular-progress-security {
  background-color: #ffffff !important;
  border: 1.12px solid #00000029 !important;
}

.export-ex .sub-saoce-between p {
  color: #000;
}

.affecteds-part-pdf {
  background: #f1f1f1;
  display: flex;
  align-items: center;
  gap: 33px;
  padding: 21px 15px;
}

.affecteds-part-pdf h3 {
  font-family: Mona-Sans;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
}

.affecteds-part-pdf .assets-flex {
  display: flex;
  align-items: center;
  gap: 8px;
}

.affecteds-part-pdf .assets-flex span {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
}

.export-ex .circular-progress-security h2 {
  color: #000 !important;
  border-bottom: 1px solid #00000029 !important;
}

.export-ex .top-heading-dashboard h2 {
  color: #000;
  border-bottom: 1px solid #00000029 !important;
}

body .export-ex .circularsecurity-text {
  color: #000 !important;
  font-weight: 600 !important;
}

.export-ex .attack-surface-detail-issue {
  background-color: #ffffff;
  border: 1.12px solid #00000029 !important;
}

.export-ex .attack-surface-detail-issue h3 {
  color: #000;
  border-bottom: 1px solid #00000029 !important;
}

.export-ex .attack-surface-detail-issue p {
  color: #000;
}

.right-line-middle {
  position: relative;
}

.right-line-middle:before {
  content: "";
  position: absolute;
  display: block;
  background-color: #ffffff4d;
  right: -15px;
  top: 1px;
  width: 1.5px;
  height: 17px;
}

.d-flexprint-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 25px;
}

.print-text.heading-main {
  color: #000 !important;
  font-weight: 600;
}

.line-hr {
  border-top: 1px solid #e4e6ea;
  width: 16% !important;
  margin-top: 13px !important;
  margin-inline: auto;
}

.print-text span {
  font-weight: 600 !important;
}

.issueskey {
  border: 1px solid #f1efefb2;
  /* box-shadow: 0px 4px 4px 0px #0000000d; */
  border-radius: 10px;
  margin-top: 25px !important;
  width: 100%;
  background-color: #fff !important;
}

.discription-detailexport h4 {
  border: 1px solid #ffffffb2;
  color: #000000;
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 17.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: #efefef;
  padding: 14px 20px;
  width: 100%;
}

.discription-detailexport .issues-peraa {
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  padding: 0px 20px;
  width: 100%;
  background-color: #fff;
}

.discription-detailexport p {
  margin: 0;
}
.cve-number-first {
  font-family: Mona-Sans, sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 27.08px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 0px 20px;
  color: #000;
  background-color: #fff;
}

.code-left-mid-manage {
  padding: 0px 20px 10px;
}

.code-left-mid-manage code {
  color: #000;
  font-family: Mona-Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
