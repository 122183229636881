.heading-main{
    font-family: Mona-Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    color: #fff;
    margin-top: 0px;
    text-align: center;
}

.profile-form .MuiPaper-rounded{
    min-width: 100%;
    padding: 40px 35px !important;
    margin-top: 40px !important;
}


.profile-form .css-fvtbp6-MuiPaper-root-MuiCard-root .heading-main{
    color: #fff!important;
}

.profile-form .css-fvtbp6-MuiPaper-root-MuiCard-root .gender{
    color: #fff!important;
}

.profile-form .css-fvtbp6-MuiPaper-root-MuiCard-root .MuiIconButton-colorSecondary{
    color: #fff!important;
}
.css-1nu30bq-MuiStack-root{
    min-width: 100%;
}

.upload-new{
    width: 135px;
    font-weight: 400!important;
    height: 40px;
    text-transform: capitalize!important;
    font-size: 15px!important;
    font-family: Mona-Sans, sans-serif!important;
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%)!important;
    color: #fff!important;
    border-radius: 10px!important;
    display: flex!important;
    margin: 18px auto 0px!important;
    line-height: 25px!important;
}


.flex-inline {
    display: flex;
}

.MuiFormGroup-root{
    display: block!important;
}
.MuiFormGroup-root .MuiTypography-body1{
    font-size: 15px!important;
}

.MuiRadio-colorSecondary.Mui-checked{
    color: #4A10A4!important;
}

.MuiOutlinedInput-input{
color: #B1B3B7!important;
}

input::placeholder{
    color: #B1B3B7!important; 
}
input:-ms-input-placeholder{
    color: #B1B3B7!important;
}

.center .upload-new{
    margin: 0px auto;
}

.center-upload img{
    height: 50%;
    width: 100%;
    object-fit: fill;
    border-radius: 15px;
}

.gender{
    font-size: 16px !important;
    font-family: Mona-Sans, sans-serif !important;
    font-style: normal;
    font-weight: 400;
    color: #fff !important;
}

.Mui-disabled {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.38)!important;
  }

.gender-value {
    margin-top: 4px!important;
}

.css-1ludl2-MuiStack-root {
    padding: 8px 0!important;
}

.profile-form .profile-icon {
    margin: 0px auto 10px;
    display: block;
}

.rightside{
    margin: 20px 0px 0px auto!important;
    text-align: center;
    display: flex!important;
}

.upload-new-plus{
    background: #fff!important;
    color: #242526!important;
    border: 1px dashed #8b8b8b!important;
    height: 40px;
    padding: 4px 3px ;
    margin: 10px auto!important;
    display: flex!important;
    width: fit-content;
    font-size: 14px!important;
    font-weight: 400!important;
}

.upload-new-plus img{
width: 15px;
height: 15px;
margin-right: 5px;
}

.upload-new-plus{
    background: #150D43!important;
    color: #fff!important;
}

.css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected:hover {
    background-color: rgb(74 16 164)!important;
}

.css-swe8o6-MuiButtonBase-root-MuiToggleButton-root,
.css-d9c359-MuiButtonBase-root-MuiToggleButton-root{
    text-transform: capitalize!important;
    font-size: 15px!important;
    font-family: Mona-Sans, sans-serif!important;
    font-style: normal;
    font-weight: 400;
}

.dark-mode-class .Mui-selected{
    background-color: #271969 !important;
    color: #fff !important;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #2E29A5;
    text-transform: capitalize;
    font-family: Mona-Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: center;
}

.light-mode-class .gender-area .Mui-selected{
    background-color: #271969!important;
    color: #fff!important;
}

.dark-mode-class ::-webkit-calendar-picker-indicator {
    filter: brightness(0) saturate(100%) invert(100%) sepia(23%) saturate(1192%) hue-rotate(160deg) brightness(115%) contrast(109%);
}

.gender-area{
display: flex;
align-items: center;
}

.gender-area .gender{
    margin-right: 15px;
    font-size: 14px!important;
    font-family: Mona-Sans, sans-serif!important;
    font-style: normal;
    font-weight: 400;
}

.eye-part{
    margin-right: 5px!important;
    color: #fff!important;
}
.center-alignmy-profile {
    text-align: center;
    justify-content: center;
}

.div-profile-image-center{
    text-align: center;
    margin: 0px auto!important;   
}

.center-upload-image-fix-size {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    object-fit: fill;
}

.my-profile-text-size h1{
    font-family: Mona-Sans, sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
    margin-bottom: 20px;
    
}

.my-profile-text-size p{
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    
}

.my-profile-text-size img{
    vertical-align: top;
}

.edit-myaccount-button{
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
    font-family: Mona-Sans, sans-serif!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: normal !important;
    text-align: center;
    padding: 15px 30px!important;
    border-radius: 10px!important;
    height: 43px;
    min-width: 134px !important;
    color: #fff !important;
    text-transform: capitalize !important;
}

.twofa-setup{
    font-family: Mona-Sans, sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    margin-bottom: 20px;
    border-top: 1px solid #FFFFFF1A;
    padding-top: 30px;
    margin-top: 35px;

}

.profile-form p{
    font-family: Mona-Sans, sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.profile-form h3{
    font-family: Mona-Sans, sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.email-twofa h3{
    font-family: Mona-Sans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0px;
    margin-bottom: 7px;
}

.email-twofa {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    color: #fff;
}

.configured-label-tag-account{
    text-transform: capitalize !important;
    background: #22FF2B33!important;
    border-radius: 10px !important;
    font-family: Mona-Sans, sans-serif !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #20FF29!important;
    padding: 1px 10px 0px !important;
    top: -5px;
    left: 5px;
}
.margin-left-email-ids{
    margin-left: 45px;
}

.profile-form .MuiStepLabel-iconContainer{
    width: 50px;
    height: 50px;
    position: relative;
}

.profile-form .MuiStepLabel-iconContainer.Mui-active:before {
    content: "";
    position: absolute;
    border-radius: 100px;
    padding: 1.5px;
    background: linear-gradient(180deg, #4c2ef4 0%, #ff36e8 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    width: 50px;
    height: 50px;
    z-index: 9999;
}

.profile-form .MuiStepLabel-iconContainer svg{
    width: 50px;
    height: 50px;
    z-index: 9;
    color: transparent!important;
    font-family: Mona-Sans, sans-serif;
    background: linear-gradient(180deg, #989898 0%, #ac9f9f 100%);
    border-radius: 100px;
}

.profile-form .MuiStepLabel-iconContainer svg.Mui-active{
    background: linear-gradient(180deg, #350D59 0%, #721CBF 100%);
    color: transparent!important;
    border-radius: 100px;
    border-top-width: 5px;

}

.profile-form .MuiStepConnector-line{
    margin-top: 11px;
    border-color: #b1b1b1;
    border-top-style: solid;
    border-top-width: 5px;
}

.profile-form .MuiStepIcon-text{
    fill: #fff;
    font-family: Mona-Sans, sans-serif;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.mr-side-auto{
    min-width: 189px!important;
    height: 43px;
    font-family: Mona-Sans, sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 13.54px;
    text-align: center;
    padding: 15px 20px !important;
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
}

@media (max-width: 767px){
    .gender-area{
        justify-content: space-between;
    }
.profile-form .MuiPaper-rounded{
 width: 100%;
 padding: 20px!important;
}
}