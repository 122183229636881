.MuiPopover-paper {
  width: fit-content !important;
  right: 0px !important;
  background: #150D43 !important;
  border: 1px solid #404040 !important;
  border-radius: 7px !important;
}

.profile-bg-grey {
  background-color: transparent !important;
  gap: 10px;
}

.profile-bg-grey:hover {
  background-color: transparent !important;
}

.profile-bg-grey:before {
  background-color: transparent !important;
}

.input-header-search {
  background-color: #271969;
  width: 300px;
  height: 39px;
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
  color: #FFFFFF80;
  font-family: Mona-Sans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 11.6px;
  text-align: left;

}

.input-header-search::placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.input-header-search::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.relative-search {
  position: relative;
  right: 50px;
  margin-left: 0 !important;
}

.relative-search img {
  position: absolute;
  right: 7px;
  top: 7px;
}

.add-vendor-btn {
  height: 40px;
  padding: 6px 16px !important;
  display: flex !important;
  margin: 5px 10px 0px auto !important;
}

.add-vendor-button-header {
  background: #271969;
  border-radius: 10px;
  font-family: Mona-Sans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 12.6px;
  text-align: center;
  color: #fff;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  gap: 14px;
  margin-right: 77px;
  height: 39px;
  cursor: pointer;
}

.newheader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-top: 5px;
  padding-right: 25px;
}

.MuiIconButton-sizeMedium .hamberger-icon {
  display: none;
}

.search-icon {
  min-height: 0px !important;
}

.notification-popups {
  position: relative;
  right: 25px;
  top: 5px;
}

.notification-text-data {
  position: absolute;
  top: 46px;
  background: #2d206c;
  width: 325px;
  border-radius: 6px;
  padding: 10px 0px 0px 10px;
  z-index: 999;
  right: -65px;
  height: 300px;
  overflow-y: auto;
}

.notification-text-data h3 {
  font-weight: 500;
  font-family: Mona-Sans, sans-serif;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin: 0;
}

/* .notification-text-data::before{
  content: "";
  position: absolute;
  top: 28px;
  left: 21px;
  transform: translateX(-70%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #2d206d;
  width: 19px;
  height: 19px;
  z-index: 99999999999;
  display: block;
} */

.notification-text-data::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Match this with your container background color */
}

.notification-text-data::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.notification-text-data::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.notification-text-data::-webkit-scrollbar-thumb {
  background: #4c4c74;
  /* Customize the thumb color */
  border-radius: 8px;
}

.time-clock-notify {
  background: #120a3a;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.47px;
  text-align: left;
  margin-right: 10px;

}

.time-clock-notify .severity {
  border-radius: 5px;
  width: 70px;
  text-align: center;
  padding: 1px 0px;
}

.time-clock-notify div {
  display: flex;
  justify-content: space-between;
}

.time-clock-notify span img {
  vertical-align: middle;
}

.pop-notification-number {
  width: 25px;
  height: 25px;
  background: linear-gradient(90deg, #5c0ecc, #89119e);
  color: #fff;
  font-size: 12px;
  position: absolute;
  border-radius: 50px;
  text-align: center;
  line-height: 27px;
  top: -7px;
  right: -10px;
  font-family: Mona-Sans, sans-serif;
}

@media screen and (min-width: 320px) and (max-width: 1199px) {

  .desktop-hide-nav {
    transform: translateX(0px);
  }

  .another-new-class .desktop-hide-nav {
    transform: none;
  }

}



@media (min-width: 1800px) {
  .newheader {
    padding-right: 5px;
  }
}