.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  background-color: #1a114c;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* max-height: 400px; */
  /* overflow-y: auto; */
}


.suggestions-list::-webkit-scrollbar-corner {
    background-color: transparent; /* Match this with your container background color */
}

.suggestions-list::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: transparent!important;
}

.suggestions-list::-webkit-scrollbar-track {
    background: transparent!important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
}

.suggestions-list::-webkit-scrollbar-thumb {
    background: #4c4c74; /* Customize the thumb color */
    border-radius: 8px;
}

.suggestions-list li {
    padding: 0px;
    cursor: pointer;
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #fff;
    padding-bottom: 18px;
}

.suggestions-list li:last-child{
    border-bottom: 0px solid #FFFFFF2E;
}

.suggestions-list li:hover {
  background-color: #1b0f52;
}
.bar-suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  background-color: #1a114c;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 400px; 
  overflow-y: auto; 
  margin-top: 3px;
  border: 1px solid #4B4B4B66;
}

.bar-suggestions-list::-webkit-scrollbar-corner {
    background-color: transparent; /* Match this with your container background color */
}

.bar-suggestions-list::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: transparent!important;
}

.bar-suggestions-list::-webkit-scrollbar-track {
    background: transparent!important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
}

.bar-suggestions-list::-webkit-scrollbar-thumb {
    background: #4c4c74; /* Customize the thumb color */
    border-radius: 8px;
}

.bar-suggestions-list li {
    padding: 0px;
    cursor: pointer;
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #fff;
    padding-top: 20px;
}

.bar-suggestions-list li:last-child{
    border-bottom: 0px solid #FFFFFF2E;
}

.bar-suggestions-list li:hover {
  background-color: #1b0f52;
}
.input-header-search-active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.sparkle-search-icon img {
  position: absolute;
  right: 6px;
  top: 7px;
}

.select-type-search-text{
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.08px;
  text-align: left;
}

.search-heading-ai-text{
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 17.41px;
  text-align: left;
  text-transform: lowercase;
  padding: 15px 25px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #FFFFFF1A;
}

.search-heading-ai-text::first-letter{
  text-transform: capitalize;
}

.peragraphai-data{
  padding: 25px 25px 10px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.left-listai-suggest {
  padding-left: 18px;
  padding-top: 0;
  font-family: Mona-Sans, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  border-bottom: 1px solid #FFFFFF2E;
  padding-bottom: 15px;
}

body .ask-genesis-ai{
  background: #830ECCCC!important;
  border: 0.51px solid #BE62FF!important;
  font-family: Mona-Sans, sans-serif!important;
  font-size: 16px!important;
  font-weight: 600!important;
  line-height: 6.35px;
  text-align: center;
  border-radius: 5px;
  width: 210px;
  height: 49px;
}

.sparkle-search-icon{
  display: flex;
  justify-content: left;
  gap: 30px;
  flex-wrap: wrap;
}

.ask-genesis-help{
  background: linear-gradient(90deg, rgba(93, 34, 135, 0.6) 0%, rgba(39, 25, 104, 0.6) 100%);
  border-radius: 10px;
  padding: 22px;
  position: relative;
  width: 74%;
}

.ask-genesis-help h3{
  font-family: Mona-Sans, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  margin-top: 10px;
}

.ask-genesis-help p{
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: left;
max-width: 70%;
margin-bottom: 10px;
}

.ask-genesis-help img {
  top: 37px;
  right: 22px;
}

.ask-genesis-help:before {
  content: "";
  position: absolute;
  top: -25px;
  right: 19px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #29177F66;
  width: 25px;
  height: 25px;
}