

.remediation-table{
    background-color: #fff;
    margin-top: 25px;
    border-radius: 15px;
}

.remediation-table h3 {
    padding-left: 15px;
    padding-top: 15px;
}

.cisco-link{
    display: flex;
    justify-content: right;
    margin-left: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight:500;
    align-items: center;
}

.cisco-link svg {
    color: #242526;
    width: 20px;
    height: 20px;
    margin: 0px 0px 0px 8px;
}

.remediation-table p{
   padding: 0px 15px 20px;
   font-family: 'Poppins';
   font-style: normal;
   font-weight:400;
}

.dark-mode-class .remediation-table{
    background: #242526;
    border: 1px solid #3A3B3C;
}

.dark-mode-class .manage-middle span{
color: #fff;
}

.dark-mode-class .manage-middle .flx-property{
    color: #fff;
}

.dark-mode-class .cisco-link svg{
    color: #fff;
}

.display-flex{
    display: flex;
    align-items: center;
}

.display-flex .domain-name{
margin-top: 0px!important;
}





