.heatmap-box {
  width:100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #ffffff;
  position: relative;
  font-family: Mona-Sans, sans-serif;
}

.heatmap-box span {
  position: absolute;
  right:4px;
  border: 1px solid #000;
  border-radius: 100px;
  padding: 5px;
  font-family: Mona-Sans;
  font-size: 11px;
  font-weight: 600;
  line-height: 9px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 21px;
  height: 21px;
  top: 4px;
  color: #000;
  text-align: center;
}

.heatmap-box-critical {
  background-color: #ff0000;
  color: #ffffff;
}

.heatmap-box-high {
  background-color: #ffa500;
  color: #ffffff;
}

.heatmap-box-medium {
  background-color: #ffd700;
  color: #000000;
}

.heatmap-box-low {
  background-color: #008000;
  color: #ffffff;
}

.heatmap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  padding: 15px 20px;
  border-radius: 16px;
  position: relative;
}

.new-class .heatmap-container {
  padding: 15px 36px;
}

.heatmap-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.yellow-shadow-background {
  background-color: #e8cd3b;
  padding: 11px 11px;
  cursor: pointer;
}

.green-shadow-background{
  background-color: #169D16;
  padding: 11px 11px;
  cursor: pointer;
}

.high-shadow-background{
  padding: 11px 11px;
  background-color: #F9B12F;
  cursor: pointer;
}

.critical-shadow-background{
  background: #FD2D36;
  padding: 11px 11px;
  cursor: pointer;
}

.contain-box .critical{
  position: absolute;
  left: -49px;
  font-size: 13px;
  transform: translate(5px, 29px);
}

.bottomfit{
position: absolute;
bottom: 0;
}
