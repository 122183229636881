.logo-preview .preview-image {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-top: 14px;
}

.show-document-button {
    line-height: 25px !important;
    border-radius: 7px !important;
}

.show-compliance .compliance-logo {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    margin: 10 auto;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    color: #fff !important;
}

.drag-over {
    border: 2px dashed #2196f3;
    background-color: #f0faff;
}


.dark-mode-class #custom-input::placeholder {
    color: #fff !important;

}

.light-mode-class #custom-input::placeholder {
    color: #000 !important;
    opacity: 0.7 !important;
}

.dark-mode-class #custom-input:disabled::placeholder {
    color: #fff !important;
}

/* Light mode */
.light-mode-class #custom-input:disabled::placeholder {
    color: #000 !important;
    opacity: 0.7 !important;
}


.margin-questionnaire .datagrid-table{
    border: none!important;
}