.tabs-bg{
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 15px;
    margin-top: 40px;
    position: relative;
}
.preview-popup.css-19owhuc .cross-right-side svg{
    color: #242526;
}

.css-139lcja .cross-right-side svg{
    color: #fff;
}

.inline-parts{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tabpanel-full .MuiBox-root{
    width: 100%;
    padding-top: 0px;
    padding-right: 0px;
}

.w100-textarea{
width: 100%;
color: #242526;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
border: 1px solid #E4E6EA;

}

.cross-right-side {
    width: auto;
    color: #212121!important;
    position: fixed!important;
    right: 160px;
    top: 43px;
}

.preview-popup {
    max-width:884px;
    margin-inline: auto;
    max-height: 560px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
}

.upload-questionnaire-pop{
    max-height: 100%;
}

.cross-right-side:hover{
    background-color: transparent!important;
}

.inline-parts h3{
    margin-top: 0px;
}

.tabs-bg .MuiTab-root{
text-transform: capitalize;
font-size: 15px;
color: #242526;
font-family: 'Poppins';
font-style: normal;
font-weight:200;
padding-left: 0;
text-align: left;
flex-direction: revert;
justify-content:left;
}

.tabs-bg.preview-popup.MuiBox-root.css-139lcja .MuiTab-textColorPrimary{
    color: #fff;
}

.tabs-bg .MuiTab-root svg{
margin-right: 5px;
width: 20px;
height: 20px;
}

.tab{
    width: 100%;
    min-height: 500px;
}

.question-part{
    width: 100%;
  
}

.question-part label{
    width: 100%;
}

.attached-button label{
    width: auto;
}

.alignciq-question{
    padding: 0px 0px;
}

.question-part p{
    font-size: 16px;
    color: #242526;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
}

.question-part .MuiTypography-root{
    font-size: 15px !important;
}


.top-part{
    width: 100%;
    margin-left: 15px;
}
.top-part p{
    font-size: 16px;
    color: #242526;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
}
.top-part .MuiTypography-root{
    font-size: 15px !important;
}

.tabpanel-full{
    width: 100%;
}
.tabs-bg .Mui-selected{
    color: #4a10a4!important;
    font-weight: 900!important;
}

.dark-mode-class .tabs-bg .Mui-selected{
    color: #fff!important;
}

.tabs-bg .MuiTabs-indicator{
    background-color: #4a10a4!important;
}

.tabs-bg .MuiTabs-vertical{
    max-width: 180px;
    min-width: 180px;
    border-color: #E4E6EA!important;
}

.MuiRadio-colorPrimary.Mui-checked{
    color: #4a10a4!important;
}

.modal-popup .MuiDialog-paper{
    min-width: 700px;
    padding: 25px 35px;
    text-align: center;
    border-radius: 15px;
}

.send-assessments{
    font-family: Mona-Sans, sans-serif;
    font-style: normal;
    font-weight:500;
    font-size: 25px;
    line-height: 35px;
    color: #fff;
}

.model-btns{
    width:100px;
}

.dark-mode-class .tabs-bg{
    background: #1b134b;
    border: 1px solid #3A3B3C;
}

.dark-mode-class .w100-textarea{
    background: #1b134b;
    border: 1px solid #3A3B3C;
    color: #fff;
}

.dark-mode-class .tabs-bg .MuiTab-root{
color: #fff;
}

.dark-mode-class .tabs-bg .MuiTabs-vertical{
border-color:#3A3B3C!important;
}

.dark-modal .send-assessments{
color: #fff;
}

.dark-mode-class .tabs-bg .Mui-selected{
    background-color: #1b134b!important;
    border: none !important;
}

.btn-right a {
    text-decoration: none;
}

.cancel-btn{
    background-color: #ffffff!important;
    border: 1px solid #4a10a4!important;
    color: #4a10a4!important;
    font-weight: 400!important;
}

.center-vendor{
    margin: 0px auto!important;
    
}

.passed{
    font-family: 'Poppins'!important;
    font-size: 15px;
    font-weight: 400!important;
    margin-top: 18px!important;
}

.set-view{
    margin-bottom: 10px;
    margin-left: 5px;
}

.dark-mode-class .view-file svg {
color: #fff;
}

.dark-mode-class .MuiRadio-colorPrimary.Mui-checked{
    color: #fff!important;
}

#passed-color{
    background: #008000!important;
    line-height: 22px !important;
    font-size: 12.13px!important;
    font-weight: 400;
    width: 112px;
    margin: 0px 25px 0px auto;
    display: block;
}

#failed-color{
    background: #FF0000 !important;
    line-height: 22px !important;
    font-size: 12.13px!important;
    font-weight: 400;
    width: 112px;
    margin: 0px 25px 0px auto;
    display: block;
}
#passed-color-button{
    background: #008000!important;
    line-height: 22px !important;
    font-size: 12.13px!important;
    font-weight: 400;
    width: 112px;
    display: block;
}

#failed-color-button{
    background: #FF0000 !important;
    line-height: 22px !important;
    font-size: 12.13px!important;
    font-weight: 400;
    width: 112px;
    display: block;
}

.right-view {
    text-align: right;
}

.padding-square{
    padding: 20px;
}

.add-section {
    width: 110px;
    height: 40px;
    padding: 0!important;
    margin: 0px 10px 20px auto!important;
}

.chat-circle-right{
    background-color: #4a10a4;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    color: #fff;
    line-height: 80px;
    text-decoration: none;
    font-size: 13px;
    text-align: center;
    overflow-y: hidden;
}

.pop-tabs-width{
    width: 95%;
    margin-inline:auto;
}

.preview-question-heading{
    background: #271771;
    min-width: 35px;
    height: 26px;
    text-align: center;
    font-family: Mona-Sans, sans-serif;
    font-size: 11px;
    font-weight: 600;
    line-height: 25px;
    border-radius: 5px;
    margin-top: 3px;
    color: #fff!important;
}

.data-text-preview{
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-left: 12px;
    margin-right: 1px;
    color: #fff;
}
.no-evidence-available{
    color: #ff0000; /* Red color for emphasis */
    font-weight: bold; /* Make the text bold */
    font-size: 16px; /* Set the font size */
    text-align: center; /* Align text to the center */
    display: block; /* Ensure it's block-level to center it */
    margin-top: 10px; /* Add some margin on top for spacing */
    font-family: 'Arial', sans-serif; /* Font family */
}

.line-h-0{
    line-height: 0;
}

.tabpanel-full-size{
    width: 80%;
}

 /* .css-argv3p .dark-mode-class{
    background: #242526;
    border: 1px solid #3A3B3C;
} */

/* .css-argv3p .cross-right-side svg{
    color: #fff;
} */
.cross-right {
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 0;
    color: #212121;
}

.cross-right:hover{
background-color: transparent;
}

.radio-text-size-label{
    font-size: 16px;
}

.score-selected{
    width: fit-content;
    padding: 4px 10px;
    border: 1px solid #E4E6EA;
    margin: 10px 0;
}

.dark-mode-class .score-selected{
    border:1px solid #3A3B3C;
}

.mui-icons-questionnaire button {
    background: #f5f6f7;
    text-align: center;
    margin: auto;
    height: 40px;
    width: 45px;
    min-width: 45px;
}

.dark-mode-class .mui-icons-questionnaire button{
    background: #242526;
    border: 1px solid #515151;
}

.dark-mode-class .mui-icons-questionnaire button svg{
    color: #fff;
}

.mui-icons-questionnaire svg {
    margin-right: 0;
}

.space-file{
    margin-left: 10px;
}

.deletequestion{
    max-width: 42%;
}

.deletequestion h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #242526;
    text-align: center;
    margin-bottom: 30px;
}

.deletequestion-btn {
    width: 145px;
    height: 40px;
    padding: 0!important;
    display: flex;
    justify-content: center;
}
.cross-rights{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

}

.preview-popup::-webkit-scrollbar-corner {
    background-color: transparent; /* Match this with your container background color */
}

.preview-popup::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: transparent!important;
}

.preview-popup::-webkit-scrollbar-track {
    background: transparent!important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
}

.preview-popup::-webkit-scrollbar-thumb {
    background: #4c4c74; /* Customize the thumb color */
    border-radius: 8px;
}

.mbtop-manage {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px!important;
    width: 100%;
}

.preview-pop-heading{
    text-align: left;
    margin-bottom: 22px;
    font-weight: 500;
    font-family: Mona-Sans , sans-serif;
    font-size: 16px;
}

.bg-dark-assessment .css-1ktfdg8-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter,
.bg-dark-assessment .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter{
    margin: 8px 8px 0;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main,
.MuiDataGrid-main{
    font-family: Mona-Sans , sans-serif;
    color: #fff;
    font-weight: 400;
}

.MuiDataGrid-columnHeaderTitle{
    font-weight: 500!important;
}

/* .bg-dark-assessment .css-gvpb0f-MuiDataGrid-root .MuiDataGrid-columnHeader, 
.bg-dark-assessment .css-gvpb0f-MuiDataGrid-root .MuiDataGrid-cell{
    padding: 0 0px 0 20px;
} */

.MuiDataGrid-withBorderColor{
    width: 100%;
}

@media (min-width: 1367px){
    .cross-right-side{
        right: 210px;
    }
}

@media (max-width: 767px){
    .deletequestion-btn {
        width: 119px;
    }
    .tabs-bg .MuiBox-root{
        display: block;
        padding-left: 0px;
    }
    .tabs-bg .MuiTabs-vertical {
        max-width: 100%;
        min-width: 100%;
    }
    .preview-popup {
        max-width: 90%;
    }
}

.color-bg{
    background-color: #242526 !important;
}

.question-card{
    border: 1px solid gray;
    margin: 15px 0px 6px 6px;
}
.question-card .questions-info{
    border: 1px solid gray;
    margin: 3px;
    border-radius: 10px;
 
    height: 48px;
    width: 108px;
}
.questions-info p{
    font-size: 13px;
    color: #f4f5f6 !important;
    font-style: normal;
    font-weight: 400 !important;
    margin: 0px !important;
}
.questions-info spam{
    font-size: 9px;
    color: #f4f5f6 !important;
    font-style: normal;
    font-weight: 400 !important;
    margin: 0px;
}
.question-info{
    margin-bottom: 4px;
    font-size: 10px;
    padding: 2px;
    border-radius: 4px;
    width: 100px;
    height: fit-content;
    margin-right: 9px;
    min-width: fit-content;
    background-color: rgb(209 209 209);
    color: black
}
.question-info-2{
    overflow: hidden;
    margin-bottom: 4px;
    font-size: 10px;
    padding: 4px;
    height: fit-content;
    margin-right: 9px;
    white-space: nowrap;
    border-radius: 4px;
    width: 100%;
    background-color: rgb(209 209 209);
    color: black
}
.css-1tbgwje-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, 
.css-1tbgwje-MuiDataGrid-root .MuiDataGrid-cell:focus-within{
    outline: 0px!important;
}

.upload-questionnaire-pop .add-new-attack-heading{
padding-top: 15px;
}

.upload-questionnaire-pop .bgaddfiles{
padding-top: 15px;
padding-bottom: 10px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: red!important;  /* Prevent border color change */
  }


  .onboard-green.stroke {
    background: #22FF2B33;
    font-family: Mona-Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 25.4px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #20FF29;
    width: 112px;
    height: 25px;
    border-radius: 4px;
    text-align: center;
    position: relative;
    z-index: 1;
    border: none; /* Remove any default border */
    box-shadow: 4px 4px 4px -20px #00000040;

}

.onboard-green.stroke:before {
    content: '';
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    right: -1.5px;
    bottom: -1.5px;
    border-radius: 4px;
    background: linear-gradient(90deg, rgba(64, 64, 64, 0.2) 0%, rgba(166, 166, 166, 0.2) 100%);
    z-index: -1; /* Position behind the button text */
    
}

body .margin-questionnaire.MuiPaper-elevation1{
    border:none!important;
}

.dots-words-limit {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
}

.same-save-new{
    background: linear-gradient(90deg, #5c0ecc 0%, #89119e 100%)!important;
    font-family: Mona-Sans, sans-serif!important;
    font-size: 15px!important;
    font-weight: 400;
    line-height: 15.47px;
    text-align: center;
    color: #fff;
    padding: 5px 22px !important;
    height: 40px;
    min-width: 130px!important;
    margin-bottom: 20px!important;
}

.move-margin-rigt-0{
    display: flex;
    margin-right: 20px;
    justify-content: right;
    gap: 20px;
}

.ciq-space{
    padding: 0px 25px;
}