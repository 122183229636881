body {
  margin: 0px auto;
}

.flex-total-issues {
  display: flex;
  align-items: center;
  justify-content: right;
  max-height: 30px;
  padding-top: 20px;
}

.w-100-issue {
  width: 100%;
}

.css-1byr0tz {
  padding-top: 20px !important;
  background-color: #120a3a;
  padding-bottom: 0 !important;
  /* position: relative; */
  max-width: 1366px;
}

.css-1byr0tz::-webkit-scrollbar-corner {
  background-color: transparent; /* Match this with your container background color */
}

.css-1byr0tz::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent !important;
}

.css-1byr0tz::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.css-1byr0tz::-webkit-scrollbar-thumb {
  background: #4c4c74; /* Customize the thumb color */
  border-radius: 8px;
}

.blur-box-cover-area {
  position: relative;
}

.blur-box-cover-area-overall-risk-score {
  position: relative;
}

.blur-box-cover-area:before {
  content: "";
  width: 100%;
  height: 74%;
  position: absolute;
  left: 0;
  right: 0;
  background: rgb(21 13 67 / 68%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  /* padding: 20px; */
  /* color: #fff; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  z-index: 9;
 bottom: 0px;
}

.vertical-bar-chart-blur:before {
  height: 88%;
}
.overall-risk-score-blur:before {
  height: 81%;
}
.sunburst-blur:before {
  height: 90%;
}
.heatmap-blur:before {
  height: 86%;
}

.overall-risk-score-icon-blur:before{
  height: 100%;
}

.third-party-classification-blur:before{
  height: 85%;
}

.third-party-at-risk-blur:before{
  height: 81%;
}
.assessments-blur-images:before{
  height: 50%;
}

.MuiPaper-elevation1 {
  background: #150d43 !important;
  border: 1px solid #404040 !important;
  box-shadow: none !important;
  border-radius: 15px !important;
}

.fit-height .MuiPaper-elevation1 {
  width: 100%;
}

.MuiTypography-root {
  font-family: "Mona-Sans" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 15.47px;
  padding-top: 0px !important;
  color: #fff;
}

.left-fit-contain h6 {
  font-size: 14px !important;
  line-height: 17px;
}

.MuiAppBar-positionFixed {
  background: transparent !important;
  backdrop-filter: inherit !important;
  margin: 15px 0 0px;
}

.css-bqyarn-MuiPaper-root-MuiCard-root {
  padding-bottom: 0px !important;
}

.css-144g3e0-MuiPaper-root-MuiCard-root {
  padding-bottom: 0px !important;
}

.align-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0px;
  padding: 25px 5px;
}

.vw-width {
  width: 100vh;
}

.vw-width h4 {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 19px;
  color: #242526;
  opacity: 1;
  margin-bottom: 6px;
}

.vw-width h6 {
  opacity: 1;
  font-weight: 400 !important;
}

.totalnumber h2 {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 45px !important;
  line-height: 50px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #242526;
  opacity: 1;
}

.card-headerpart {
  display: flex;
  align-items: center;
  padding: 18px 25px;
  justify-content: space-between;
  border-bottom: 1px solid #2c2556;
  margin-bottom: 25px;
}

.min-width {
  min-width: 82px;
}
.min-width-s-number {
  width: 40px;
  padding-right: 0 !important;
}

.css-1ex1afd-MuiTableCell-root {
  font-size: 13.52px !important;
  color: #fff !important;
  border-bottom: 1.13px solid rgba(255, 255, 255, 0.1) !important;
}

.head-th th {
  font-family: Mona-Sans, sans-serif;
  font-size: 13.52px;
  font-weight: 400;
  line-height: 11.6px;
  text-align: left;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.02) !important;
  border-bottom: 1.13px solid rgba(255, 255, 255, 0.1);
}

.third-party-risk-table tr td {
  border-bottom: 1.13px solid rgba(255, 255, 255, 0.1);
  font-family: Mona-Sans, sans-serif;
  font-size: 13.52px;
  font-weight: 400;
  line-height: 13.08px;
  text-align: left;
  color: #fff;
}

.css-1j30m50 {
  padding: 0 !important;
}

.right-search {
  position: absolute !important;
  right: 90px;
  top: 8px;
}

.flex-property .MuiPaper-elevation1 {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
}

.fit-height .MuiPaper-elevation1 {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
}

.left-fit-contain .MuiCard-root:first-child {
  margin-bottom: 0px;
}

.left-fit-contain .MuiPaper-elevation1 {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
}

body .left-fit-contain .line-dark {
  box-shadow: none !important;
  margin-bottom: 0px !important;
}

.MuiButton-containedPrimary {
  background-color: #4a10a4 !important;
  color: #fff !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
  box-shadow: none !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  /* line-height: 100px !important; */
}

.flex-property {
  display: flex;
  width: 100%;
  height: 100%;
}

.css-10650aq {
  /* height: 100vh !important; */
  justify-content: center;
}

.w100 .MuiPaper-elevation1 {
  width: 100%;
}

.css-1cj3uuw-MuiButtonBase-root-MuiIconButton-root {
  cursor: pointer;
}

.css-1cj3uuw-MuiButtonBase-root-MuiIconButton-root:before {
  background: transparent !important;
}

.css-1y19tqg h6 {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 20px;
  color: #fff;
}

.css-1y19tqg p {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 20px;
  color: #fff;
}

.css-1ludl2-MuiStack-root a li {
  font-family: Mona-Sans, sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  color: #fff;
}

.new-heading-front {
  font-family: Mona-Sans, sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contaner-box {
  background-color: #fff;
}

.fit-height {
  display: flex;
}

/* .fit-height .css-xqhl11 {
    height: auto !important;
} */

.left-fit-contain {
  display: flex;
  flex-wrap: wrap;
}

.left-fit-contain .MuiCard-root {
  display: flex;
  /* align-items: center; */
  width: 100%;
}

.dark-mode-class .vw-width h4 {
  color: #fff;
}

.dark-mode-class .totalnumber h2 {
  color: #fff;
}

.apexcharts-legend-text {
  font-size: 15px !important;
  color: #fff !important;
  /* margin-bottom: 8px !important; */
  font-family: Mona-Sans, sans-serif !important;
  font-weight: 400 !important;
}

.dark-mode-class p {
  color: #fff;
}

.dark-mode-class {
  background-color: #120a3a;
}

.dark-mode-class .heading-main {
  color: #fff;
}

.dark-mode-class .MuiPaper-elevation1 {
  background: #150d43 !important;
  border: 1px solid #404040 !important;
}

.dark-mode-class .line-dark {
  border: 0px !important;
}

.dark-mode-class .apexcharts-legend-text {
  color: #fff !important;
}

.dark-mode-searchbar .searchs-icon {
  filter: brightness(0) saturate(100%) invert(100%) sepia(23%) saturate(1192%)
    hue-rotate(160deg) brightness(115%) contrast(109%);
}

.fit-height .MuiInputBase-root {
  border-radius: 10px !important;
  color: #242526 !important;
  /* border: 0.5px solid #242526!important; */
}

.fit-height .MuiOutlinedInput-input {
  color: #242526 !important;
  font-size: 15px !important;
  font-family: "Poppins" !important;
}

.dark-mode-class .MuiOutlinedInput-input {
  color: #fff !important;
}

.fit-height .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  color: #242526 !important;
  font-size: 15px !important;
  font-family: "Poppins" !important;
}

.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
  display: block !important;
}

.percentText {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: -25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
}

.textSize {
  font-size: 20px;
}

.searchbutton {
  line-height: 28px !important;
}

.card-full form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 15px 15px 0px;
}

.print-text {
  font-family: Mona-Sans, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 0px;
  margin-bottom: 0px !important;
  color: #000;
}

.view-file {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .view-file a {
    position: absolute;
    right: 0px;
} */

.view-file svg {
  color: #dfdde4;
}

.pin-upload {
  background: grey !important;
  color: #dfdde4 !important;
  border: 1px dashed #8b8b8b !important;
  height: 35px;
  padding: 15px !important;
}

.inbutton {
  display: flex;
  text-align: right;
  justify-content: right;
}

.inbutton a {
  text-decoration: none;
}

.Executive {
  width: 175px !important;
  border-radius: 50px !important;
}

.right-15 {
  margin-right: 15px;
}

.security-posture {
  font-family: Mona-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;  
  background: #F4F4F4;
  color: #000;
  padding: 14px;
}

.export-ex .percentText {
  margin-top: -30px;
  margin-bottom: 7px;
}

.padding-issues {
  padding: 15px;
}

.bgprint {
  background: #ffffff;
  border-radius: 10px;
}

canvas {
  display: none;
}

.bgprint .affecteds-part h3 {
  color: #242526 !important;
}

.bgprint .affecteds-part .target-color {
  color: #242526 !important;
}

.bgprint .issues-peraa {
  color: #242526 !important;
}

.issueskey h4 {
  color: #242526 !important;
}

.css-1u361qs-MuiCardHeader-root {
  color: #242526 !important;
}

.css-bj2p8a-MuiPaper-root-MuiCard-root .css-1qvr50w-MuiTypography-root {
  color: #fff !important;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root .css-1qvr50w-MuiTypography-root {
  color: #242526 !important;
}

.fit-height .css-xqhl11 {
  height: auto !important;
}

.bg-dark-assessment .css-wop1k0-MuiDataGrid-footerContainer {
  border-top: 0px !important;
}

.gobal-top-move {
  margin-bottom: 21px;
}

.apexcharts-radial-series {
  position: relative;
}

.apexcharts-radial-series:after {
  content: "";
  display: block;
  position: absolute;
  width: 20px; /* Size of the marker */
  height: 20px; /* Size of the marker */
  background-color: #ffffff; /* Color of the circle */
  border-radius: 50%; /* Make it a circle */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); /* Optional: shadow for better visibility */
  z-index: 9999; /* Ensure it appears above other elements */

  /* Adjust the following based on your chart */
  top: 50%;
  left: 50%;
  transform: translate(
    90px,
    -70px
  ); /* Position the circle at the end of the bar */
}

.full-coverdesign .css-rorn0c-MuiTableContainer-root {
  overflow-x: clip !important;
}
.apexcharts-datalabels-group {
  font-weight: 600 !important;
}

.new-heading-front span {
  padding-left: 4px;
  color: #fff;
}

@media (min-width: 1600px) {
  .image-third-party,
  .total-issues-chart {
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .topHeader-move {
    min-height: 0px !important;
    background: transparent;
  }

  .dark-mode-class .topHeader-move {
    background-color: transparent;
    /* right: 19px; */
  }
}

@media (min-width: 1920px) {
  .assessment-datacheck {
    top: 2px !important;
  }
  .topHeader-move.header-move {
    background-color: transparent;
    right: 60px;
  }
  body {
    height: 65vw;
    overflow-y: auto;
  }
  .css-1byr0tz {
    overflow-y: hidden;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent; /* Match this with your container background color */
  }

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: transparent !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: #4c4c74; /* Customize the thumb color */
    border-radius: 8px;
  }
}

@media screen and (min-width: 1525px) and (max-width: 1799px) {
  #chart {
    max-width: 355px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1698px) {
  .css-10650aq {
    height: 100vh !important;
  }
  .max-w-left {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .dark-mode-class .topHeader-move {
    right: -42px;
  }

  .ml25 {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1620px) and (max-width: 1799px) {
  .css-1byr0tz {
    overflow-y: hidden !important;
  }
  body {
    height: 70vw;
    overflow-y: auto !important;
  }

  .fit-height #chart {
    max-width: 460px !important;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent; /* Match this with your container background color */
  }

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: transparent !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: #4c4c74; /* Customize the thumb color */
    border-radius: 8px;
  }
}

@media (min-width: 1800px) {
  .css-1byr0tz {
    overflow-y: hidden !important;
  }
  body {
    height: 70vw;
    overflow-y: auto !important;
  }

  .fit-height #chart {
    max-width: 460px !important;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent; /* Match this with your container background color */
  }

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: transparent !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: #4c4c74; /* Customize the thumb color */
    border-radius: 8px;
  }
  .dark-mode-class .topHeader-move {
    right: -15px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .line-dark h2 {
    font-size: 26px !important;
  }

  .vw-width h4,
  .vw-width h6 {
    padding-left: 7px;
  }
}

@media (min-width: 600px) {
  .css-19r6kue-MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1699px) {
  .ipad-space {
    padding: 0px 20px 0 20px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .line-dark h2 {
    font-size: 26px !important;
  }

  .vw-width h4,
  .vw-width h6 {
    padding-left: 7px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .number-up {
    font-size: 12px;
  }
  .ml25 {
    margin-left: 20px;
  }

  .same-as-username {
    max-width: 100px;
  }

  .right-search {
    right: 79px;
    top: 12px;
  }

  .dark-mode-sidebar .css-ixt6q8 {
    background-color: #353535 !important;
    border-top: 1px solid #424242;
    border-bottom: 1px solid #424242;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .new-heading-front {
    font-size: 16px;
    line-height: normal;
  }

  .css-1dfrjtm-MuiPaper-root-MuiAppBar-root {
    left: 42% !important;
  }
  .css-1byr0tz {
    overflow-y: hidden !important;
  }
  body {
    height: 70vw;
    overflow-y: auto !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 899px) {
  .fit-height .MuiCard-root {
    /* height: 480px !important; */
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .fit-height .MuiCard-root {
    /* height: 347px; */
  }

  .ipad-space {
    padding: 0px 20px 0 20px;
  }
  .input-header-search {
    width: 150px;
  }
  .relative-search {
    right: 25px;
  }
  .new-heading-front {
    font-size: 13px;
  }
  .same-as-username {
    font-size: 13px !important;
    max-width: 82px;
  }
  .heading-main {
    margin-left: 20px;
  }
  .newheader {
    padding-left: 20px;
  }
  .question-buttons {
    position: relative;
    right: 20px;
    top: 10px;
  }

  /* .left-fit-contain .MuiCard-root{
    height: 100px;
} */
  #chart {
    max-width: 430px !important;
  }

  .right-search {
    right: 79px;
    top: 12px;
  }

  .dark-mode-sidebar .css-ixt6q8 {
    background-color: #353535 !important;
    border-top: 1px solid #424242;
    border-bottom: 1px solid #424242;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .align-boxes {
    padding: 25px 0px;
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .dark-mode-sidebar .css-ixt6q8 {
    background-color: #353535 !important;
    border-top: 1px solid #424242;
    border-bottom: 1px solid #424242;
  }

  .align-boxes {
    padding: 15px 0px;
    margin-top: 0px;
  }

  .totalnumber h2 {
    font-size: 30px !important;
  }

  .css-1byr0tz {
    /* padding-top: 73px !important; */
  }
  .new-heading-front {
    font-size: 14px;
  }
  .heading-main {
    font-size: 25px !important;
  }

  .left-fit-contain {
    padding-top: 0px !important;
  }

  .right-search {
    right: 61px;
    top: 13px;
  }

  .fit-height .MuiCard-root {
    /* height: 290px; */
  }

  .MuiTypography-root {
    font-size: 18px !important;
  }
}

.mbottom {
  padding-bottom: 20px;
}

.fit-height .css-1lzsus4 {
  height: auto;
}

.apx-legend-position-right {
  height: 100%;
  overflow-y: hidden !important;
  padding-right: 5px !important;
}

.totalnumber .css-8p45ng-MuiCircularProgress-root {
  height: 25px !important;
  width: 25px !important;
}

.third-party-risk-table {
  max-height: 356px !important;
}

/* For Chrome, Edge, and Safari */
.third-party-risk-table::-webkit-scrollbar-corner {
  background-color: transparent; /* Match this with your container background color */
}

.third-party-risk-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent !important;
}

.third-party-risk-table::-webkit-scrollbar-track {
  background: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 0px;
}

.third-party-risk-table::-webkit-scrollbar-thumb {
  background: #4c4c74; /* Customize the thumb color */
  border-radius: 8px;
}

/* .third-party-risk-table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: transparent!important;
    margin: 0;
    padding: 0;
}

.third-party-risk-table::-webkit-scrollbar-track {
    background: transparent!important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
}

.third-party-risk-table::-webkit-scrollbar-thumb {
    background: #45405B;
    border-radius: 8px;
    border: 0px solid transparent;
} */

.third-party-risk-table-light {
  max-height: 356px !important;
}

.third-party-risk-table-light::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent !important;
}

.third-party-risk-table-light::-webkit-scrollbar-track {
  background: transparent;
  margin-bottom: 10px;
  margin-top: 10px;
}

.third-party-risk-table-light::-webkit-scrollbar-thumb {
  background: #45405b;
  border-radius: 8px;
  border: 0px solid transparent;
}

/* start new */
@font-face {
  font-family: "Mona-Sans";
  src: url("../../../public/assets/font/Mona-Sans-SemiBold.woff2")
      format("woff2"),
    url("../../../public/assets/font/Mona-Sans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../../../public/assets/font/Mona-Sans-Black.woff2") format("woff2"),
    url("../../../public/assets/font/Mona-Sans-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../../../public/assets/font/Mona-Sans-Regular.woff2")
      format("woff2"),
    url("../../../public/assets/font/Mona-Sans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../../../public/assets/font/Mona-Sans-SemiBold.woff2")
      format("woff2"),
    url("../../../public/assets/font/Mona-Sans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans Medium Wide";
  src: url("../../../public/assets/font/Mona-Sans-MediumWide.woff2")
      format("woff2"),
    url("../../../public/assets/font/Mona-Sans-MediumWide.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.toggleMode {
  display: none;
}

.top-heading-dashboard {
  width: 100%;
  position: relative;
}

.top-heading-dashboard h2 {
  border-bottom: 1px solid #2c2556;
  font-size: 16px !important;
  margin: 25px 0px 0px;
  padding: 0px 25px 25px;
  font-family: Mona-Sans, sans-serif;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #fff;
}

.image-third-party {
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 40px 20px;
}

.image-third-party p {
  font-family: Mona-Sans, sans-serif;
  font-size: 37px;
  font-weight: 600;
  line-height: 35.78px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.image-third-party-icon {
  padding-left: 17%;
}

.number-up {
  font-family: Mona-Sans, sans-serif;
  font-size: 18.32px;
  font-weight: 600;
  line-height: 26.17px;
  text-align: left;
  color: #60d39c;
  position: relative;
  top: -32px;
}

.number-down {
  font-family: Mona-Sans, sans-serif;
  font-size: 18.32px;
  font-weight: 600;
  line-height: 26.17px;
  text-align: left;
  color: #ff0000;
  position: relative;
  top: -32px;
}

.donut-dashboard .apexcharts-datalabel-value {
  color: #fff !important;
  fill: #fff !important;
  font-family: Mona-Sans, sans-serif !important;
  font-size: 28px;
  font-weight: 600;
  line-height: 27.08px;
}

.total-issues-chart {
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
}

.in-chart-bar .card-headerpart {
  display: none;
}

.in-chart-bar .MuiPaper-elevation1 {
  border: none !important;
}

.in-chart-bar {
  margin-bottom: 0px;
}

.risk-part {
  padding: 14px;
  width: 215px;
  height: 51px;
  margin: 0px auto;
  font-family: Mona-Sans, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 23.21px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0px;
}

.low-risk-part p {
  margin: 0;
  background: linear-gradient(to right, #45d7a2, #b0ffb0); /* Light green */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.medium-risk-part p {
  margin: 0;
  background: linear-gradient(to right, #fd9b3d, #ffe680); /* Yellow */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.high-risk-part p {
  margin: 0;
  background: linear-gradient(to right, #ff4d00, #ffcc80); /* Orange */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.critical-risk-part p {
  margin: 0;
  background: linear-gradient(to right, #ff0505, #ffb0b0); /* Red gradient */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-gradient {
  background-image: url("../../assets/Thermostats-new.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.critical-border-gradient {
  background-image: url("../../assets/critical-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.high-border-gradient {
  background-image: url("../../assets/high-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.medium-border-gradient {
  background-image: url("../../assets/medium-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.low-border-gradient {
  background-image: url("../../assets/low-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.MuiPaper-elevation1.score-different-color {
  background-color: #140c40 !important;
  border: none !important;
}

.score-different-color .top-heading-dashboard h2 {
  border-bottom: none;
  padding-bottom: 0px;
  background: linear-gradient(90deg, #ffffff 0%, #999999 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-ellipsshape {
  /* background-image: url('../../assets/bg-ellipse.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center -42px;
}

.critical-r {
  margin: 0px auto;
  font-family: Mona-Sans, sans-serif;
  font-size: 13.52px;
  font-weight: 400;
  line-height: 13.08px;
  text-align: center;
  background-image: url("../../assets/Thermostats-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  /* display: inline-block; */
  padding: 6px 10px;
  height: 18px;
}

.critical-r span {
  margin: 0;
  color: #ff1919;
}

.logo-vender-multiple {
  width: 28px;
  height: 28px;
  background: #ffffff2e;
  display: inline-block;
  border-radius: 100px;
  vertical-align: sub;
  font-size: 9px;
  text-align: center;
  line-height: 29px;
}

.logo-vender-multiple img {
  padding-top: 5px;
}

.score-value {
  font-family: Mona-Sans, sans-serif;
  font-size: 7.89px;
  font-weight: 600;
  line-height: 12.96px;
  text-align: left;
  color: #60d39c;
  position: relative;
  top: -13px;
}

.low-score-value {
  font-family: Mona-Sans, sans-serif;
  font-size: 7.89px;
  font-weight: 600;
  line-height: 12.96px;
  text-align: left;
  color: red;
  position: relative;
  top: -13px;
}

.total_issues_select {
  border-radius: 7px !important;
  color: #000 !important;
  font-family: Mona-Sans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
  padding: 4px 0 2px !important;
  background-color: #d9d9d9;
  width: 143.32px;
  height: 26.87px;
}

.total_issues_select:before {
  border-bottom: 0px !important;
}

.total_issues_select select {
  padding-left: 8.83px !important;
  padding-top: 5px !important;
  font-family: Mona-Sans, sans-serif;
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 15px;
  border-radius: 5.05px !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
  z-index: 9;
}

.total_issues_select option {
  background: #d9d9d9 !important;
  color: #000 !important;
  font-weight: 600 !important;
}

.total_issues_select svg {
  color: #000;
  margin-right: -5px;
}

.flex-total-issues {
  display: flex;
  align-items: center;
  justify-content: right;
}

.d-flex-checks {
  width: 100%;
  display: flex;
  gap: 32.34px;
  padding-left: 85px;
  align-items: center;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
}

.d-flex-checks .inline-form-check {
  /* background: radial-gradient(69.9% 110.16% at 17.58% 4.3%, rgba(12, 248, 58, 0.3) 0%, rgba(28, 27, 51, 0.06) 78.06%);
    padding: 8px 10px;
    border-radius: 5px; */
}

.checkbox-outline {
  /* border-radius: 5px;
    display: block;
    border: 0.51px solid transparent;
    border-image-source: linear-gradient(147.77deg, #0cf83a69 1.90%, rgba(23, 185, 255, 0.1) 53.59%);
    border-image-slice: 1; */
}

.d-flex-checks .second-color-g {
  /* background: radial-gradient(69.9% 110.16% at 17.58% 4.3%, rgba(43, 106, 227, 0.3) 0%, rgba(28, 27, 51, 0.06) 78.06%); */
}

.d-flex-checks .third-color-g {
  /* background: radial-gradient(69.9% 110.16% at 17.58% 4.3%, rgba(144, 11, 140, 0.3) 0%, rgba(28, 27, 51, 0.06) 78.06%); */
}

.custom-checkbox {
  display: flex;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-family: Mona-Sans, sans-serif;
  font-size: 15.78px;
  font-weight: 400;
  line-height: 6px;
  text-align: right;
  align-items: center;
}

.assessment-datacheck {
  position: relative;
  top: 1.6px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: -1;
  left: 0;
  height: 20.29px;
  width: 20.29px;
  background-color: transparent;
  border-radius: 2.5px;
  border: 3px solid #fff;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: transparent;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: transparent;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-line-third .apexcharts-gridlines-horizontal {
  border: none;
  display: none;
}

.custom-line-third .apexcharts-grid-borders .apexcharts-gridline:first-child {
  display: none;
}

.custom-line-third .apexcharts-grid-borders .apexcharts-gridline {
  opacity: 0.3;
}

.custom-line-third .apexcharts-grid line {
  stroke: #ffffff;
  stroke-width: 1;
  stroke-dasharray: 0;
  stroke-linecap: round;
  opacity: 0.1;
}

.custom-line-third .apexcharts-grid line:first-child {
  stroke: transparent !important;
}

.same-as-username {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 15.47px;
  text-align: left;
  text-transform: lowercase;
  color: #fff;
}
.same-as-username::first-letter {
  text-transform: capitalize;
}

.donut-dashboard .apexcharts-legend-series {
  margin-bottom: 19px !important;
}

.custom-line-third .apexcharts-legend-series {
  margin-bottom: 28px !important;
}

.in-chart-bar .apexcharts-gridlines-horizontal {
  border: none;
  display: none;
}

.in-chart-bar .apexcharts-grid-borders .apexcharts-gridline:first-child {
  display: none;
}

.in-chart-bar .apexcharts-grid-borders .apexcharts-gridline {
  opacity: 0.3;
}

.in-chart-bar .apexcharts-grid line {
  stroke: #ffffff;
  stroke-width: 1;
  stroke-dasharray: 0;
  stroke-linecap: round;
  opacity: 0.1;
}

.in-chart-bar .apexcharts-grid line:first-child {
  stroke: transparent !important;
}

.css-1r54htb-MuiPaper-root-MuiDrawer-paper {
  border-right: 1px solid transparent !important;
  height: auto !important;
  z-index: 999 !important;
}

.css-pis9hk-MuiInputBase-root-MuiInput-root:after {
  border: 0px !important;
}

.css-coqgb2-MuiPaper-root-MuiCard-root {
  margin-bottom: 0px !important;
}

.overall-chart-center {
  justify-content: center;
  position: relative;
}

.outer {
  position: relative;
  width: 600px;
  height: 400px;
}
canvas {
  position: absolute;
}
.percent {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 80px;
  bottom: 0;
}

.gauge-semicircle .pointer circle {
  /* stroke: #FD9B3D !important;  */
  stroke-width: 5px !important; /* Set stroke width */
  fill: white !important; /* Set fill color to white */
  filter: drop-shadow(0px 0px 15px #fd9b3d) !important; /* Add drop shadow */
}

.gauge-semicircle .tick-value,
.gauge-semicircle .tick-line {
  display: none;
}

.gauge-semicircle .subArc path {
  stroke-linecap: round;
}

.gauge-semicircle .last-scan {
  margin: 0px;
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.54px;
  text-align: center;
}

.gauge-container {
  position: relative;
}

.gauge-container .semicircle-gauge:before {
  /* content:'';
  background-image: url('../../assets/graph-circle-round.svg');
  background-size: 100% 100%;
  background-position: center;
  width: 195px;
  position: absolute;
  height: 203px;
  bottom: 42px;
  background-repeat: no-repeat;
  left: 80px; */
}

.number-up.position-right-side-overall {
  top: 35px;
  right: 23px;
  position: absolute;
}
.number-down.position-right-side-overall {
  top: 35px;
  right: 23px;
  position: absolute;
}

.charts-bar-label-design
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: left !important;
  top: -19px !important;
}

.charts-bar-label-design .apexcharts-legend-marker {
  border-radius: 0px !important;
  margin-right: 6px !important;
}

.charts-bar-label-design .apexcharts-legend-series {
  display: flex !important;
}

.legend-text-issue-legend .apexcharts-legend-series {
  margin-bottom: 8px !important;
}

.legend-text-issue-legend .apexcharts-datalabels-group {
  transform: translate(0px, -2px);
}
