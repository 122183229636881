span .sparkle-icon{
    position: relative;
    margin-bottom: 8px;
    border:none
}
.global-full-search-input .relative-search{
 right: auto;
 max-width: 100%;
}

.global-full-search-input .input-header-search{
    width: 100%;
    height: 49px;
}

.global-full-search-input{
.suggestions-list {
width: 100%;
background-color: #120A3A;
margin-top: 1rem;
}
}
.global-full-search-input{
    .bar-suggestions-list {
    width: 100%;
    background-color: #120A3A;
    margin-top: 1rem;
    }
    }
.global-full-search-input{
    .input-header-search-active {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .suggestions-list {
        li{
            border-radius: 6px;
            margin-bottom: 20px;
            background: #2A198161;

        }
    }
}
.global-full-search-input{
    .input-header-search-active {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .bar-suggestions-list {
        li{
            border-radius: 6px;
            margin-bottom: 20px;
            background: #2A198161;

        }
    }
}

.max-inputai-data {
    width: 65%;
    position: relative;
}

.sparkle-search-icon .ai-genesis-top-button img{
    position: static;
    margin-right: 10px;
}

.topdetail-searchbar {
    top: 9px!important;
    width: 31px;
}