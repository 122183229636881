.next-button {
    float: right;
    margin-top: auto;
}

.add-questionnaire-action .add-questionnaire-button {
    margin-right: 10px;
    border-radius: 10px;
}
.add-questionnaire-action img{
    height: 30px;
    width: 30px;
    border-radius: 50%;
}
body .add-questionnaire-button {
    line-height: 30px !important;
    background: #271969!important;
    font-family: Mona-Sans!important;
    font-size: 12px!important;
    font-weight: 600!important;
    line-height: 11.6px!important;
    text-align: center;
    height: 39px!important;
}

.link-remove {
    text-decoration: none;
}

.add-Questionnaire {
    width: 200px;
}

.d-justifybetween {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.accordion-part .MuiPaper-elevation1 {
    background-color: #f5f6f7;
}

.section-headingpart {
    font-family: Mona-Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    
}

.sub-categoryname {
    font-family: Mona-Sans ,sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 15.47px;
    text-align: left;
    margin-bottom: 11px;
    color: #fff;
    
}

.addsection {
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
    height: 43px;
    font-family: Mona-Sans , sans-serif!important;
    font-size: 14px!important;
    font-weight: 600!important;
    line-height: 13.54px!important;
    text-align: center!important;
    padding: 15px 35px!important;
    margin-top: 10px!important;

}

.min-h40 {
    height: 40px;
}

.full-form .title-label {
    font-family: Mona-Sans, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 13.54px;
    text-align: left;
    color: #fff;
}

.full-form {
    margin: 25px;
}

.expand-section {
    font-weight: 400 !important;
    line-height: 38px;
}

.padding-space {
    padding: 10px;
}

.textarea-space textarea {
 border-radius: 10px;
}


.margin-questionnaire {
    margin-bottom: 20px;
}

.full-form input {
    color: #000!important;
}

.full-form textarea {
    color: #000!important;
}

textarea#\:r1\: {
    border: none;
}

.question-buttons{
    display: flex;
    justify-content: right;
    gap: 28px;
    position: absolute;
    right: 283px;
    top: 23px;
}

.right-side-questionnaire-library{
    right: 25px;
    top: 7px;
}

.document-buttons{
    display: flex;
    justify-content: right;
    gap: 25px;
    position: absolute;
    right:21px;
    top: 23px;
}

.document-buttons label{
    top: -7px!important;
}

.pop-upload-q {
    display: flex;
    width: 82%;
    justify-content: center;
    gap: 50px;
    padding-top: 44px;
    margin: 0px auto;
}

.pop-upload-q .MuiGrid-root{
    width: 100%;
}

.preview-popup,.css-139lcja{
    background: #120A3A!important;
}

.css-139lcja .bgaddfiles{
    background: #120A3A!important;
}

.pop-upload-q label{
    background: #120A3A !important;
    border: 1px dashed #FFFFFF4D !important;
    width: 313px !important;
    height: 200px !important;
    border-radius: 10px;
    position: relative;
}

.pop-upload-q label:before{
    content: '';
    display: block;
    width: 326px;
    height: 212px;
    border: 1px solid #BE06C566;
    position: absolute;
    border-radius: 10px;
}

.upload-img-border img{
    width: 46px!important;
    height: 46px!important;
    display: block;
    margin: 0px auto 22px;
}
.upload-img-border{
    display: block;
    text-align: center;
    margin: 0px auto;
}
.upload-img-border span{
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 500;
line-height: 13.54px;
text-align: left;
}

.selectfiles{
    background: #271969;
    border-radius: 10px;
    font-family: Mona-Sans, sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 11.6px;
    text-align: center;
    color: #fff;
    padding: 9px 20px;
    border: none;
    display: block;
    margin: 22px auto 0px;

}

.download-demo-btn{
    text-align: left;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 25px;
}

.download-demo-btn p{
    font-family: Mona-Sans ,sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
    color: #fff;
}

.download-demo-btn button{
    background: #271969;

}

.underline-text{
    text-decoration: underline!important;
}

.backlink{
    display: flex;
    align-items: center;
    gap: 14px;
    font-family: Mona-Sans, sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    color: #fff;
}

.none-line{
    text-decoration: none!important;
}
.upload-add-quationnaire-page{
    background: #120A3A !important;
    border: 1px dashed #FFFFFF4D !important;
    width: 90%;
    height: 170px !important;
    border-radius: 25px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px;
}

.upload-add-quationnaire-page label:before {
    content: '';
    display: block;
    width: 112%;
    height: 184px;
    border: 1px solid #BE06C566;
    position: absolute;
    border-radius: 25px;
}

.upload-add-quationnaire-page .MuiButton-containedPrimary{
    background: #120A3A !important;
    height: 100% !important;
}

.input-common{
border: 1px solid #FFFFFF1A;
background: #150D43;
border-radius: 10px;
}

.input-common fieldset{
    border-color: #FFFFFF1A!important;
}

.padding-right-grid {
    padding-right: 16px;
}

.expand-section-heading{
font-family: Mona-Sans, sans-serif!important;
font-size: 18px!important;
font-weight: 600!important;
line-height: 44px!important;
text-align: left;
color: #fff;
width: 100%;
}

.padding-square{
    padding-left: 15px;
    padding-right: 15px;
}

.accordion-part .MuiAccordionSummary-gutters{
    padding: 0px 42px;

}

.accordiantypo{
    display: flex;
    align-items: center;
    gap: 22px;
}

.dark-mode-class .accordion-part .MuiPaper-elevation1{
    background: linear-gradient(90deg, rgba(39, 25, 105, 0.8) 0%, rgba(21, 13, 67, 0.8) 100%)!important;
}

.accordion-part .accordian-detailspage{
    padding: 20px 43px 30px;
    border-radius:10px;
}

.section-input-first{
    border: 1px solid #FFFFFF1A;
    height: 60px;
    border-radius: 10px;
}

.font-type input{
font-family: Mona-Sans;
font-size: 16px;
font-style: italic;
font-weight: 400!important;
line-height: 15.47px;
text-align: left;

}

.right-move{
    display: block!important;
    margin: 0px 0 0 auto!important;
    top: 15px;
}

.select-question-type{
    height: 44px;
    border-radius: 6px!important;
    font-family: Mona-Sans, sans-serif!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 14px;
    text-align: left;
    /* border: 1px solid #FFFFFF1A!important; */
    background: #241760!important;

}

.question-no{
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 22px;
}

.issue-select-question .MuiInputBase-root{
    height: 60px;
    border-radius:10px!important;
    font-family: Mona-Sans, sans-serif!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 14px;
    text-align: left;
    background:transparent!important;
}

.issue-select-question label{
    font-family: Mona-Sans, sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    top: 8px !important;
    left: 0px !important;
}

.add-options-section{
    margin-top: 20px!important;
    border-bottom: 1px solid #FFFFFF0F!important;
    padding-bottom: 25px!important;
    max-width: 94.5%;
}

.option-add-select{
    border: 1px solid #FFFFFF1A;
    background: #241760;
    font-family: Mona-Sans, sans-serif;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 14px;
    text-align: center;
}

.letter-options{
    min-width: 25px;
    min-height: 25px;
    background: #4B38B333;
    border-radius: 2px;
    text-align: center;
}

.letter-options span{
font-family: Mona-Sans, sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;
color: #fff;
position: relative;
top: 1px;
}

.herrankey{
    position: relative;
}

 .herrankey:first-child:before{
    content: '';
    position: absolute;
    left: 25px;
    background-image: url('../../assets/herraki2.svg');
    width: 25px;
    top: 97px;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
}

.by-default-activeline-left.active-class{
    position: relative;
}

.by-default-activeline-left.active-class:before{
    content: '';
    position: absolute;
    left: 41px;
    height: 90.9%;
    border-left: 1.5px solid #51497b;
    top: 61px;
} 

.add-quationnire-section-name{
    padding-left: 25px;
    padding-right: 25px;
}
.add-quationnire-section-name .right-move.margins-button{
    margin-top: 7px !important;
    margin-bottom: 22px !important;
}

.left-move-to-select {
    margin-left: 50px;
    margin-right: 25px;
}

.last-submit-btn{
    margin-top: 40px!important;
    margin-bottom: 20px!important;
    float: right;
}

.relative-popup .css-argv3p{
    background-color: #120a3a;
}

.preview-page{
    font-family: Mona-Sans, sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 21.27px;
    text-align: left;
    border-bottom: 1px solid #FFFFFF33;
    padding-top: 6px;
    padding-bottom: 10px;
    margin-bottom: 11px;
    color: #fff;
}

.preview-submission{
    background: #29177F66;
    padding: 35px 26px;
    border-radius: 10px;
    width: 625px;
    margin-top: 26px;
    position: relative;
}

.preview-submission:before{
    content: '';
    position: absolute;
    top: -19px;
    left: 45px;
    transform: translateX(-70%);
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #29177F66;
    width: 19px;
    height: 19px;
}

.preview-submission h2{
font-family: Mona-Sans, sans-serif;
font-size: 22px;
font-weight: 600;
line-height: 30px;
text-align: left;
color: #fff;
margin-top: 0px;
margin-bottom: 29px;
}

.preview-submission p{
    font-family: Mona-Sans, sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-bottom: 0px;
}

.title-preview{
    margin-top: 10px;
    display: flex;
    gap: 100px;
    align-items: center;
    border-bottom: 1px solid #FFFFFF14;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.title-preview h2{
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 15.47px;
    text-align: left;
    min-width: 120px;
    color: #fff;
}

.title-preview p {
font-family: Mona-Sans, sans-serif;
font-size: 16px;
font-weight: 400;
line-height: normal;
text-align: left;
color: #fff;

}

.previous-submit{
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 24px;
}

.previous-submit button{
    min-width: 182px;
    height: 43px;
}

body .back-to-previous{
    border: 1px solid #FFFFFF33!important;
    background: transparent!important;
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 15.47px;
    text-align: left;

}

.checkbox-outline.preview{
    margin: 25px 0px;
}

.custom-checkbox{
    line-height: normal !important;
}

.checkbox-outline.preview .checkmark{
width: 20px;
height: 20px;
border: 1px solid #FFFFFF66;
}


.checkbox-outline.preview .assessment-datacheck {
    position: relative;
    top: 2px;
    left: 12px;
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #fff;
}

.checkbox-outline.preview .custom-checkbox .checkmark:after {
    left: 9px;
    top: 3px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.accordion-part{
    padding: 1% 0% 1% 0%
}

.margin-leftissue-select-question {
    padding-left: 50px;
    padding-right: 25px;
}
.margin-leftissue-select-question > .MuiGrid-item {
    margin-bottom: 16px; /* Adjust this value */
}

.MuiTablePagination-actions button {
    box-shadow: 0px 0px 0px 1px #464F603D;
    background-color: #fff !important;
    color: #cacccf !important;
    padding: 2px 2px;
    border-radius: 6px;
    margin: 0px 10px 0 0px;
}


.add-options-section {
    margin-left: 50px!important;
    margin-right: 25px!important;
}

.MuiInputBase-formControl{
    border-radius: 10px!important;
}

.left-mui-add-options{
    margin-left: 50px
}

textarea::-webkit-scrollbar-corner {
    background-color: transparent; /* Match this with your container background color */
}

textarea::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: transparent!important;
}

textarea::-webkit-scrollbar-track {
    background: transparent!important;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
}

textarea::-webkit-scrollbar-thumb {
    background: #4c4c74; /* Customize the thumb color */
    border-radius: 8px;
}
