.login-screen {
  margin: 0px auto;
  min-height: 100vh;
  display: flex !important;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  background-color: #120A3A;
}

.css-v82cpl {
  margin-bottom: 25px;
}

.box-login {
  background-color: #120a3a;
  border: 1px solid #120a3a;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.login-screen input {
  background: #120A3A;
  border: 1px solid #120a3a;
  border-radius: 15px;
  color: #fff;
  padding: 0px 14px;
}

.login-screen input:hover {
  background-color: transparent;
}

.css-18yadix {
  /* min-height: 0px !important; */
  min-width: 100%;
  padding-top: 0px !important;
  text-align: center;
  padding-bottom: 0 !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.bg-image-forgot .css-18yadix {
  padding-left: 45px !important;
  padding-right: 45px !important;
  padding-top: 15px !important;
  padding-bottom: 43px !important;
}

.login-screen h2 {
  font-family: Mona-Sans, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 27.08px;
  text-align: left;
  color: #fff;
  margin-bottom: 60px;
  margin-top: 55px;
}

.login-screen h4 {
  margin-bottom: 0px;
}

.login-screen p {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 40px;
}

.login-screen button {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  background-color: #4A10A4 !important;
  border-radius: 10px;
  color: #fff;
  text-transform: capitalize;
}

.flex-direction {
  flex-direction: column !important;
}

.forgot-text {
  font-family: Mona-Sans, sans-serif;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 11.6px !important;
  text-align: right;
  text-decoration: none !important;
  color: #fff!important;
}

.flex-direction .dont-have {
  font-family: Mona-Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #B1B3B7;
  text-decoration: none;
  margin-bottom: 5px;
  display: inline-block;
  margin-bottom: 15px;
}

.dont-have a {
  font-size: 15px !important;
}

.eye-icon {
  position: absolute;
  right: 15px;
}

.eye-icon button {
  background-color: transparent !important;
  color: #fff;
  width: auto;
  z-index: 1;
}

.body-dark .eye-icon button {
  color: #fff;
}

.body-dark input::placeholder {
  color: #ccc8c8 !important;
  opacity: 1;
}

.body-light input::placeholder {
  color: #282829 !important;
  opacity: 1;
}

.login-screen .css-rb5gc9-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
  left: 16px !important;
  top: 3.4px !important;
  font-family: Mona-Sans, sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.64px;
  text-align: left;

}

.css-axj3wf-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #fff !important;
  font-family: Mona-Sans, sans-serif !important;
}

/* .css-rb5gc9-MuiFormLabel-root-MuiInputLabel-root{
    color: #B1B3B7!important;
    left: 32px!important;
    top: 4px!important;
  } */

.inputfield {
  position: relative;
  width: 100%;
}

.inputfield img {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 13px;
  top: 17px;
}

.MuiFormControl-root {
  width: 100%;
}

.login-screen .MuiInputBase-root {
  padding: 0px !important;
  border-radius: 10px !important;
  height: 60px;
}

.login-screen .MuiInputBase-root:hover {
  border: 0px !important;
}

.sinup-bold {
  margin-left: 5px !important;
  display: inline-block;
  color: #242526 !important;
  text-decoration: underline !important;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400;
}

.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px transparent inset !important;
  -webkit-text-fill-color: #f8f8f8 !important;
  caret-color: #fff;
  border-radius: 10px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px transparent inset !important;
  -webkit-text-fill-color: #f8f8f8 !important;
  caret-color: #fff;
  border-radius: 10px !important;
}

 input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #120a3a inset !important;
  -webkit-text-fill-color: #f8f8f8 !important;
  caret-color: #fff;
}

.login-screen .MuiTextField-root {
  margin: 0px 0 6px !important;
  width: 100% !important;
}

.already-have {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #B1B3B7;
}

.already-have a {
  color: #242526;
  text-decoration: underline;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 15px !important;
}

body .space-bottom {
  margin-bottom: 20px;
}

.back-to {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.54px;
  text-align: left;
  display: flex;
  justify-content: right;
  margin-top: 13px;
  margin-bottom: 0px;
}

.body-light .back-to {
  color: #fff;
}

.body-dark .back-to {
  color: #fff;
}

.achor {
  text-decoration: none !important;
}

.back-to img {
  filter: brightness(0) saturate(100%) invert(78%) sepia(0%) saturate(1918%) hue-rotate(26deg) brightness(93%) contrast(92%);
  width: 20px;
  height: 20px;
}

.relative {
  position: relative;
}

.dark-toggle {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
  border-radius: 0px 0px 10px 10px;
  width: 316px;
  margin: 0px auto !important;
  text-align: center;
  display: flex !important;
  justify-content: space-between;
  bottom: -61px;
  height: 40px;
  flex-direction: row-reverse;
  padding: 20px;
  font-size: 15px;
  line-height: 20px;
  color: #242526;
}


.dark-toggle .MuiTypography-root {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.dark-toggle .css-1yjjitx-MuiSwitch-track {
  background-color: #a3a3a3;
}

.css-1yjjitx-MuiSwitch-track {
  background-color: #a7a8a8 !important;
}

.css-mghcik-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #4a10a4 !important;
}

.dark-toggle .Mui-checked .MuiSwitch-thumb {
  color: #fff !important;
}

.MuiSwitch-thumb {
  width: 13px !important;
  height: 13px !important;
  margin: 4px 4px !important;
}

.body-dark .box-login {
  background-color: #120a3a;
  border: 1px solid #120a3a;
  width: 100%;
}


.body-dark .inputfield input {
  background-color: #120A3A !important;
  /* border: 1px solid #2A224E; */
  font-family: Mona-Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.54px;
  text-align: left;
  color: #fff !important;
  padding-left: 28px;
  height: 60px;
}

.body-dark .inputfield input:focus-visible {
  outline: 0px;
}

.login-buttton-gradient {
  background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
  font-family: Mona-Sans, sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 17.41px;
  text-align: left;
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  margin-top: 47px !important;
  box-shadow: 4px 4px 4px 0px #0000000D !important;

}

.body-dark .inputfield img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(23%) saturate(1192%) hue-rotate(160deg) brightness(115%) contrast(109%);
}


.body-dark .dark-toggle {
  background-color: #18191A !important;
  border: 1px solid #2A224E;
  color: #fff;
}

.logo-size {
  width: 152px;
  height: 53px;
  text-align: center;
  margin: 0px auto;
}

.body-dark .forgot-text,
.body-dark .sinup-bold {
  color: #fff !important;
}

.body-dark .already-have a {
  color: #fff !important;
  margin-bottom: 15px;
  font-size: 15px !important;
}

.css-161s7uk-MuiGrid-root {
  margin-bottom: 0px !important;
  margin-top: 8px !important;
}

.MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4A10A4 !important;
  border-width: 1px !important;
}

.dark-mode-class .MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2A224E !important;
}

.f2a .MuiPaper-elevation {
  padding: 0px !important;
  background: #fff;
}

.f2a p {
  margin-bottom: 0px !important;
  font-size: 17px;
}

.input-style input {
  font-size: 20px;
  background: #150D43;
  border: 1.12px solid #404040;
}

.fa-center {
  display: flex;
  justify-content: center;
  background-color: #120A3A;
}

.center-alignbtn {
  padding-bottom: 25px;
  margin-top: 0px!important;
}

.fa-center h2 {
  margin-top: 0px !important;
  text-align: center;
}

.reset-passwords {
  padding: 0px 25px 25px;
}

.enter-new-password {
  margin-bottom: 40px !important;
}

@media (max-width: 767px) {
  .css-18yadix {
    min-width: 100%;
  }

  .dark-toggle {
    width: 240px;
    bottom: -64px;
  }

  .login-screen h2 {
    font-size: 25px;
  }

}



@media screen and (max-height: 800px) {
  .register-scroll {
    height: 100vh;
    overflow-y: auto;
    display: block;
    padding-top: 15px;
  }
}

.inputfield input {
  background-color: #120a3a !important;
  border: 1px solid #2A224E;
  height: 60px;
}

.class-right {
  text-align: right;
}

.bg-login-new {
  background-image: url('../../assets/login-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  background-position: center center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 75px;
}

.login-right-padding {
  padding-right: 0px !important;
}

.bg-login-new h1 {
  font-family: Mona-Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
  margin-top: 21px;
  margin-bottom: 0;
  color: #fff;
}

.bg-login-new h2 {
  font-family: Mona-Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 17.41px;
  text-align: left;
  margin: 0px;

}

.full-width-forgot {
  margin: 0px auto;
  border: 1px solid #404040;
  border-radius: 10px;
  min-width: 568px;
}

.full-width-forgot .css-18yadix {
  min-height: auto !important;
}

.bg-image-forgot {
  background-image: url('../../assets/forgot-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.full-width-forgot h2 {
  font-family: Mona-Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 21.27px;
  text-align: left;
  margin-bottom: 20px;
}

.full-width-forgot p {
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
}

.bg-image-forgot .login-buttton-gradient {
  margin-top: 29px !important;
}

.absolute-logo-forget {
  position: absolute;
  top: 28px;
  left: 40px;
}

.login-right-padding .css-18yadix {
  min-width: 460px;
}

.fit-login-area {
  max-width: 788px;
}

.css-axj3wf-MuiFormLabel-root-MuiInputLabel-root {
  transform: translateX(-15px);
  /* Move label 15px to the left */
  padding-right: 15px;
  /* Add padding to prevent text from being cut off */
  white-space: nowrap;
  /* Prevent text wrapping */
  overflow: visible;
  /* Ensure the text isn't cut off */
}

.login-screen .MuiTextField-root.inputfield-top24 {
  margin-top: 24px !important;
}

.max-widh-twofa{
  padding: 0px!important;
}
.margin-twofa-top{
margin-top: 0px!important;
}
.inputfield input::placeholder {
  color: #fff;
  opacity: 1;
}
.inputfield input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.inputfield input:-ms-input-placeholder {
  color: #fff;
}

.MuiFormControl-root label{
  color: #fff!important;
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
  .login-right-padding .css-18yadix {
    min-width: 340px;
    padding-left: 17px !important;
  }
}

.active-account .inputfield img {
  left: 7px;
  top: 19px;
}