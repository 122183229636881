
.series-knob {
    height: 20px;
    width: 20px;
    background: #ffffff;
    border-radius: 100px;
    box-shadow: 1px 1px 8px 7px #8f8a8a;
    position: relative;
    z-index: 999;
    display: none;
}
.knob-value-51 {
    display: block;
    top: 25px;
    left: 178px;
}
.knob-value-70 {
    display: block;
    top: 67px;
    left: 249px;
}

.chart-bar-subcription{
    background-color: rgb(50, 38, 113);
    border-radius: 25px;
    padding: 0px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 10px;
    margin: 4px 0px 25px;

}

.chart-bar-subcription .apexcharts-canvas{
    top: -15px;
}