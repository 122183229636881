.toggleMode{
    padding-left: 25px;
    overflow-x: hidden;
}

.navBar {
    display: flex;
    flex-wrap: wrap;
    height: 85vh;
}

.navBar ul{
    padding-left: 20px;
    padding-right: 20px;
}

.navBar a{
    font-family: Mona-Sans, sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #fff;
    margin-bottom: 16px;
}

.navBar .active{
font-family: Mona-Sans , sans-serif;
background-color: #342C5C !important;
color: #fff!important;
border-radius: 0px;
position: relative;
font-weight: 600!important;
}

.navBar .active::before{
    content: '';
    position: absolute;
    width: 5px;
    height: 32px;
    background: linear-gradient(0deg, #6C6C6C 0%,#FBFBFB  100%);
    left: 0;
    display: inline-block;
    border-radius: 0px 5px 5px 0px;
}

.section-bottom {
    display: flex;
    align-items: end;
    width: 100%;
}

.logo-sidebar{
    margin:25px auto 35px;
    display: block;
    text-align: center;
}

.logo-sidebar img{
    width: 145.11px;
    height: 50px;
}

.css-qeglhl{
    width: auto!important;
    height: auto!important;
}

.username-profile .css-ixt6q8{
    background-color: #fff!important;
    border-top: 1px solid #E4E6EA;
    border-bottom: 1px solid #E4E6EA;
    border-radius: 0px!important;
    padding: 15px 0 15px 20px!important;
}

.dark-mode-sidebar .css-ixt6q8{
    background-color:#121212!important;
    border-top: 1px solid #242526;
    border-bottom: 1px solid #242526;
}

.dark-mode-sidebar .css-ixt6q8 h6{
    color: #fff;
}

.dark-mode-sidebar .navBar a{
    color: #C3C5CC;
}

.css-ixt6q8 h6{
    font-family: 'Poppins'!important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px!important;
    line-height: 20px;
    color: #242526;
}

.toggleMode span{
    font-family: 'Poppins'!important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px!important;
    line-height: 20px;
    color: #242526;  
}

.dark-mode-sidebar .toggleMode span{
    color: #fff;
}

.css-3mgqde-MuiPaper-root-MuiDrawer-paper{
    border-right: none!important;
}

/* .css-5yipe8-MuiPaper-root-MuiDrawer-paper{
    border-right-style: solid!important;
    border-right: 1px solid transparent!important;
    background: linear-gradient(180deg, #221950 0%, #150D3F 100%)!important;
} */

.simplebar-content-wrapper{
    border-right-style: solid!important;
    border-right: 1px solid transparent!important;
    background: linear-gradient(180deg, #221950 0%, #150D3F 100%)!important;  
}

.css-dxfs78{
    border-right-style: solid!important;
    border-right: 1px solid transparent!important;
    background: linear-gradient(180deg, #221950 0%, #150D3F 100%)!important;  
}

.section-bottom .MuiFormControlLabel-labelPlacementEnd{
    flex-direction: row-reverse;
    margin-left: 0px!important;
    width: 100%;
    justify-content: space-between;
}

.css-1r54htb-MuiPaper-root-MuiDrawer-paper{
    border-right: solid!important;
}

.css-1nobdqi.Mui-checked + .MuiSwitch-track{
    background-color: #4a10a4!important;
}

.css-1nobdqi.Mui-checked {
    color: rgb(255 255 255);
}

.css-19gndve {
    width: 15px;
    height: 15px;
}

.css-ecvcn9 {
    width: 58px;
    height: 34px;
}
 
.css-1dfrjtm-MuiPaper-root-MuiAppBar-root{
    left: 232px!important;
    max-width: 1366px;
    right: 0!important;
    left: 50% !important;
    transform: translate(-43%, 7%);
}

.another-new-class{
    width: 96px!important;
    transition: 0.2s;
}

.css-8p2v4e .MuiPaper-elevation0{
    border-right: 0px solid #ffffff00 !important;
}

.another-new-class .MuiDrawer-paper{
    width: 96px;
    border-right: 0px solid #ffffff00 !important;
    /* transition: 0.2s; */
}
.another-new-class .hide-text-collaped-tab{
    display: none;
}

.new-class,
.max-w-left,
.MuiBox-root.css-8p2v4e{
    transition: 0.2s;
}

.hamberger-icon{
    cursor: pointer;
}

.another-new-class .MuiDrawer-paper .main-logo-menu{
    display: none;
}

.another-new-class .MuiDrawer-paper .favilogos-menu{
    display: block;
    width: auto;
}

.favilogos-menu{
    display: none;
}