
.filter-right{
background-color: #1B0F58;
width: 100%;
height: 100%;
}

.filter-right h3{
    font-family: Mona-Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-bottom: 1px solid #FFFFFF1A;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    margin-top: 0px;
    margin-bottom: 25px;
}

.filter-divide-two{
    background: #150A4CB2;
    margin-top: 40px;
}

.filter-list-block{
    margin-bottom: 15px;
}

.filter-list-block .checklists{
    padding-left: 15px; 
}

.accordion-item h4{
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 600;
line-height: 14px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
padding: 15px;
background: #251960;
margin: 0;
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 15px;
cursor: pointer;
}

.filter-list-block h4 img{
    cursor: pointer;
}

.accordion-content{
    padding-left: 15px;
}
.filter{
    height: 150px;
}


.accordion-content .inline-form-check.checkbox-outline{
margin-bottom: 20px;
}

.accordion-content .inline-form-check.checkbox-outline .checkmark{
    height: 17.29px;
    width: 17.29px;
    border: 1.5px solid #fff;
    border-radius: 0px;
}

.accordion-content .inline-form-check.checkbox-outline .assessment-datacheck{
    font-size: 14px;
    left: 5px;
}

.vulnerability-flex-detail{
    display: flex;
    align-items: center;
    gap: 120px;
}

.vulnerability-flex-detail h3{
font-family: Mona-Sans, sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 14px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #fff;
}

.vulnerability-flex-detail h4{
font-family: Mona-Sans, sans-serif;
font-size: 18px;
font-weight: 600;
line-height: 19px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
}

.discriptions-vulnerability-text h3{
    font-family: Mona-Sans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: #1D0E6599;
    padding: 20px;
    margin-top: 0px;
    
}

.discriptions-vulnerability-text p{
font-family: Mona-Sans, sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
padding: 0px 20px 20px;
margin: 0px;
}

.bg-dark-detail{
background: #140A46;
}

.btncreate-remediation{
    background: linear-gradient(90deg, #5C0ECC 0%, #89119E 100%);
    font-family: Mona-Sans, sans-serif;
    font-size: 13.69px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 10px 12px;
    border-radius: 8.56px;
}

.commet-line{
    gap: 80px;
    display: flex;
    align-items: center;
}

.issues-related-detail-line{
    font-family: Mona-Sans, sans-serif;
    font-size: 16px!important;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: #1D0E6599!important;
    padding: 15px 25px !important;
    margin-top: 0px;
}

 .sunburst-container svg {
    /* width: 1200px !important;   */
    width: 1422px;
    height: auto !important;  
  }
  
  .sunburst-viz .text-contour{
    fill: #ffffff!important;
    stroke-width: 0!important;
  }
  .sunburst-viz text{
    fill: #ffffff!important;
    font-family: Mona-Sans, sans-serif!important;
    font-size: 8px!important;
    letter-spacing: 0.5px!important;
  } 

.apexcharts-heatmap-rect{
  stroke: #150d43 !important;
  stroke-width: 6px !important;
  position: relative;
}

.padding-left-headmap{
  padding: 0px 0px;
  width: 100%;
}

.padding-left-headmap p{
  font-family: Mona-Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  transform: rotate(270deg);
  position: absolute;
  left: -6px;
  letter-spacing: 0.5px;
  bottom: 218px;
  color: #fff;
}

.overall-midtoset-align{
  display: flex;
  align-items: center;
}

.overall-midtoset-align::before{
  content: "";
  position: absolute;
  border-right: 1.95px solid #FFFFFF40;
  width: 60px;
  height: 276px;
  bottom: 112px;

}

.relationship-heading::before{
  content: "";
  position: absolute;
  border-top: 1.95px solid #FFFFFF40;
  bottom: 28px;
  left: 36px;
  width: 88%;
}

.relationship-heading{
  transform: rotate(0deg)!important;
  position: inherit!important;
  margin-top: 0!important;
  text-align: center!important;
  padding-left: 25px;
}

.padding-left-headmap line{
stroke: transparent!important;
}

.padding-left-headmap .apexcharts-yaxis-texts-g{
  transform: translate(-40px, 0)!important;
}

.cover-vertical-chart{
  background: #1A124DB2;
  padding: 10px;
    margin: 0px 25px 11px;
}

.cover-vertical-chart .vertical-progresschart{
  height: 10px!important;
  border-radius: 15px!important;
  margin-top: 9px;
}

.cover-vertical-chart .linearprocess-peragraph span{
  border: 1.08px solid #FFFFFF1A;
  background: #1F1464;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
}

.cover-vertical-chart .linearprocess-peragraph{
  font-size: 14px;
}

.top-issues-vertical-heading{
  padding: 0px 25px;
  color: #fff;
}

.filter-select-dropdown{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.width-set-formcontrol{
  width: 30%;
  position: relative!important;
  right:25px;
  top: 0px;
}

.width-set-formcontrol .MuiInputBase-root{
  height: 45px;
  padding-left: 19px;
}

.width-set-formcontrol .MuiFormLabel-root{
top: -4px !important;
left: 19px!important;
font-size: 16px!important;
font-family: Mona-Sans, sans-serif!important;
}

.filter-select-dropdown h2{
  border:0px;
}

.filter-select-dropdown{
  border-bottom: 1px solid #2c2556;
}

.width-set-formcontrol ul li, 
.width-set-formcontrol .MuiSelect-select{
  font-size: 14px;
  color: #fff!important;
}

.filter-icon{
  position: absolute;
  top: 11px;
  font-size: 22px !important;
  left: 10px;
}

.row-menu button{
  color: #fff;
}

fieldset{
  border-color: rgba(255, 255, 255, 0.23)!important;
}